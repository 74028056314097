import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase-config";
import CreateEvent from "./CreateEvent";
import React from "react";

export default async function CancelEvent(userID, connectionName) {
  const eventList = doc(db, "User/" + userID + "/Calendar", "Calendar");
  const eventListSnap = await getDoc(eventList);

  if (eventListSnap.exists()) {
    let events = eventListSnap.data().events;
    // find the event
    for (const [index, element] of events.entries()) {
      if (element.name === connectionName) {
        const newList = await CreateEvent(
          connectionName,
          "",
          "",
          "",
          "",
          "",
          userID,
          false,
          element.sectionList,
          "none",
          "none",
          [],
          false
        );
        return newList;
      }
    }
    return events;
  }
  // if nothing is found
}
