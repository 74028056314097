import React from "react";
import FooterWebsite from "../../Components/FooterWebsite";
import HeaderMenu from "../../Components/HeaderMenu";

const faqs = [
  {
    id: 1,
    question:
      "How is this different from all the work-life balance topics that people always talk about?",
    answer:
      "It may be helpful to consider re-thinking how you are approaching the work-life ‘balance’ notion that is commonly used and consider the notion of work-life ‘integration’ instead.   Balance infers equal and that might not always be possible; whereas integration allows for overlapping, combining, mixing, and amalgamating things simultaneously so that multiple goals can be pursued at the same time.  This is where the Mental Health Connections Self-Prescription activity fits as it provides a mechanism to explore how to plan for and integrate different determinants of mental health into both work and life contexts.",
  },
  {
    id: 2,
    question:
      "I like the concept of making Mental Health Connections, but what should I do if I’m struggling to find the motivation to get started?",
    answer:
      "You might want to consider reaching out to a trusted friend, family member, or a mental health professional to discuss your concerns, get support, and brainstorm around completing the steps of the Mental Health Connections Self-Prescription activity.",
  },
  {
    id: 3,
    question:
      "What do I do if I have a mental health item that I want to capture in one of my Health Circles, but I’m having a hard time figuring out which one to put it in because it could fit in a few?",
    answer:
      "Great question.  The best part is that there are no ‘wrong answers’ as this is your Mental Health Connections Self-Prescription activity, so you get to decide how to use it.  If you want to capture an item in multiple Health Circles that is fine, and then you can use it in different ways to make connections that make sense to you.",
  },
  {
    id: 4,
    question:
      "If I’m already seeing a mental health professional, how does this activity fit?",
    answer:
      "We are pleased that you have already taken what is often the hardest step by going to see someone – well done!  The Mental Health Connections Self-Prescription activity is designed to be compatible with mental health care support as the Health Circles that form the basis for making connections in this activity are all supported by evidence-based research to be positively connected to mental health.  We encourage you to consider sharing your Mental Health Connections Self-Prescription activity with your mental health professional for further discussion and support.",
  },
  // More questions...
];

export default function FAQPage() {
  return (
    <div>
      <HeaderMenu />
      <main className='pt-24 sm:py-32'>
        <div className='absolute inset-0 -z-10 overflow-hidden'>
          <div
            className='absolute left-0 top-0 -z-10 transform-gpu overflow-hidden blur-3xl sm:top-0'
            aria-hidden='true'
          >
            <div
              className='relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-primary-light to-primary opacity-30 sm:left-4 sm:w-[72.1875rem]'
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div
            className='absolute left-0 bottom-0 -z-10 transform-gpu overflow-hidden blur-3xl sm:bottom-0'
            aria-hidden='true'
          >
            <div
              className='aspect-[1097/845] w-[68.5625rem] bg-gradient-to-r from-primary-light to-primary opacity-25'
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
        </div>
        <div className='w-3/4 mx-auto px-6'>
          <h2 className='text-3xl sm:text-4xl p-2 font-bold leading-10 tracking-tight text-dark-text'>
            Frequently Asked Questions
          </h2>
          <dl className='mt-10 space-y-8 divide-y divide-gray-600'>
            {faqs.map((faq) => (
              <div
                key={faq.id}
                className='pt-8 lg:grid lg:grid-cols-12 lg:gap-8'
              >
                <dt className='text-lg font-semibold leading-7 text-dark-text lg:col-span-5'>
                  <span className='font-bold '>Q:</span> {faq.question}
                </dt>
                <dd className='mt-4 lg:col-span-7 lg:mt-0'>
                  <p className='text-lg leading-7 text-dark-text'>
                    <span className='font-bold '>A:</span> {faq.answer}
                  </p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </main>
      <FooterWebsite className='pt-0' />
    </div>
  );
}
