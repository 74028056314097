import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Dialog,
  Transition,
  Switch,
  Combobox,
  DialogPanel,
  TransitionChild,
  DialogTitle,
  ComboboxOptions,
  ComboboxOption,
} from "@headlessui/react";
import { Field, Form, Formik } from "formik";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import NonagoneMap from "../NonagoneMap/NonagoneMap";
import CreateEvent from "../../../../Components/Event/CreateEvent";
import CustomTimeJump from "../CustomTimeJump";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../../../../firebase-config";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CreateEventPopup({
  show: initailOpen,
  onClose,
  ...props
}) {
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [currentConnection, setCurrentConnection] = useState([]);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [allDayEnabled, setAllDayEnabled] = useState(false);
  const [repeatEnabled, setRepeatEnabled] = useState(false);
  const [repeatType, setRepeatType] = useState("day");
  const [weekSelected, setWeekSelected] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [attendeesQuery, setAttendeesQuery] = useState("");
  const [attendeesList, setAttendeesList] = useState([]);
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [personList, setPersonList] = useState([]);

  // init the person list from the database if its not already there
  useEffect(() => {
    const getPersonList = async () => {
      // get the person list from the database
      const docRef = doc(db, "User/" + props.userID + "/Person", "Person");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setPersonList(docSnap.data().personList);
      } else {
        await updateDoc(docRef, { personList: [] });
      }
    };
    getPersonList();
  }, []);

  const filteredPeople =
    attendeesQuery === ""
      ? []
      : personList.filter((person) => {
          return person.email
            .toLowerCase()
            .includes(attendeesQuery.toLowerCase());
        });

  const addPerson = (person) => {
    // Check if the person already exists in the list based on their email
    const personExists = attendeesList.some(
      (attendee) => attendee.email === person.email
    );

    if (!personExists) {
      // If the person does not exist, add them to the list
      setAttendeesList([...attendeesList, person]);
    }

    setAttendeesQuery("");

    //check if the person is already in personList
    //if not add them
    let found = 0; // 0 is false
    const personDoc = doc(db, "User/" + props.userID + "/Person", "Person");
    for (const tmp of personList) {
      if (tmp.email === person.email) {
        found = 1;
        return;
      }
    }
    if (found === 0) {
      updateDoc(personDoc, {
        personList: [...personList, person],
      });
    }
  };
  //person is the email not the object
  const removePerson = (person) => {
    const newArray = attendeesList.filter((item) => item.email !== person);
    setAttendeesList(newArray);
  };

  const translateNewDate = (value) => {
    // convert the date to the correct format yyy-mm-dd
    let date = value;
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, "0");
    let day = String(date.getDate()).padStart(2, "0");
    let dateString = year + "-" + month + "-" + day;
    return dateString;
  };

  const translateNewTime = (value) => {
    // output hh:mm (24 hour time)
    let date = value;
    let hour = date.getHours();
    let minute = date.getMinutes();
    let timeString =
      String(hour).padStart(2, "0") +
      ":" +
      String(minute).padStart(2, "0") +
      ":00";
    return timeString;
  };

  useEffect(() => {
    setOpen(initailOpen);
    setRepeatEnabled(false);
    setAllDayEnabled(false);
  }, [initailOpen]);

  useEffect(() => {
    setCurrentConnection(props.currentConnection);
  }, [props.currentConnection]);

  useEffect(() => {
    setPersonList(props.personList);
  }, [props.personList]);

  useEffect(() => {
    setCalendarEvents(props.calendarEvents);
  }, [props.calendarEvents]);

  const handleClose = (calendarEvents) => {
    setOpen(false);
    if (onClose) {
      onClose(calendarEvents);
    }
  };

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-10'
        initialFocus={cancelButtonRef}
        onClose={() => handleClose()}
      >
        <TransitionChild
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </TransitionChild>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex justify-stretch sm:justify-center align-middle grow min-h-max sm:min-h-full max-h-max w-full py-20 sm:py-20 lg:pl-72 p-4 text-center sm:items-center'>
            <TransitionChild
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <DialogPanel className='relative transform overflow-hidden rounded-lg bg-white px-4 mx-8 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full max-w-2xl sm:p-6'>
                <div>
                  <div className='mt-3 text-center sm:mt-5'>
                    <DialogTitle
                      as='h3'
                      className='text-base font-semibold leading-6 text-gray-900'
                    >
                      Create event for {currentConnection.name}
                    </DialogTitle>
                    <div className='mt-2'>
                      <Formik
                        initialValues={{
                          attendees: "",
                          startDate: startDate
                            ? startDate
                            : translateNewDate(new Date()),
                          endDate: endDate
                            ? endDate
                            : translateNewDate(new Date()),
                          startTime: startTime
                            ? startTime
                            : translateNewTime(new Date()),
                          endTime: endTime
                            ? endTime
                            : translateNewTime(new Date()),
                          repeatFlag: currentConnection.repeatFlag || false,
                          repeatType: currentConnection.repeatType || "day",
                          repeatEnd:
                            currentConnection.repeatEnd !== "none"
                              ? translateNewDate(
                                  new Date(currentConnection.repeatEnd)
                                )
                              : translateNewDate(new Date()),
                          location: currentConnection.location || "",
                        }}
                        validate={async (values) => {
                          const errors = {};

                          if (values.endDate < values.startDate) {
                            values.endDate = values.startDate;
                          }
                          if (values.endTime < values.startTime) {
                            values.endTime = values.startTime;
                          }

                          return errors;
                        }}
                        onSubmit={async (values) => {
                          console.log(values);
                          const newEvents = await CreateEvent(
                            currentConnection.name,
                            values.location,
                            values.startDate,
                            values.endDate,
                            values.startTime,
                            values.endTime,
                            props.userID,
                            allDayEnabled,
                            currentConnection.sectionList,
                            repeatType,
                            values.repeatEnd,
                            attendeesList,
                            values.repeatFlag,
                            weekSelected
                          );
                          setCalendarEvents(newEvents);
                          handleClose(newEvents);
                        }}
                      >
                        {({ setFieldValue, values }) => (
                          <Form className=''>
                            <div className='mt-4 flex flex-col '>
                              <div className='col-span-3 mt-4 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-3 sm:gap-3'>
                                <div>
                                  <label
                                    htmlFor='date'
                                    className='block text-sm font-medium text-gray-700 my-2'
                                  >
                                    Start Date
                                  </label>
                                  <Field
                                    type='date'
                                    name='startDate'
                                    id='startDate'
                                    autoComplete='date'
                                    className='mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                                  />
                                </div>
                                <div>
                                  <label
                                    htmlFor='date'
                                    className='block text-sm font-medium text-gray-700 my-2'
                                  >
                                    End Date
                                  </label>
                                  <Field
                                    type='date'
                                    name='endDate'
                                    id='endDate'
                                    autoComplete='date'
                                    className='mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                                  />
                                </div>
                                <div className='my-2 row-span-2'>
                                  {currentConnection.sectionList ===
                                    undefined ||
                                  currentConnection.sectionList.length ===
                                    0 ? null : (
                                    <div className='flex justify-center'>
                                      <NonagoneMap
                                        data={currentConnection.sectionList}
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className='p-4 py-6 flex col-span-2 flex-row justify-evenly  align-middle'>
                                  <div className='flex items-center mx-4'>
                                    <Switch
                                      checked={allDayEnabled}
                                      name='allDay'
                                      id='allDay'
                                      onChange={setAllDayEnabled}
                                      className={classNames(
                                        allDayEnabled
                                          ? "bg-primary"
                                          : "bg-gray-200",
                                        "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                                      )}
                                    >
                                      <span
                                        aria-hidden='true'
                                        name='allDay'
                                        id='allDay'
                                        className={classNames(
                                          allDayEnabled
                                            ? "translate-x-5"
                                            : "translate-x-0",
                                          "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                        )}
                                      />
                                    </Switch>
                                    <label
                                      as='span'
                                      name='allDay'
                                      id='allDay'
                                      className='ml-3 text-sm'
                                    >
                                      <span className='font-medium text-gray-900'>
                                        All day
                                      </span>
                                    </label>
                                  </div>
                                  <div className='flex items-center mx-4'>
                                    <Switch
                                      checked={repeatEnabled}
                                      name='repeatFlag'
                                      id='repeatFlag'
                                      onChange={(newValue) => {
                                        setFieldValue("repeatFlag", newValue);
                                        setRepeatEnabled(!repeatEnabled);
                                      }}
                                      className={classNames(
                                        repeatEnabled
                                          ? "bg-primary"
                                          : "bg-gray-200",
                                        "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                                      )}
                                    >
                                      <span
                                        aria-hidden='true'
                                        className={classNames(
                                          repeatEnabled
                                            ? "translate-x-5"
                                            : "translate-x-0",
                                          "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                        )}
                                      />
                                    </Switch>
                                    <label as='span' className='ml-3 text-sm'>
                                      <span className='font-medium text-gray-900'>
                                        Repeat
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>

                              {allDayEnabled === false ? (
                                <div className='flex flex-col sm:flex-row justify-center'>
                                  <div className='mx-auto'>
                                    <label
                                      htmlFor='time'
                                      className='text-sm font-medium text-gray-700'
                                    >
                                      Start time
                                    </label>
                                    <Field
                                      name='startTime'
                                      id='startTime'
                                      component={CustomTimeJump}
                                      autoComplete='time'
                                      className='mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                                    />
                                  </div>
                                  <div className='mx-auto'>
                                    <label
                                      htmlFor='time'
                                      className='text-sm font-medium text-gray-700'
                                    >
                                      End time
                                    </label>
                                    <Field
                                      name='endTime'
                                      id='endTime'
                                      component={CustomTimeJump}
                                      autoComplete='time'
                                      className='mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                                    />
                                  </div>
                                </div>
                              ) : null}

                              {repeatEnabled === true ? (
                                <motion.div className='mt-4'>
                                  <label
                                    htmlFor='repeat'
                                    className='block text-sm font-medium text-gray-700'
                                  >
                                    Repeat
                                  </label>
                                  <p>
                                    Repeat every
                                    <Field
                                      as='select'
                                      name='repeatType'
                                      id='repeatType'
                                      onChange={(e) => {
                                        setFieldValue(
                                          "repeatType",
                                          e.target.value
                                        );
                                        setRepeatType(e.target.value);
                                      }}
                                      className='ml-2 mt-1 focus:ring-primary focus:border-primary  shadow-sm sm:text-sm border-gray-300 rounded-md'
                                    >
                                      <option value='day'>day</option>
                                      <option value='week'>week</option>
                                    </Field>
                                  </p>
                                  {repeatType === "week" ? (
                                    <ul
                                      key='dayList'
                                      className='flex flex-row justify-between mt-4'
                                    >
                                      <motion.li
                                        animate={{
                                          scale: weekSelected[0] ? 1.2 : 1,
                                        }}
                                        key='Monday'
                                        onClick={() => {
                                          const updatedWeekSelected = [
                                            ...weekSelected,
                                          ]; // Create a copy of the array
                                          updatedWeekSelected[0] =
                                            !updatedWeekSelected[0]; // Toggle the value of the first element
                                          setWeekSelected(updatedWeekSelected); // Update the state with the new array
                                        }}
                                        className={classNames(
                                          weekSelected[0]
                                            ? "bg-primary-light text-dark-text"
                                            : "bg-primary rounded-full text-light-text",
                                          "w-2/12 cursor-pointer mx-2"
                                        )}
                                      >
                                        M
                                      </motion.li>
                                      <motion.li
                                        animate={{
                                          scale: weekSelected[1] ? 1.2 : 1,
                                        }}
                                        key='Tuesday'
                                        onClick={() => {
                                          const updatedWeekSelected = [
                                            ...weekSelected,
                                          ]; // Create a copy of the array
                                          updatedWeekSelected[1] =
                                            !updatedWeekSelected[1]; // Toggle the value of the first element
                                          setWeekSelected(updatedWeekSelected); // Update the state with the new array
                                        }}
                                        className={classNames(
                                          weekSelected[1]
                                            ? "bg-primary-light text-dark-text"
                                            : "bg-primary rounded-full text-light-text",
                                          "w-2/12 cursor-pointer mx-2"
                                        )}
                                      >
                                        T
                                      </motion.li>
                                      <motion.li
                                        animate={{
                                          scale: weekSelected[2] ? 1.2 : 1,
                                        }}
                                        key='Wednesday'
                                        onClick={() => {
                                          const updatedWeekSelected = [
                                            ...weekSelected,
                                          ]; // Create a copy of the array
                                          updatedWeekSelected[2] =
                                            !updatedWeekSelected[2]; // Toggle the value of the first element
                                          setWeekSelected(updatedWeekSelected); // Update the state with the new array
                                        }}
                                        className={classNames(
                                          weekSelected[2]
                                            ? "bg-primary-light text-dark-text"
                                            : "bg-primary rounded-full text-light-text",
                                          "w-2/12 cursor-pointer mx-2"
                                        )}
                                      >
                                        W
                                      </motion.li>
                                      <motion.li
                                        animate={{
                                          scale: weekSelected[3] ? 1.2 : 1,
                                        }}
                                        key='Thursday'
                                        onClick={() => {
                                          const updatedWeekSelected = [
                                            ...weekSelected,
                                          ]; // Create a copy of the array
                                          updatedWeekSelected[3] =
                                            !updatedWeekSelected[3]; // Toggle the value of the first element
                                          setWeekSelected(updatedWeekSelected); // Update the state with the new array
                                        }}
                                        className={classNames(
                                          weekSelected[3]
                                            ? "bg-primary-light text-dark-text"
                                            : "bg-primary rounded-full text-light-text",
                                          "w-2/12 cursor-pointer mx-2"
                                        )}
                                      >
                                        T
                                      </motion.li>
                                      <motion.li
                                        animate={{
                                          scale: weekSelected[4] ? 1.2 : 1,
                                        }}
                                        key='Friday'
                                        onClick={() => {
                                          const updatedWeekSelected = [
                                            ...weekSelected,
                                          ]; // Create a copy of the array
                                          updatedWeekSelected[4] =
                                            !updatedWeekSelected[4]; // Toggle the value of the first element
                                          setWeekSelected(updatedWeekSelected); // Update the state with the new array
                                        }}
                                        className={classNames(
                                          weekSelected[4]
                                            ? "bg-primary-light text-dark-text"
                                            : "bg-primary rounded-full text-light-text",
                                          "w-2/12 cursor-pointer mx-2"
                                        )}
                                      >
                                        F
                                      </motion.li>
                                      <motion.li
                                        animate={{
                                          scale: weekSelected[5] ? 1.2 : 1,
                                        }}
                                        key='Saturday'
                                        onClick={() => {
                                          const updatedWeekSelected = [
                                            ...weekSelected,
                                          ]; // Create a copy of the array
                                          updatedWeekSelected[5] =
                                            !updatedWeekSelected[5]; // Toggle the value of the first element
                                          setWeekSelected(updatedWeekSelected); // Update the state with the new array
                                        }}
                                        className={classNames(
                                          weekSelected[5]
                                            ? "bg-primary-light text-dark-text"
                                            : "bg-primary rounded-full text-light-text",
                                          "w-2/12 cursor-pointer mx-2"
                                        )}
                                      >
                                        S
                                      </motion.li>
                                      <motion.li
                                        animate={{
                                          scale: weekSelected[6] ? 1.2 : 1,
                                        }}
                                        key='Sunday'
                                        onClick={() => {
                                          const updatedWeekSelected = [
                                            ...weekSelected,
                                          ]; // Create a copy of the array
                                          updatedWeekSelected[6] =
                                            !updatedWeekSelected[6]; // Toggle the value of the first element
                                          setWeekSelected(updatedWeekSelected); // Update the state with the new array
                                        }}
                                        className={classNames(
                                          weekSelected[6]
                                            ? "bg-primary-light text-dark-text"
                                            : "bg-primary rounded-full text-light-text",
                                          "w-2/12 cursor-pointer mx-2"
                                        )}
                                      >
                                        S
                                      </motion.li>
                                    </ul>
                                  ) : null}
                                  <div>
                                    <p className='text-left mt-4'>
                                      Occurs every{" "}
                                      {days.map((item, index) => {
                                        if (
                                          weekSelected.every(
                                            (item) =>
                                              item === false && index === 0
                                          )
                                        ) {
                                          return (
                                            <span key='everyDay'>
                                              every day
                                            </span>
                                          );
                                        } else {
                                          return (
                                            <span key={index}>
                                              {" "}
                                              {weekSelected[index] ? (
                                                <span>{item}, </span>
                                              ) : null}
                                            </span>
                                          );
                                        }
                                      })}{" "}
                                      until{" "}
                                      <Field
                                        type='date'
                                        name='repeatEnd'
                                        id='repeatEnd'
                                        autoComplete='repeatEnd'
                                        className='mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                                      />{" "}
                                      {/* <button
                                type='button'
                                onClick={() => setRepeatEnd("")}
                                className='text-primary'
                              >
                                Remove end date
                              </button> */}
                                    </p>
                                  </div>
                                </motion.div>
                              ) : null}

                              <div className='flex flex-col sm:flex-row my-4 justify-between w-full'>
                                <div className='flex flex-col w-full sm:w-1/2'>
                                  <label
                                    htmlFor='location'
                                    className='block text-sm font-medium text-gray-700'
                                  >
                                    Location
                                  </label>

                                  <Field
                                    type='text'
                                    name='location'
                                    id='location'
                                    autoComplete='location'
                                    className='mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                                  />
                                  <div className=''>
                                    <label
                                      htmlFor='location'
                                      className='block text-sm font-medium text-gray-700 mt-4 mb-2'
                                    >
                                      Invite attendees
                                    </label>

                                    <div>
                                      <DialogPanel className=' mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all'>
                                        <Combobox>
                                          <div className='relative'>
                                            <MagnifyingGlassIcon
                                              className='pointer-events-none absolute left-4 top-2.5 h-5 w-5 text-gray-400'
                                              aria-hidden='true'
                                            />
                                            <Combobox.Input
                                              className=' w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm focus:ring-primary focus:border-primary'
                                              placeholder='Invite attendees'
                                              onChange={(event) =>
                                                setAttendeesQuery(
                                                  event.target.value
                                                )
                                              }
                                            />
                                          </div>

                                          {filteredPeople.length > 0 && (
                                            <ComboboxOptions
                                              static
                                              className='z-10 max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800'
                                            >
                                              {filteredPeople.map((person) => (
                                                <ComboboxOption
                                                  key={person.id}
                                                  value={person}
                                                  type='button'
                                                  onClick={() =>
                                                    addPerson(person)
                                                  }
                                                  className={({ active }) =>
                                                    classNames(
                                                      "cursor-default select-none px-4 py-2",
                                                      active &&
                                                        "bg-primary text-white"
                                                    )
                                                  }
                                                >
                                                  {person.name}
                                                </ComboboxOption>
                                              ))}
                                            </ComboboxOptions>
                                          )}

                                          {attendeesQuery !== "" &&
                                            filteredPeople.length === 0 && (
                                              <p
                                                className='p-4 text-sm font-bold text-gray-500'
                                                onClick={() =>
                                                  addPerson({
                                                    id: personList.length + 1,
                                                    email: attendeesQuery,
                                                    name: attendeesQuery,
                                                  })
                                                }
                                              >
                                                No emails found. Click to add
                                              </p>
                                            )}
                                        </Combobox>
                                      </DialogPanel>
                                    </div>
                                  </div>
                                </div>

                                <div className='flex flex-col mx-auto'>
                                  <label className='text-sm font-medium text-gray-700 mt-4'>
                                    Invited
                                  </label>
                                  {attendeesList.length !== 0 ? (
                                    <ul className='mt-4 border-2 border-dark-text rounded-lg max-h-44 overflow-y-auto'>
                                      {attendeesList.map((person) => (
                                        <li
                                          key={person.email}
                                          className='flex m-2'
                                        >
                                          <p
                                            onClick={() =>
                                              removePerson(person.email)
                                            }
                                            className='text-md text-dark-text font-semibold text-center cursor-pointer'
                                          >
                                            {person.email}
                                          </p>
                                        </li>
                                      ))}
                                    </ul>
                                  ) : (
                                    <div>No attendees</div>
                                  )}
                                </div>
                              </div>

                              <div className='col-span-3 mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3'>
                                <button
                                  type='submit'
                                  className='inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white hover:text-dark-text shadow-sm hover:bg-primary-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-light sm:col-start-2'
                                >
                                  Create Event
                                </button>

                                <button
                                  type='button'
                                  className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0'
                                  onClick={() => handleClose()}
                                  ref={cancelButtonRef}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
