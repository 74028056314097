import React, { useState, useEffect } from "react";
import { Fragment, useRef } from "react";
import {
  Dialog,
  Transition,
  TransitionChild,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { Formik, Form, Field } from "formik";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../../../firebase-config";

// props: type(bug, content)
export default function ReportPopup({ open: initialOpen, onClose, ...props }) {
  const [report, setReport] = useState(initialOpen);
  const [bugSent, setBugSent] = useState(false);

  // Sync local state with parent prop
  useEffect(() => {
    setReport(initialOpen);
  }, [initialOpen]);

  // Handle closing the dialog
  const handleClose = () => {
    setReport(false);
    if (onClose) {
      onClose(); // Call the parent's onClose callback if provided
    }
  };

  return (
    <div>
      <Transition show={report} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={handleClose}>
          <TransitionChild
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </TransitionChild>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center lg:pl-60 sm:p-0'>
              <TransitionChild
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <DialogPanel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
                  <div>
                    <div className='mt-3 text-center sm:mt-5'>
                      <DialogTitle
                        as='h3'
                        className='text-lg font-semibold leading-6 text-gray-900'
                      >
                        Report {props.type}
                      </DialogTitle>
                      <div className='mt-2'>
                        <Formik
                          initialValues={{}}
                          onSubmit={async (values) => {
                            // send the email
                            let payload = {
                              to: "info@mhconnections.ca",
                              message: {
                                subject: "Bug/Content report",
                                replyTo: props.userInfo.email_username,
                                html: `<h1>Bug/Content Report</h1>
                                <p>Email: ${props.userInfo.email_username}</p>
                                <p>Message: ${values.message}</p>`,
                              },
                            };
                            addDoc(collection(db, "contact_us_email"), payload);
                            //setReportBug(false);
                            //setBugSent(false);
                            onClose();
                          }}
                        >
                          <Form className='w-3/4 mx-auto'>
                            <div className=''>
                              {bugSent ? (
                                <div>
                                  <p className='text-left'>
                                    Thank you for your feedback. It has been
                                    sent to our team for review.
                                  </p>

                                  <button
                                    type='submit'
                                    className='mt-2 inline-flex col-span-2 w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white hover:text-dark-text shadow-sm hover:bg-primary-light'
                                  >
                                    Close
                                  </button>
                                </div>
                              ) : (
                                <div>
                                  <p className='text-left'>
                                    In your report include the title of the page
                                    your on and a brief description of the bug
                                    you encountered.
                                  </p>
                                  <Field
                                    as='textarea'
                                    rows='5'
                                    name='message'
                                    id='message'
                                    className='mt-2 block w-full shadow-sm sm:text-sm focus:ring-primary focus:border-primary border-gray-300 rounded-md '
                                    placeholder='Message'
                                  />

                                  <button
                                    type='button'
                                    onClick={() => setBugSent(true)}
                                    className='mt-2 inline-flex col-span-2 w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white hover:text-dark-text shadow-sm hover:bg-primary-light'
                                  >
                                    Send
                                  </button>
                                </div>
                              )}
                            </div>
                          </Form>
                        </Formik>
                      </div>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
