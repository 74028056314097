import React, { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Form, Formik, Field, ErrorMessage } from "formik";
import FooterWebsite from "../../Components/FooterWebsite";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/20/solid";
import { RadioGroup } from "@headlessui/react";

import sideTextLogoWhite from "../../../src/Images/sideLogoBlack2.svg";
import {
  addDoc,
  collection,
  doc,
  Timestamp,
  getDoc,
  getDocs,
  query,
  where,
  limit,
  writeBatch,
} from "firebase/firestore";
import { auth, db } from "../../firebase-config";
import { FirebaseError } from "firebase/app";
import { createUserWithEmailAndPassword } from "firebase/auth";
import dayjs from "dayjs";
import { useLocation, useNavigate, Link } from "react-router-dom";

const steps = [
  { name: "User Information", href: "#", status: "current" },
  { name: "Billing Information", href: "/BillingPage", status: "upcoming" },
  { name: "Confirmation", href: "#", status: "upcoming" },
];

const frequencies = [
  { value: "annually", label: "Annually", priceSuffix: "/year" },

  { value: "monthly", label: "Monthly", priceSuffix: "/month" },
];

const tiers = [
  {
    name: "month",
    label: "Monthly",
    id: "tier-monthly",
    href: "#",
    price: "$5",
    description:
      "Most flexible. For those who want to try this out and getting the benefits of actively planning for & tracking their mental health.",
    features: [
      "Weekly Self-Assessment of Mental Health and the Health Circles",
      "Create your Mental Health Connections",
      "Add Mental Health Connections to your calendar, set reminders, and invite others to join your connections",
      "Track your progress",
    ],
    featured: false,
    cta: "Buy plan",
  },
  {
    name: "annually",
    label: "Annually",
    id: "tier-annual",
    href: "#",
    price: "$40",
    description:
      "Most popular. For those who want to fully immerse themselves with Mental Health Connections and its features.",
    features: [
      "Weekly Self-Assessment of Mental Health and the Health Circles",
      "Create your Mental Health Connections",
      "Add Mental Health Connections to your calendar, set reminders, and invite others to join your connections",
      "Track your progress",
    ],
    featured: true,
    cta: "Contact sales",
  },
];

const includedFeatures = [
  "Weekly Self-Assessment of Mental Health and the Health Circles",
  "Create your Mental Health Connections",
  "Add Mental Health Connections to your calendar, set reminders, and invite others to join your connections",
  "Track your progress",
];

export default function CreateAccount() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [date, setDate] = useState(dayjs(new Date()));
  const location = useLocation();
  const getQueryParams = (queryString) => {
    return new URLSearchParams(queryString);
  };

  const queryParams = getQueryParams(location.search);
  const queryPlan = queryParams.get("plan");

  const initialPlan = location.state?.plan ?? queryPlan ?? "Annually";
  const [plan] = useState(initialPlan);
  const [frequency, setFrequency] = useState(frequencies[0]);

  useEffect(() => {
    if (plan === "Monthly") {
      setFrequency(frequencies[1]);
    } else {
      setFrequency(frequencies[0]);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Mental Health Connections"; // Set the new tab name here
  }, []);

  const createCollection = async (uid) => {
    const docRef = doc(db, "User", uid);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {
      alert("Error: User document does not exist. Please contact support.");
      return;
    }

    const connectionsCollectionRef = collection(db, "User", uid, "Connections");
    const personCollectionRef = collection(db, "User", uid, "Person");
    const calendarCollectionRef = collection(db, "User", uid, "Calendar");
    const itemsCollectionRef = collection(db, "User", uid, "Items");

    const [connections, people, calendars, items] = await Promise.all([
      getDocs(connectionsCollectionRef, limit(1)),
      getDocs(personCollectionRef, limit(1)),
      getDocs(calendarCollectionRef, limit(1)),
      getDocs(itemsCollectionRef, limit(1)),
    ]);

    const batch = writeBatch(db);

    if (connections.size === 0) {
      batch.set(doc(connectionsCollectionRef, "Connections"), {
        connectionList: [],
      });
    }
    // for inviting others to join your connections
    if (people.size === 0) {
      batch.set(doc(personCollectionRef, "Person"), { personList: [] });
    }

    if (calendars.size === 0) {
      batch.set(doc(calendarCollectionRef, "Calendar"), {
        events: [],
        reminders: [
          { active: false, name: "daily", reminderTime: Timestamp.now() },
          { active: false, name: "weekly", reminderTime: Timestamp.now() },
        ],
      });
    }
    if (items.size === 0) {
      const itemDocs = [
        "healthy_nutrition_snacks",
        "hobbies",
        "laughter_makes_you_smile",
        "movement_activity",
        "outdoors_nature",
        "planning_looking_forward_to",
        "self_care",
        "sleep_recovery",
        "social_interaction",
      ];

      itemDocs.forEach((item) => {
        batch.set(doc(itemsCollectionRef, item), { section_list: [] });
      });
    }
    await batch.commit();
  };

  const required = (value) => (value ? undefined : "Required");

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <div key='create account page'>
      <header className='relative border-b border-gray-200 bg-white text-sm font-medium text-gray-700'>
        <div className='mx-auto max-w-7xl px-4 pb-16 my-4 mb-8 sm:px-6 lg:px-8'>
          <div className='relative flex justify-end sm:justify-center'>
            <Link to='/' className='absolute left-0 top-1/2 z-10'>
              <img src={sideTextLogoWhite} alt='' className='max-h-20 w-auto' />
            </Link>
          </div>
        </div>
      </header>
      <main className='isolate'>
        <div className='absolute inset-y-0 left-0 -z-10 w-full lg:w-1/2'>
          <div
            className='absolute left-0 top-0 -z-10 transform-gpu overflow-hidden blur-3xl sm:top-0'
            aria-hidden='true'
          >
            <div
              className='relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-primary-light to-primary opacity-30 sm:left-4 sm:w-[72.1875rem]'
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div
            className='absolute left-0 bottom-0 -z-10 transform-gpu overflow-hidden blur-3xl sm:bottom-0'
            aria-hidden='true'
          >
            <div
              className='aspect-[1097/845] w-[68.5625rem] bg-gradient-to-r from-primary-light to-primary opacity-25'
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
        </div>
        <div>
          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              email_username: "",
              password: "",
              confirm_password: "",
              country: "",
              gender: "",
              terms_and_conditions: false,
              product_updates: false,
              achievments_and_reminders: false,
              external_resources_and_links: false,
              offers: false,
            }}
            validate={(values) => {
              const errors = {};
              if (!values.first_name) {
                errors.first_name = "Required";
              }
              if (!values.last_name) {
                errors.last_name = "Required";
              }
              if (!values.password) {
                errors.password = "Required";
              } else if (values.password.length < 8) {
                errors.password = "Password must be at least 8 characters";
              }
              if (!values.confirm_password) {
                errors.confirm_password = "Required";
              } else if (values.confirm_password !== values.password) {
                errors.confirm_password = "Passwords must match";
              }
              if (!values.email_username) {
                errors.email_username = "Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                  values.email_username
                )
              ) {
                errors.email_username = "Invalid email address";
              }
              if (!values.country) {
                errors.country = "Required";
              }
              if (!values.gender) {
                errors.gender = "Required";
              }

              if (values.terms_and_conditions !== true) {
                errors.terms_and_conditions = "Required";
              }
              return errors;
            }}
            onSubmit={async (values) => {
              // creating a new user in the database

              createUserWithEmailAndPassword(
                auth,
                values.email_username,
                values.password
              )
                .then(async (userCredential) => {
                  // Signed in
                  const user = userCredential.user;
                  const collectionRef = collection(db, "User");
                  // why is the only field not required
                  if (!values.why) {
                    values.why = "";
                  }
                  const payload = {
                    about_your_self: values.why,
                    email_username: values.email_username,
                    first_name: values.first_name,
                    last_name: values.last_name,
                    country: values.country,
                    gender: values.gender,
                    paid: false,
                    number_completed: 0,
                    uuid: user.uid,
                    year_of_birth: Timestamp.fromDate(date.toDate()), // maybe .$y
                    weeks_in_a_row: 0,
                    terms_and_conditions: values.terms_and_conditions,
                    verified: false,
                    notification: {
                      product_updates: values.product_updates,
                      achievments_and_reminders:
                        values.achievments_and_reminders,
                      external_resources_and_links:
                        values.external_resources_and_links,
                      offers: values.offers,
                    },
                  };
                  await addDoc(collectionRef, payload);
                  // get the document that was just created
                  const userCollectionRef = collection(db, "User");
                  const userQ = query(
                    userCollectionRef,
                    where("uuid", "==", user.uid)
                  );
                  const userQuerySnapshot = await getDocs(userQ);
                  const docRef = doc(db, "User", userQuerySnapshot.docs[0].id);

                  const docSnap = await getDoc(docRef);
                  if (docSnap.exists()) {
                    await createCollection(docSnap.id);

                    const payload = {
                      to: values.email_username,
                      template: {
                        name: "welcome_email",
                        data: {
                          name: values.first_name + " " + values.last_name,
                        },
                      },
                    };
                    await addDoc(collection(db, "contact_us_email"), payload);

                    // after the user is created on the backend go the the billing page by calling stipe
                    //useCheckout(user.uid);
                    //navigate("/LoginPage");
                    navigate("/BillingPage", {
                      state: { plan: frequency.label, user: user.uid },
                    });
                  } else {
                    // doc.data() will be undefined in this case
                    alert("Alert");
                  }
                  // create the collection for the user
                })
                .catch((error) => {
                  if (error instanceof FirebaseError) {
                    console.error("Firebase Error:", error.code, error.message);
                  } else {
                    console.error("General Error:", error.code, error.message);
                  }
                  //Handle specific error codes if needed
                  if (error.code === "auth/email-already-in-use") {
                    alert("That email address is already in use!");
                  }
                });
            }}
          >
            {({ handleSubmit, errors }) => (
              <Form
                className='w-3/4 mx-auto'
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleSubmit();
                  }
                }}
              >
                <div className='space-y-12'>
                  <div className='grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 py-12 md:grid-cols-3'>
                    <div>
                      <h2 className='text-base font-semibold leading-7 text-dark-text'>
                        Tell us about yourself
                      </h2>
                    </div>

                    <div className='grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2'>
                      <div className='sm:col-span-full'>
                        <label
                          htmlFor='first-name'
                          className='block text-md font-medium leading-6 text-dark-text'
                        >
                          What encouraged you to participate in the Mental
                          Health Connections Self-Prescription activity?
                        </label>
                        <div className='mt-2'>
                          <Field
                            type='text'
                            name='why'
                            id='why'
                            className='block w-full rounded-md border-0 py-1.5 text-dark-text shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3'>
                    <div>
                      <h2 className='text-base font-semibold leading-7 text-dark-text'>
                        Account Information
                      </h2>
                      <p className='mt-1 text-sm leading-6 text-gray-600'>
                        Use an email address that you have access to.
                      </p>
                    </div>

                    <div className='grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2'>
                      <div className='sm:col-span-full'>
                        <label
                          htmlFor='first-name'
                          className='block text-sm font-medium leading-6 text-dark-text'
                        >
                          Email Address
                        </label>
                        <div className='mt-2'>
                          <Field
                            type='text'
                            name='email_username'
                            id='email_username'
                            autoComplete='username'
                            validate={required}
                            className='block w-full rounded-md border-0 py-1.5 text-dark-text shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6'
                          />
                          <ErrorMessage
                            name='email/username'
                            component='div'
                            className='text-red-500 text-sm'
                          />
                        </div>
                      </div>

                      <div className='sm:col-span-full'>
                        <label
                          htmlFor='last-name'
                          className='block text-sm font-medium leading-6 text-dark-text'
                        >
                          Password
                          {showPassword ? (
                            <button
                              className='float-right'
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              <p>
                                <EyeSlashIcon className='h-5 w-5 text-dark-text float-left mr-2' />{" "}
                                Hide
                              </p>
                            </button>
                          ) : (
                            <button
                              className='float-right'
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              <p>
                                <EyeIcon className='h-5 w-5 text-dark-text float-left mr-2' />{" "}
                                Show
                              </p>
                            </button>
                          )}
                        </label>
                        <div className='mt-2'>
                          <Field
                            type={showPassword ? "text" : "password"}
                            name='password'
                            id='password'
                            autoComplete='password'
                            validate={required}
                            className='block w-full rounded-md border-0 py-1.5 text-dark-text shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6'
                          />
                          <ErrorMessage
                            name='password'
                            component='div'
                            className='text-red-500 text-sm'
                          />
                        </div>
                      </div>
                      <div className='sm:col-span-full'>
                        <label
                          htmlFor='last-name'
                          className='block text-sm font-medium leading-6 text-dark-text'
                        >
                          Confirm Password
                        </label>
                        <div className='mt-2'>
                          <Field
                            type={showPassword ? "text" : "password"}
                            name='confirm_password'
                            id='confirm_password'
                            autoComplete='password'
                            validate={required}
                            className='block w-full rounded-md border-0 py-1.5 text-dark-text shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6'
                          />
                          <ErrorMessage
                            name='confirm_password'
                            component='div'
                            className='text-red-500 text-sm'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3'>
                    <div>
                      <h2 className='text-base font-semibold leading-7 text-dark-text'>
                        Personal Information
                      </h2>
                    </div>

                    <div className='grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2'>
                      <div className='sm:col-span-3'>
                        <label
                          htmlFor='first-name'
                          className='block text-sm font-medium leading-6 text-dark-text'
                        >
                          First name
                        </label>
                        <div className='mt-2'>
                          <Field
                            type='text'
                            name='first_name'
                            id='first_name'
                            autoComplete='given-name'
                            validate={required}
                            className='block w-full rounded-md border-0 py-1.5 text-dark-text shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6'
                          />

                          <ErrorMessage
                            name='first_name'
                            component='div'
                            className='text-red-500 text-sm'
                          />
                        </div>
                      </div>

                      <div className='sm:col-span-3'>
                        <label
                          htmlFor='last-name'
                          className='block text-sm font-medium leading-6 text-dark-text'
                        >
                          Last name
                        </label>
                        <div className='mt-2'>
                          <Field
                            type='text'
                            name='last_name'
                            id='last_name'
                            autoComplete='family-name'
                            validate={required}
                            className='block w-full rounded-md border-0 py-1.5 text-dark-text shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6'
                          />
                          <ErrorMessage
                            name='last_name'
                            component='div'
                            className='text-red-500 text-sm'
                          />
                        </div>
                      </div>
                      <div className='sm:col-span-3'>
                        <label
                          htmlFor='country'
                          className='block text-sm font-medium leading-6 text-dark-text'
                        >
                          Country
                        </label>
                        <div className='mt-2'>
                          <Field
                            as='select'
                            id='country'
                            name='country'
                            placeholder='select country'
                            autoComplete='country-name'
                            validate={required}
                            className='block w-full rounded-md border-0 py-1.5 text-dark-text shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary sm:max-w-xs sm:text-sm sm:leading-6'
                          >
                            <option>Select an option</option>
                            <option>United States</option>
                            <option>Canada</option>
                            <option>Mexico</option>
                          </Field>
                          <ErrorMessage
                            name='country'
                            component='div'
                            className='text-red-500 text-sm'
                          />
                        </div>
                      </div>
                      <div className='sm:col-span-3'>
                        <label
                          htmlFor='country'
                          className='block text-sm font-medium leading-6 text-dark-text'
                        >
                          Gender
                        </label>
                        <div className='mt-2'>
                          <Field
                            as='select'
                            id='gender'
                            name='gender'
                            autoComplete='country-name'
                            validate={required}
                            className='block w-full rounded-md border-0 py-1.5 text-dark-text shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary sm:max-w-xs sm:text-sm sm:leading-6'
                          >
                            <option>Select an option</option>
                            <option>Male</option>
                            <option>Female</option>
                            <option>Non-binary</option>
                            <option>A Gender not listed here</option>
                            <option>Unsure</option>
                            <option>Prefer not to say</option>
                          </Field>
                          <ErrorMessage
                            name='gender'
                            component='div'
                            className='text-red-500 text-sm'
                          />
                        </div>
                      </div>
                      <div className='sm:col-span-3'>
                        <label
                          htmlFor='country'
                          className='block text-sm font-medium leading-6 text-dark-text'
                        >
                          Year of Birth
                        </label>
                        <div className='mt-2 '>
                          <LocalizationProvider
                            className='text-light-text'
                            dateAdapter={AdapterDayjs}
                          >
                            <DatePicker
                              id='year-picker'
                              className='text-dark-text bg-background'
                              views={["year"]}
                              value={date}
                              onChange={(date) => setDate(date)}
                              style={{ color: "white" }}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3'>
                    <div>
                      <h2 className='text-base font-semibold leading-7 text-dark-text'>
                        Select Subscription Plan
                      </h2>
                    </div>
                    <div className='absolute inset-y-full left-0 -z-10 w-full lg:w-1/2'>
                      <div
                        className='absolute left-0 top-3/4 -z-10 transform-gpu overflow-hidden blur-3xl sm:top-0'
                        aria-hidden='true'
                      >
                        <div
                          className='relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-primary-light to-primary opacity-30 sm:left-4 sm:w-[72.1875rem]'
                          style={{
                            clipPath:
                              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                          }}
                        />
                      </div>
                    </div>

                    <div className='grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2'>
                      <div className='mt-4 flex justify-center flex-col sm:flex-row col-span-full'>
                        <button
                          type='button'
                          onClick={() => setFrequency(frequencies[0])}
                          className={classNames(
                            frequency.label === "Annually"
                              ? "bg-primary text-light-text"
                              : "bg-transparent text-dark-text",
                            "p-1 px-2 rounded-2xl mr-2 my-1"
                          )}
                        >
                          Save 33%
                        </button>

                        <RadioGroup
                          value={frequency}
                          onChange={setFrequency}
                          className='grid grid-cols-2 gap-x-1 rounded-full p-2 text-center text-md font-semibold leading-5 ring-1 ring-inset ring-dark-text w-full sm:w-fit'
                        >
                          <h2 className='sr-only'>Payment frequency</h2>
                          {frequencies.map((option) => (
                            <RadioGroup.Option
                              key={option.value}
                              value={option}
                              className={({ checked }) =>
                                classNames(
                                  checked
                                    ? "bg-primary text-white"
                                    : "text-dark-text bg-transparent",
                                  "cursor-pointer rounded-full text-base px-2 py-1 flex items-center w-full sm:w-fit justify-center "
                                )
                              }
                            >
                              <span>{option.label}</span>
                            </RadioGroup.Option>
                          ))}
                        </RadioGroup>
                      </div>
                      <div className='sm:col-span-full'>
                        {" "}
                        {tiers.map((tier, tierIdx) => {
                          if (tier.label === frequency.label) {
                            return (
                              <div
                                key={tier.name}
                                className='mx-auto mt-4 max-w-2xl rounded-3xl ring-1 ring-dark-text lg:mx-0 lg:flex lg:max-w-none'
                              >
                                <div className='p-8 sm:p-10 lg:flex-auto'>
                                  <div className='flex flex-col sm:flex-row'>
                                    <div>
                                      <h3 className='text-2xl font-bold tracking-tight text-gray-900'>
                                        {tier.label}
                                      </h3>
                                      <p className='mt-6 pr-8 text-base leading-7 text-dark-text'>
                                        {tier.description}
                                      </p>
                                    </div>
                                    <div className='max-w-xs px-8 bg-slate-300 rounded-2xl flex justify-center items-center py-8 w-fit mx-auto'>
                                      <p className='flex my-auto gap-x-2'>
                                        <span className='text-5xl font-bold tracking-tight text-gray-900'>
                                          {tier.price}
                                        </span>
                                        <span className='text-sm font-semibold leading-6 tracking-wide text-gray-600'>
                                          {frequency.priceSuffix}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div className='mt-10 flex items-center gap-x-4'>
                                    <h4 className='flex-none text-sm font-semibold leading-6 text-primary'>
                                      What’s included
                                    </h4>
                                    <div className='h-px flex-auto bg-dark-text' />
                                  </div>
                                  <ul className='mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6'>
                                    {includedFeatures.map((feature) => (
                                      <li
                                        key={feature}
                                        className='flex gap-x-3'
                                      >
                                        <CheckIcon
                                          className='h-6 w-5 flex-none text-primary'
                                          aria-hidden='true'
                                        />
                                        {feature}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </div>

                  <div className='grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3'>
                    <div>
                      <h2 className='text-base font-semibold leading-7 text-dark-text'>
                        Notifications
                      </h2>
                      <p className='mt-1 text-sm leading-6 text-gray-600'>
                        We'll always let you know about important changes, but
                        you pick what else you want to hear about. By signing
                        up, you agree to Mental Health Connections Privacy
                        Policy. Your personal details are strictly for our use,
                        and you can unsubscribe at any time.
                      </p>
                    </div>

                    <div className='max-w-2xl space-y-10 md:col-span-2'>
                      <fieldset>
                        <div className='mt-6 space-y-6'>
                          <div className='relative flex gap-x-3'>
                            <div className='flex h-6 items-center'>
                              <Field
                                type='checkbox'
                                id='product_updates'
                                name='product_updates'
                                className='h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary'
                              />
                              <ErrorMessage
                                name='product_updates'
                                component='div'
                                className='text-red-500 text-sm'
                              />
                            </div>
                            <div className='text-sm leading-6'>
                              <label
                                htmlFor='comments'
                                className='font-medium text-dark-text'
                              >
                                Product Updates
                              </label>
                              <p className='text-gray-500'>
                                Receive notifications when we release new
                                features or updates.
                              </p>
                            </div>
                          </div>
                          <div className='relative flex gap-x-3'>
                            <div className='flex h-6 items-center'>
                              <Field
                                id='achievments_and_reminders'
                                name='achievments_and_reminders'
                                type='checkbox'
                                className='h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary'
                              />
                            </div>
                            <div className='text-sm leading-6'>
                              <label
                                htmlFor='candidates'
                                className='font-medium text-dark-text'
                              >
                                Receive notifications of reminders and
                                achievement notices
                              </label>
                              {/* <p className='text-gray-500'>
                                Say up to date with the latest research.
                              </p> */}
                            </div>
                          </div>
                          <div className='relative flex gap-x-3'>
                            <div className='flex h-6 items-center'>
                              <Field
                                id='external_resources_and_links'
                                name='external_resources_and_links'
                                type='checkbox'
                                className='h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary'
                              />
                            </div>
                            <div className='text-sm leading-6'>
                              <label
                                htmlFor='offers'
                                className='font-medium text-dark-text'
                              >
                                Updates to External Resources & Links
                              </label>
                              <p className='text-gray-500'>
                                Receive notifications when a new resource or
                                link is added.
                              </p>
                            </div>
                          </div>
                          <div className='relative flex gap-x-3'>
                            <div className='flex h-6 items-center'>
                              <Field
                                id='offers'
                                name='offers'
                                type='checkbox'
                                className='h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary'
                              />
                            </div>
                            <div className='text-sm leading-6'>
                              <label
                                htmlFor='offers'
                                className='font-medium text-dark-text'
                              >
                                Offers
                              </label>
                              <p className='text-gray-500'>
                                Receive notifications when we are having a
                                promotion.
                              </p>
                            </div>
                          </div>
                          <div className='relative flex gap-x-3'>
                            <div className='flex h-6 items-center'>
                              <Field
                                id='terms_and_conditions'
                                name='terms_and_conditions'
                                type='checkbox'
                                className='h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary'
                              />
                            </div>
                            <div className='text-sm leading-6'>
                              <label
                                htmlFor='offers'
                                className='font-medium text-dark-text'
                              >
                                <Link to='https://mhconnections.ca/terms'>
                                  <span className='text-blue-700 underline font-medium'>
                                    Terms and Conditions
                                  </span>{" "}
                                  I acknowledge that this product does not
                                  constitute acute mental health advice and that
                                  I should seek support from a mental health
                                  professional if I am experiencing an acute
                                  mental health crisis.
                                  <ErrorMessage
                                    name='terms_and_conditions'
                                    component='div'
                                    className='text-red-500 text-sm'
                                  />
                                </Link>
                              </label>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>

                <div className='mt-6 flex items-center justify-end gap-x-6'>
                  {/* make a temp link to the login page */}
                  {/* <button
                    type='submit'
                    onClick={() => handleSubmit()}
                    className='p-2 rounded-lg mt-2 bg-primary text-light-text hover:bg-primary-light hover:text-dark-text max-h-10'
                  >
                    create account
                  </button> */}
                  <button
                    type='submit'
                    className='p-2 rounded-lg mt-2  bg-primary text-light-text hover:bg-primary-light hover:text-dark-text max-h-10'
                  >
                    Next step
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </main>
      <FooterWebsite />
    </div>
  );
}
