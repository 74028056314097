import React, { useEffect, useState, Fragment } from "react";
import categories from "../../../Dashboard/Components/common/healthCircleDef";
import { BsChevronDown, BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { Formik, Form, Field, ErrorMessage } from "formik";
import AddItems from "../../../../Components/Item/AddItems";
import { Dialog, Transition } from "@headlessui/react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../../firebase-config";
import DeleteItem from "../../../../Components/Item/DeleteItem";
import { TrashIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";

export default function InsideFile(props) {
  const [categoryIndex, setCategoryIndex] = useState(props.categoryId);
  const [showExample, setShowExample] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const [open, setOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(props.currentItem);

  function changeForwardIndex(index) {
    if (index === categories.length - 1) {
      setCategoryIndex(0);
    } else {
      setCategoryIndex(index + 1);
    }
    setSearchItem("");
  }

  async function removeItem(item, sectionId) {
    DeleteItem(props.userId, item, sectionId);
    // get updated list
    const collectionRef = collection(db, "User/" + props.userId + "/Items");
    const snapshot = await getDocs(collectionRef);
    const dict = {};
    snapshot.docs.forEach((doc) => {
      dict[doc.id] = doc.data();
    });
    setCurrentItem(dict);
  }

  function changeBackwardsIndex(index) {
    if (index === 0) {
      setCategoryIndex(categories.length - 1);
    } else {
      setCategoryIndex(index - 1);
    }
    setSearchItem("");
  }

  const toggleVisibility = () => {
    setShowExample(!showExample);
  };

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full  justify-center p-4 text-center items-center sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left h-min shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6'>
                  <div>
                    <div className='mt-3 text-center sm:mt-5'>
                      <h1 className='pt-0 p-4 text-xl'>
                        Item added to Health Circle
                      </h1>
                      <button
                        id='close-button'
                        type='button'
                        className='inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:text-dark-text hover:bg-primary-light'
                        onClick={() => setOpen(false)}
                      >
                        close
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {categories.map((category, index) => {
        if (index === categoryIndex)
          return (
            <motion.div
              key={category.name}
              className='flex flex-row'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.6 }}
            >
              <div className='flex items-center'>
                <BsChevronLeft
                  className='min-w-10 h-auto w-10 md:w-20 m-2 text-primary cursor-pointer hover:text-dark-text hover:bg-primary-light rounded-lg'
                  onClick={() => changeBackwardsIndex(index)}
                />
              </div>
              <div className=''>
                <div key={category.id} className='flex justify-center mx-auto'>
                  <category.iconName className='h-14 w-14 my-auto text-dark-text' />
                  <h1 className='text-2xl m-4 text-center place-content-center font-bold text-dark-text'>
                    {category.name}
                  </h1>
                  <img
                    src={category.nonagonMap}
                    alt='nonagon'
                    className='hidden sm:flex mt-2 float-end'
                  />
                </div>
                <div key='carousel-text' className='flex flex-col mt-4 '>
                  {category.name === "Sleep / Recovery" ? (
                    <div>
                      <p>
                        <span className='font-semibold'>Sleep:</span>
                        {category.text}
                      </p>
                      <p>
                        <span className='font-semibold'>Recovery:</span>
                        {category.text2}
                      </p>
                    </div>
                  ) : (
                    <p>{category.text}</p>
                  )}
                  <button
                    onClick={toggleVisibility}
                    className='m-2 mb-10 text-primary font-semibold text-start flex'
                  >
                    Examples
                    <BsChevronDown className=' mt-1 ml-1' />
                  </button>
                  {showExample ? (
                    <div className='col-span-3'>
                      <p className='col-span-3 text-md -mt-10 mb-16 ml-4  text-start'>
                        {category.name === "Sleep / Recovery" ? (
                          <p>
                            {" "}
                            <span className='font-semibold'>Sleep</span>
                            {category.example.replace(/sleep/i, "")}
                          </p>
                        ) : (
                          <p>{category.example}</p>
                        )}
                      </p>
                    </div>
                  ) : null}
                  {showExample && category.name === "Sleep / Recovery" ? (
                    <div className='col-span-3'>
                      <p className='col-span-3 text-md -mt-10 mb-10 ml-4 text-start'>
                        <span className='font-semibold'>Recovery</span>
                        {category.example2.replace(/Recovery/i, "")}
                      </p>
                    </div>
                  ) : null}
                </div>

                <div key='items-div'>
                  <div key='add-items'>
                    <Formik
                      initialValues={{
                        item: "",
                        searchItems: "",
                      }}
                      validate={(values) => {
                        const errors = {};
                        if (!values.item && values.item.length > 0) {
                          errors.item = "Required";
                        } else if (
                          currentItem[
                            categories[categoryIndex].id
                          ].section_list.includes(values.item)
                        ) {
                          errors.item = "Item already added";
                        }
                        return errors;
                      }}
                      onSubmit={(value, { resetForm, setFieldError }) => {
                        setSearchItem("");
                        if (value.item.length === 0) {
                          return;
                        }

                        AddItems(props.userId, value.item, category.id);
                        currentItem[category.id].section_list.unshift(
                          value.item
                        );
                        setOpen(true);
                        resetForm({
                          values: {
                            item: "",
                            searchItems: "",
                          },
                        });
                      }}
                    >
                      {({ handleChange }) => (
                        <Form className=' mx-auto mb-10 border-2 border-dark-text grid sm:grid-cols-2 grid-cols-1 gap-4 bg-light-text rounded-md'>
                          <div className='flex flex-col sm:border-r-2 border-r-0 border-b-2 sm:border-b-0 pb-2 border-dark-text'>
                            <h1 className='m-2 mx-auto text-lg font-semibold'>
                              Add Item
                            </h1>
                            <Field
                              id={"add_item_" + category.id}
                              name='item'
                              type='text'
                              placeholder='Enter New Item'
                              className='rounded-2xl focus:ring-primary focus:border-primary mx-auto mt-4 w-3/4 bg-light-text'
                            />
                            <ErrorMessage
                              name='item'
                              component='div'
                              className='text-red-500 text-center mb-2'
                            />

                            <button
                              disabled={props.payment === false}
                              type='submit'
                              className='rounded-2xl mx-auto mt-4 bg-primary w-3/4 p-3 text-light-text hover:bg-primary-light hover:text-dark-text  text-center'
                            >
                              Add
                            </button>
                          </div>
                          <div
                            key='current-items'
                            className='flex flex-col justify-center'
                          >
                            <h1 className='m-2 mx-auto text-lg font-semibold text-center'>
                              Current Items
                            </h1>

                            <Field
                              type='text'
                              name='searchItems'
                              placeholder='Search Items'
                              className='rounded-2xl ml-6 w-3/4 focus:ring-primary focus:border-primary bg-light-text '
                              onChange={(e) => {
                                handleChange(e);
                                setSearchItem(e.target.value);
                              }}
                            />
                            {currentItem[category.id].section_list.length ===
                            0 ? (
                              <p className='text-center m-6'>No items added</p>
                            ) : (
                              <ul className='m-6 max-h-64 min-h-10 overflow-y-auto border-2 border-dark-text rounded-md divide-y divide-dark-text divide-solid '>
                                {currentItem[
                                  categories[categoryIndex].id
                                ].section_list
                                  .map((item, index) => ({ item, index }))
                                  .sort((a, b) => a.index - b.index)
                                  .map(({ item, index }) => {
                                    if (item.includes(searchItem)) {
                                      return (
                                        <li
                                          key={item + categoryIndex}
                                          className='ml-2 flex justify-between content-center items-center align-center'
                                        >
                                          <p>{item}</p>
                                          <button
                                            disabled={props.payment === false}
                                            className='font-semibold text-lg rounded-lg p-1 m-1 hover:bg-red-700 hover:text-light-text'
                                            onClick={() =>
                                              removeItem(item, category.id)
                                            }
                                          >
                                            <TrashIcon className='h-6 w-6' />
                                          </button>
                                        </li>
                                      );
                                    }
                                    return null; // Return null for items that don't meet the conditions
                                  })}
                              </ul>
                            )}
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
                <div key='index div'>
                  <div className=''>
                    <ul className='w-full flex justify-between flex-wrap'>
                      {categories.map((category, index) => {
                        if (index !== categoryIndex) {
                          return (
                            <li
                              key={category.id}
                              onClick={() => setCategoryIndex(index)}
                              className='text-md font-semibold m-1 cursor-pointer'
                            >
                              {category.name}
                            </li>
                          );
                        } else {
                          return (
                            <li
                              key={category.id}
                              onClick={() => setCategoryIndex(index)}
                              className='text-md font-semibold m-1 text-primary cursor-pointer'
                            >
                              {category.name}
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className='flex items-center'>
                <BsChevronRight
                  className='min-w-10 h-auto w-10 md:w-20 m-2 text-primary cursor-pointer hover:text-dark-text hover:bg-primary-light rounded-lg'
                  onClick={() => changeForwardIndex(index)}
                />
              </div>
            </motion.div>
          );
      })}
    </div>
  );
}
