import React, { useState, useEffect } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
  Elements,
  AddressElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  collection,
  getDocs,
  query,
  where,
  documentId,
} from "firebase/firestore";
import { db } from "../../../firebase-config";
import sideTextLogoWhite from "../../../Images/sideLogoWhite2.svg";
import { Formik, Form, Field } from "formik";
import { useLocation } from "react-router-dom";

const stripePromise = loadStripe(
  "pk_live_51NnpuDL0HRQUVolbup1M6ZL8dN3ZBQ8wdNBqwha6rikG5JUq9Dkb9lc5luhI4oKxowyRryGPpQTSfb0jp1sQYI2l001pRTItjn"
); // Replace with your Stripe Publishable Key

const SubscriptionForm = ({ clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);
  const [setMessage] = useState("");

  // Replace with your app URL
  const appUrl = "https://mental-health-connection.web.app/";

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      console.error("Stripe or Elements not loaded");
      return;
    }

    setLoading(true);

    const paymentElement = elements.getElement(PaymentElement);
    if (!paymentElement) {
      console.error("PaymentElement is not mounted.");
      setMessage("Payment Element not found.");
      setLoading(false);
      return;
    }

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: appUrl, // Your success URL
      },
    });

    if (error) {
      setMessage(error.message);
    } else {
      setMessage("Subscription successful!");
    }
    setLoading(false);
  };

  return (
    <div className='m-6'>
      {clientSecret ? (
        <form onSubmit={handleSubmit}>
          <h1 className='my-2'>Card information</h1>
          <PaymentElement />
          <h1 className='my-2'>Billing address</h1>
          <AddressElement options={{ mode: "billing" }} />

          <button
            type='submit'
            disabled={!stripe || loading}
            className='bg-primary hover:bg-primary-light hover:text-dark-text text-light-text font-bold py-2 px-4 rounded mt-6 w-full'
          >
            {loading ? "Loading..." : "Subscribe"}
          </button>
          <p className='mx-4 mt-4 text-sm'>
            By confirming your subscription, you allow Mental Health Connections
            to charge you for future payments in accordance with their terms.
            You can always cancel your subscription.
          </p>
        </form>
      ) : (
        <p>Loading payment form...</p>
      )}
    </div>
  );
};

const SubscriptionPage = () => {
  const location = useLocation();
  const { plan, user } = location.state || {};

  const [clientSecret, setClientSecret] = useState("");
  const [priceInfo, setPriceInfo] = useState(null);
  const [productInfo, setProductInfo] = useState(null);
  const [coupon, setCoupon] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [tax, setTax] = useState(0);
  const [total, setTotal] = useState(0);

  const backendUrl = "https://hidden-cliffs-04441-732a194e6c26.herokuapp.com/";
  //const backendUrl = "http://localhost:4242/";

  useEffect(() => {
    async function getStripeCustomerId(customerQuery) {
      try {
        // Wait for the documents to be fetched
        const customerSnapshot = await getDocs(customerQuery);

        // Check if there are any documents in the snapshot
        if (customerSnapshot.empty) {
          console.error("No matching documents found.");
          return null; // Handle this case as needed, or return an appropriate value
        }

        // Wait for the stripeId to be extracted
        const stripeCustomerId = customerSnapshot.docs[0].data().stripeId;
        console.log("Stripe ID:", stripeCustomerId);
        // Return the stripeCustomerId
        return stripeCustomerId;
      } catch (error) {
        console.error("Error retrieving stripeId:", error);
        return null; // Handle or return an appropriate value in case of an error
      }
    }

    async function getPlanInfo(planName) {
      const planQuery = query(
        collection(db, "products"),
        where("name", "==", planName)
      );
      const planSnapshot = await getDocs(planQuery);

      if (planSnapshot.empty) {
        console.error("No matching documents found.");
        return null; // Handle this case as needed, or return an appropriate value
      }
      const productInfo = planSnapshot.docs[0].data();
      // get the product ID
      const productId = planSnapshot.docs[0].id;

      // Get the price information
      const priceQuery = query(collection(db, "products", productId, "prices"));
      const priceSnapshot = await getDocs(priceQuery);
      // Get the price information
      const priceInfo = priceSnapshot.docs[0].data();
      const priceId = priceSnapshot.docs[0].id;
      console.log("Price ID:", priceId);

      return { productId, priceInfo, productInfo, priceId };
    }

    const fetchClientSecret = async () => {
      try {
        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

        console.log(user);
        // Fetch customer ID from your backend
        const customerQuery = query(
          collection(db, "customers"),
          where(documentId(), "==", user)
        );
        await delay(2000);
        //TODO: fix the time delay
        const stripeCustomerId = await getStripeCustomerId(customerQuery);
        const planInfo = await getPlanInfo(plan);
        setPriceInfo(planInfo.priceInfo);
        setProductInfo(planInfo.productInfo);

        console.log(
          "calling create subscription" + stripeCustomerId + planInfo.priceId
        );
        // Call backend to create a subscription and get the clientSecret
        const response = await fetch(backendUrl + "create-subscription", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            customerId: stripeCustomerId,
            priceId: planInfo.priceId, // Replace with the price ID from your Stripe Dashboard
          }),
        });
        const data = await response.json();
        setClientSecret(data.clientSecret);

        // Update customer information (if needed)
        // await fetch(backendUrl + "update-customer", {
        //   method: "POST",
        //   headers: { "Content-Type": "application/json" },
        //   body: JSON.stringify({
        //     customerId: stripeCustomerId,
        //     address: {
        //       line1: "123 Main Street",
        //       city: props.newUser.city,
        //       state: props.newUser.region,
        //       postal_code: props.newUser.postalCode,
        //       country: props.newUser.country,
        //     },
        //   }),
        // });
        setTax(((planInfo.priceInfo.unit_amount / 100) * 0.09).toFixed(2));
        setTotal(((planInfo.priceInfo.unit_amount / 100) * 1.09).toFixed(2));
      } catch (error) {
        console.error(
          "Error fetching client secret or updating customer:",
          error
        );
      }
    };

    fetchClientSecret();
  }, [user]);

  return (
    <div className='h-screen'>
      {/* title div */}

      <div className='h-full flex flex-col md:flex-row justify-between'>
        {productInfo ? (
          <div className='divide-y-2 divide-light-text/25 h-fit sm:h-full w-1/2 p-8 bg-primary text-light-text'>
            <img
              className='h-32 w-auto pt-4'
              src={sideTextLogoWhite}
              alt='logo'
            />

            <div className='mb-4 pt-4'>
              <h2>Subscribe to {productInfo.name}</h2>
              <div className='flex flex-row my-2 align-middle'>
                <h1 className='text-light-text text-4xl'>14 days free</h1>
              </div>
              <div className='flex flex-row'>
                <h1 className=''>
                  Then CA${(priceInfo.unit_amount / 100).toFixed(2)} per{" "}
                  {priceInfo.interval}
                </h1>
              </div>
            </div>
            <div className='mb-4 pt-4'>
              <div className='flex justify-between'>
                <h2>{productInfo.name}</h2>
                <h2>14 days free</h2>
              </div>
              <p className='m-4'>{productInfo.description}</p>
              <h2>
                CA${(priceInfo.unit_amount / 100).toFixed(2)}/{" "}
                {priceInfo.interval} after
              </h2>
            </div>
            <div className='mb-4 pt-4'>
              <div className='flex flex-row justify-between mb-4'>
                <h2>Subtotal</h2>
                <p>CA${(priceInfo.unit_amount / 100).toFixed(2)}</p>
              </div>

              {coupon ? (
                <div>
                  <Formik>
                    <Form
                      onSubmit={() => {
                        // handle coupon application
                      }}
                    >
                      <Field
                        type='text'
                        placeholder='Enter your code'
                        className='border-2 border-dark-text text-dark-text rounded-lg p-2 m-2'
                      />
                      <button
                        type='submit'
                        className='bg-black text-white p-2 rounded-lg'
                      >
                        Apply
                      </button>
                    </Form>
                  </Formik>
                </div>
              ) : (
                <button
                  className='bg-black text-white p-2 rounded-lg'
                  onClick={() => setCoupon(true)}
                >
                  Add promotion code
                </button>
              )}
            </div>
            <div className='mb-4 pt-4'>
              <div className='flex flex-row justify-between'>
                <h2>Tax</h2>
                <p>{tax}</p>
              </div>
            </div>
            <div className='mb-4 pt-4'>
              <div className='flex flex-row justify-between'>
                <h2>Total after trial</h2>
                <p>{total}</p>
              </div>
              <div className='font-semibold flex flex-row justify-between'>
                <h2>Total due today</h2>
                <p>CA$0.00</p>
              </div>
            </div>
          </div>
        ) : null}
        <div className='w-1/2'>
          {clientSecret ? (
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <SubscriptionForm clientSecret={clientSecret} />
            </Elements>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPage;
