//import { GoogleAuthProvider } from "firebase/auth";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { object, string } from "yup";
import MentalHealthConnectionsLogoGreen from "../../Images/sideLogoBlack2.svg";
import mountain from "../../Images/mountain1.jpg";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../../firebase-config";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
  setDoc,
  onSnapshot,
  limit,
  Timestamp,
} from "firebase/firestore";
import CheckStripePayment from "../../Components/User/GetStripeStatus";
import ResetPasswordPopup from "./Components/ResetPasswordPopup";

const DEFAULT_COLLECTIONS = {
  stats: {
    sections: {
      healthy_nutrition_snacks: 0,
      hobbies: 0,
      laughter_makes_you_smile: 0,
      movement_activity: 0,
      outdoors_nature: 0,
      planning_looking_forward_to: 0,
      self_care: 0,
      sleep_recovery: 0,
      social_interaction: 0,
    },
    total_completed: 0,
    monthly_completed: 0,
  },
  calendar: {
    events: [],
    reminders: [
      { active: false, name: "daily", reminderTime: Timestamp.now() },
      { active: false, name: "weekly", reminderTime: Timestamp.now() },
    ],
  },
};

export default function LoginPage() {
  const navigation = useNavigate();
  const [emailError, setEmailError] = useState("");
  const [resetPassword, setResetPassword] = useState(false);

  const initializeUserCollections = async (userID) => {
    const collections = {
      Connections: { connectionList: [] },
      Person: { personList: [] },
      Calendar: DEFAULT_COLLECTIONS.calendar,
      Stats: DEFAULT_COLLECTIONS.stats,
    };

    const items = [
      "healthy_nutrition_snacks",
      "hobbies",
      "laughter_makes_you_smile",
      "movement_activity",
      "outdoors_nature",
      "planning_looking_forward_to",
      "self_care",
      "sleep_recovery",
      "social_interaction",
    ];

    for (const [collectionName, data] of Object.entries(collections)) {
      const collectionRef = collection(db, `User/${userID}/${collectionName}`);
      const documents = await getDocs(collectionRef, limit(1));

      if (documents.size === 0) {
        await setDoc(doc(collectionRef, collectionName), data);
      }
    }

    const itemsCollection = collection(db, `User/${userID}/Items`);
    const itemDocs = await getDocs(itemsCollection, limit(1));

    if (itemDocs.size === 0) {
      for (const item of items) {
        await setDoc(doc(itemsCollection, item), { section_list: [] });
      }
    }
  };
  const handleSubmit = async (values) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );

      const user = userCredential.user;
      const collectionRef = collection(db, "User");
      const q = query(collectionRef, where("uuid", "==", user.uid));

      onSnapshot(q, async (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          initializeUserCollections(doc.id);
        });
      });

      await CheckStripePayment(values.email);
      navigation("/Dashboard", { section: "" });
    } catch (error) {
      setEmailError("Invalid email or password");
    }
  };

  // async function getOverallMentalHealth(userDocID) {
  //   if (userDocID) {
  //     const userID = userDocID;
  //     // get the user information from the database
  //     const docRef = doc(db, "User", userID);
  //     const docSnap = await getDoc(docRef);
  //     if (docSnap.exists()) {
  //       // check for collections in the user document
  //       // if there are no collections, then we need to create them
  //       // if there are collections, then we need to update them
  //       const connectionsCollection = collection(
  //         db,
  //         "User/" + userID + "/Connections"
  //       );
  //       const connections = await getDocs(connectionsCollection, limit(1));
  //       if (connections.size === 0) {
  //         // create the connections collection
  //         const connectionsCollection = collection(
  //           db,
  //           "User/" + userID + "/Connections"
  //         );
  //         // create the connections document
  //         const connectionsDoc = doc(connectionsCollection, "Connections");
  //         // set the connections document
  //         await setDoc(connectionsDoc, { connectionList: [] });
  //       }

  //       const personCollection = collection(db, "User/" + userID + "/Person");
  //       const people = await getDocs(personCollection, limit(1));
  //       if (people.size === 0) {
  //         console.log("Persons do not exist");
  //         // create the Person collection

  //         // create the Person document
  //         const personDoc = doc(personCollection, "Person");
  //         // set the person document
  //         await setDoc(personDoc, { personList: [] });
  //       }

  //       const calendarCollection = collection(
  //         db,
  //         "User/" + userID + "/Calendar"
  //       );
  //       const calendars = await getDocs(calendarCollection, limit(1));
  //       if (calendars.size === 0) {
  //         console.log("Calendar does not exist");

  //         // create the calendar document
  //         const calendarDoc = doc(calendarCollection, "Calendar");
  //         // set the calendar document
  //         await setDoc(calendarDoc, {
  //           events: [],
  //           reminders: [
  //             { active: false, name: "daily", reminderTime: Timestamp.now() },
  //             {
  //               active: false,
  //               name: "weekly",
  //               reminderTime: Timestamp.now(),
  //             },
  //           ],
  //         });
  //       }

  //       const itemsCollection = collection(db, "User/" + userID + "/Items");
  //       const items = await getDocs(itemsCollection, limit(1));
  //       if (items.size === 0) {
  //         // create the Items collection

  //         // create the Items document
  //         const healthy_nutrition_snacks = doc(
  //           itemsCollection,
  //           "healthy_nutrition_snacks"
  //         );
  //         const hobbies = doc(itemsCollection, "hobbies");
  //         const laughter_makes_you_smile = doc(
  //           itemsCollection,
  //           "laughter_makes_you_smile"
  //         );
  //         const movement_activity = doc(itemsCollection, "movement_activity");
  //         const outdoors_nature = doc(itemsCollection, "outdoors_nature");
  //         const planning_looking_forward_to = doc(
  //           itemsCollection,
  //           "planning_looking_forward_to"
  //         );
  //         const self_care = doc(itemsCollection, "self_care");
  //         const sleep_recovery = doc(itemsCollection, "sleep_recovery");
  //         const social_interaction = doc(itemsCollection, "social_interaction");
  //         // set the Items document
  //         await setDoc(healthy_nutrition_snacks, { section_list: [] });
  //         await setDoc(hobbies, { section_list: [] });
  //         await setDoc(laughter_makes_you_smile, { section_list: [] });
  //         await setDoc(movement_activity, { section_list: [] });
  //         await setDoc(outdoors_nature, { section_list: [] });
  //         await setDoc(planning_looking_forward_to, { section_list: [] });
  //         await setDoc(self_care, { section_list: [] });
  //         await setDoc(sleep_recovery, { section_list: [] });
  //         await setDoc(social_interaction, { section_list: [] });
  //       }

  //       const assessmentsCollection = collection(
  //         db,
  //         "User/" + userID + "/Assessments"
  //       );
  //       if (assessmentsCollection) {
  //         console.log("Assessments exist");
  //       } else {
  //         console.log("Assessments do not exist");
  //       }

  //       // create the stats collections if it does not already exist
  //       const statsCollection = collection(db, "User/" + userID + "/Stats");
  //       const stats = await getDocs(statsCollection, limit(1));
  //       if (stats.size === 0) {
  //         // create the Assessments collection
  //         const statsDoc = doc(statsCollection, "Stats");
  //         const sections = {
  //           healthy_nutrition_snacks: 0,
  //           hobbies: 0,
  //           laughter_makes_you_smile: 0,
  //           movement_activity: 0,
  //           outdoors_nature: 0,
  //           planning_looking_forward_to: 0,
  //           self_care: 0,
  //           sleep_recovery: 0,
  //           social_interaction: 0,
  //         };
  //         const total_completed = 0;
  //         const monthly_completed = 0;

  //         // create the Stats document
  //         await setDoc(statsDoc, {
  //           sections,
  //           total_completed,
  //           monthly_completed,
  //         });
  //       }
  //     }
  //   }
  // }

  return (
    <>
      <ResetPasswordPopup
        open={resetPassword}
        onClose={() => setResetPassword(false)}
      />
      <div className='flex min-h-full flex-1'>
        <div className='flex flex-1 bg-background flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24'>
          <div className='mx-auto w-full max-w-sm lg:w-96'>
            <div>
              <div>
                <Link to='https://mhc-landing-page--test-landing-page-4e4dkttc.web.app/'>
                  <img
                    alt='Mental Health Connections Logo'
                    src={MentalHealthConnectionsLogoGreen}
                    className='h-32 w-auto text-primary'
                  />
                </Link>
              </div>
              <h2 className='mt-8 text-2xl font-bold leading-9 tracking-tight text-dark-text'>
                Sign in to your account
              </h2>
              <p className='mt-2 text-sm leading-6 text-dark-text'>
                Not a member?{" "}
                <Link
                  to='/CreateAccount'
                  state={{ plan: "" }}
                  className='font-semibold text-primary '
                >
                  Start a 14 day free trial
                </Link>
              </p>
            </div>

            <div className='mt-10'>
              <div>
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={object({
                    email: string()
                      .email("Invalid email address")
                      .required("Required"),
                    password: string().required("Required"),
                  })}
                  onSubmit={handleSubmit}
                >
                  <Form action='#' method='POST' className='space-y-6'>
                    <div>
                      <label
                        htmlFor='email'
                        className='block text-md font-medium leading-6 text-dark-text'
                      >
                        Email address
                      </label>
                      <div className='mt-2'>
                        <Field
                          id='email'
                          name='email'
                          type='email'
                          autoComplete='email'
                          className='block w-full text-dark-text bg-background rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-light-text focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6'
                        />
                      </div>
                      {emailError !== "" ? (
                        <p className='text-red-500 text-sm'>{emailError}</p>
                      ) : null}
                      <ErrorMessage
                        name='email'
                        component='div'
                        className='text-red-500 text-sm'
                      />
                    </div>

                    <div>
                      <label
                        htmlFor='password'
                        className='block text-md font-medium leading-6 text-dark-text'
                      >
                        Password
                      </label>
                      <div className='mt-2'>
                        <Field
                          id='password'
                          name='password'
                          type='password'
                          autoComplete='current-password'
                          className='block w-full text-dark-text bg-background rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-light-text focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6'
                        />
                      </div>
                      <ErrorMessage
                        name='password'
                        component='div'
                        className='text-red-500 text-sm'
                      />
                    </div>

                    <div className='flex items-center justify-between'>
                      {/* <div className='flex items-center'>
                        <input
                          id='remember-me'
                          name='remember-me'
                          type='checkbox'
                          className='h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary-light'
                        />
                        <label
                          htmlFor='remember-me'
                          className='ml-3 block text-sm leading-6 text-dark-text'
                        >
                          Remember me
                        </label>
                      </div> */}

                      <div className='text-sm leading-6'>
                        <button
                          href='#'
                          type='button'
                          onClick={() => setResetPassword(true)}
                          className='font-semibold text-primary'
                        >
                          Forgot password?
                        </button>
                      </div>
                    </div>

                    <div>
                      {/* <Link to="/Dashboard"> */}
                      <button
                        type='submit'
                        id='loginButton'
                        className='flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-background shadow-sm hover:bg-primary-light hover:text-dark-text focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                      >
                        Sign in
                      </button>
                      {/* </Link> */}
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <div className='relative hidden w-0 flex-1 lg:block'>
          <img
            className='absolute inset-0 h-full w-full object-cover'
            src={mountain}
            alt=''
          />
        </div>
      </div>
    </>
  );
}
