import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import MonthData from "../../../../Components/UserData/MonthData";
import YearData from "../../../../Components/UserData/YearData";
import PropTypes from "prop-types";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export default function RadarChart(props) {
  RadarChart.propTypes = {
    userID: PropTypes.string,
    timePeriod: PropTypes.string,
    selectedMonth: PropTypes.string,
  };

  const options = {
    plugins: {
      legend: {
        display: false, // Disable the default legend
      },
    },
    scales: {
      r: {
        beginAtZero: true,
        min: 0,
        max: 10,
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  const [radarData, setRadarData] = useState(null);
  const [chartOptions, setChartOptions] = useState(options);

  useEffect(() => {
    async function fetchData() {
      let data = null;
      if (props.timePeriod === "month") {
        data = await MonthData(props.userID, "RadarChart", props.selectedMonth);
      }
      if (props.timePeriod === "year") {
        data = await YearData(props.userID, "RadarChart");
      }
      setRadarData(data);
    }
    fetchData();
  }, [props.userID, props.timePeriod, props.selectedMonth]);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        setChartOptions({
          ...options,
          plugins: {
            ...options.plugins,
            legend: {
              display: false,
              position: "top",
            },
          },
        });
      } else {
        setChartOptions(options);
      }
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Custom plugin for checkboxes in the legend
  const legendWithCheckboxes = {
    id: "legendWithCheckboxes",
    afterDraw(chart) {
      const legendContainer = document.getElementById("legend-container");
      const isSmallScreen = window.innerWidth < 768;

      legendContainer.style.display = "flex";
      legendContainer.style.flexDirection = isSmallScreen ? "column" : "row";
      legendContainer.style.justifyContent = "space-around";
      legendContainer.style.flexWrap = "wrap";
      legendContainer.style.backgroundColor = "#ccc";
      legendContainer.style.padding = "8px";
      legendContainer.style.borderRadius = "8px";
      legendContainer.style.boxShadow = "0 2px 4px rgba(0, 0, 0, 0.2)";

      if (!legendContainer || legendContainer.hasChildNodes()) return;

      chart.legend.legendItems.forEach((legendItem, index) => {
        const checkbox = document.createElement("input");
        checkbox.type = "checkbox";
        checkbox.checked = !chart.data.datasets[index].hidden;
        checkbox.style.marginRight = "8px";
        checkbox.style.cursor = "pointer";
        checkbox.style.borderRadius = "25%";

        checkbox.addEventListener("change", () => {
          chart.data.datasets[index].hidden = !checkbox.checked;
          chart.update();
        });

        const label = document.createElement("label");
        label.style.display = "flex";
        label.style.alignItems = "center";
        label.style.marginBottom = "4px";
        label.style.cursor = "pointer";

        // Use dynamically calculated isBigScreen
        label.style.width = "50%";

        const colorBox = document.createElement("span");
        colorBox.style.backgroundColor = legendItem.fillStyle;
        colorBox.style.width = "12px";
        colorBox.style.height = "12px";
        colorBox.style.borderRadius = "50%";
        colorBox.style.border = `5px solid ${legendItem.strokeStyle}`;
        colorBox.style.display = "inline-block";
        colorBox.style.marginRight = "8px";

        label.appendChild(checkbox);
        label.appendChild(colorBox);
        label.appendChild(document.createTextNode(legendItem.text));
        legendContainer.appendChild(label);
      });
    },
  };

  return (
    <div className='w-full'>
      <div id='legend-container' style={{ marginBottom: "16px" }}></div>
      {(radarData && (
        <Radar
          options={chartOptions}
          data={radarData}
          plugins={[legendWithCheckboxes]}
        />
      )) || <h1>no available data</h1>}
    </div>
  );
}
