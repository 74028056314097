import React from "react";
import add_to_calendar from "../../../Images/thumbnails/add mental health connections to your calendar.png";
import populate_health_circles from "../../../Images/thumbnails/populate the health circles.png";
import weekly_self_assessment from "../../../Images/thumbnails/Weeky self-assessment of your mental health.png";
import create_and_update from "../../../Images/thumbnails/thumbnail_Thumbnail Create MHCs.png";
import ReactPlayer from "react-player";
import { motion } from "framer-motion";

const guideSection = [
  {
    title: "Step 1: Weekly Mental Health self-assessment",
    poster: weekly_self_assessment,
    url: "https://vimeo.com/970933451",
    text: "This is the first step in your MHC journey. This assessment is based on the past seven days. First assess your overall mental health over the passed seven days. Then indicate how intentional you were at incorporating each of the 9 health circles. We do this on a scale of 1-10 Where 1 being the lowest and 10 being the highest. After this you will get your first opportunity to add Items and create connections. For more information on this look at step 2 and 3. This assessment will serve as a weekly check in for your mental health and will help you to identify areas to look into and areas that you are doing well.",
  },
  // Mental health is a state of mental well-being that enables people to cope with the stresses of life, realize their abilities, learn well and work well, and contribute to their community (World Health Organization).
  {
    title: "Step 2: Add Items to your Health Circles",
    poster: populate_health_circles,
    url: "https://vimeo.com/970933539",
    text: "In this step you will add items to any of the mental health circles. These items are activities that you do or are planning to to. They should be classified by the definitions given for each mental health circle. Go to the definitions page for more information on the classifications. An example of an item is: 'going to the beach' is Outdoor / Nature.",
  },
  {
    title: "Step 3: Create Mental Health Connections",
    poster: create_and_update,
    url: "https://vimeo.com/970933618",
    text: "Based on your reflections in the previous step, you will create mental health connections. A connection is a list of items form your mental health circles. This will represent an action planed for you mental health. An example of a connection is: 'coffee at Starbucks, with John' planned for friday. This connections would be combining the social interaction, healthy nutrition circles, and planning / looking forward to.",
  },
  {
    title: "Step 4: Manage Mental Health Connections",
    poster: add_to_calendar,
    url: "https://vimeo.com/970933693",
    text: "In this step you will manage your mental health connections. You will be able to see all your connections, and update them as needed. You will also be able to create future events. You will also be able to complete the connections, and see your progress over time by checking the 'My Data' page.",
  },
];

export default function GuidePage() {
  return (
    <motion.div
      className='relative isolate mt-4 '
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <main className='relative isolate mx-2 sm:mx-2 lg:mx-10 px-2 sm:px-4  lg:overflow-visible -z-10'>
        <h1 className='text-3xl font-semibold tracking-tight text-dark-text sm:text-5xl'>
          {" "}
          Video Tutorials
        </h1>
        <div
          className='absolute left-0 top-0 -z-20 transform-gpu overflow-hidden blur-3xl sm:top-0'
          aria-hidden='true'
        >
          <div
            className='relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-primary-light to-primary opacity-30 sm:left-4 sm:w-[72.1875rem]'
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div
          className='absolute left-0 bottom-0 -z-20 transform-gpu overflow-hidden blur-3xl sm:bottom-0'
          aria-hidden='true'
        >
          <div
            className='aspect-[1097/845] w-[68.5625rem] bg-gradient-to-r from-primary-light to-primary opacity-25'
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>

        <div className='list-none'>
          {guideSection.map((item) => (
            <li
              key={item.title}
              className='gap-y-2 bg-light-text border-dark-text border-2 rounded-md p-1 m-1 mb-4 sm:p-5 sm:m-5 '
            >
              <h2 className='text-xl xl:text-2xl 2xl:text-3xl p-4 sm:p-0 font-semibold leading-6 text-dark-text'>
                {item.title}
              </h2>
              <div className='grid grid-cols-1 xl:grid-cols-2 align-middle'>
                <div className='flex gap-x-4'>
                  <div className='min-w-0 flex-auto'>
                    <p className='text-md xl:text-lg max-w-6xl p-6 leading-6 text-dark-text'>
                      {item.text}
                    </p>
                  </div>
                </div>
                <div className='flex justify-center items-center w-full h-80'>
                  <div className='w-full h-full gap-x-1.5 z-20'>
                    <ReactPlayer
                      url={item.url}
                      controls
                      width='100%'
                      height='100%'
                      className='min-h-80'
                    />
                  </div>
                </div>
              </div>
            </li>
          ))}
        </div>
        <h1 className='text-3xl font-semibold text-gray-800'>Shortcuts</h1>

        <div className='grid sm:grid-cols-1 md:grid-cols-2 gap-4'>
          <div className='bg-light-text border-dark-text border-2 rounded-md p-1 m-1 mb-4 sm:p-5 sm:m-5'>
            <h2 className='text-xl xl:text-2xl 2xl:text-3xl p-4 sm:p-0 font-semibold leading-6 text-dark-text'>
              ISO shortcut
            </h2>
            <ul className='m-4'>
              <li>1: Go the the Mental Health Connections login page</li>
              <li>2: Select the '' icon</li>
              <li>3: Scroll down to 'Add to Home Screen'</li>
              <li>4: Tap 'Add' in the top left corner</li>
            </ul>
            <h3>
              Congratulations - you have now created a Shortcut for Mental
              Health Connections!!
            </h3>
          </div>
          <div className='bg-light-text border-dark-text border-2 rounded-md p-1 m-1 mb-4 sm:p-5 sm:m-5'>
            <h2 className='text-xl xl:text-2xl 2xl:text-3xl p-4 sm:p-0 font-semibold leading-6 text-dark-text'>
              Android shortcut
            </h2>
            <ul className='m-4'>
              <li>1: Go the the Mental Health Connections login page</li>
              <li>2: Select the '' icon</li>
              <li>3: Scroll down to 'Add to Home Screen'</li>
              <li>
                4: Then in the popup tap 'Add' and then in the next popup tap
                'Add" again
              </li>
            </ul>
            <h3>
              Congratulations - you have now created a Shortcut for Mental
              Health Connections!!
            </h3>
          </div>
        </div>
      </main>
    </motion.div>
  );
}
