import React from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import Monthdata from "../../../../Components/UserData/Monthdata";
import Yeardata from "../../../../Components/UserData/Yeardata";
import { useEffect, useState } from "react";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export default function RadarChart(props) {
  console.log(props.timePeriod);

  // const data = {
  //   labels: [
  //     "Janurary",
  //     "February",
  //     "March",
  //     "April",
  //     "May",
  //     "June",
  //     "July",
  //     "August",
  //     "September",
  //     "October",
  //     "November",
  //     "December",
  //   ],
  //   datasets: [
  //     {
  //       label: "Self-Care",

  //       data: [9, 4, 8, 8, 7, 3, 4, 6, 3, 7, 4, 9],
  //       backgroundColor: "rgba(75,192,192,0.0)",
  //       borderColor: "rgba(75,192,192,1)",
  //       pointBackgroundColor: "rgba(75,192,192,1)",
  //       borderWidth: 3,
  //     },
  //     {
  //       label: "Movement / Activity",
  //       data: [9, 4, 4, 2, 7, 3, 4, 6, 3, 7, 4, 9],
  //       backgroundColor: "rgba(255,255,71,0.0)",
  //       borderColor: "rgba(255,255,71,1)",
  //       pointBackgroundColor: "rgba(255,255,71,1)",
  //       borderWidth: 3,
  //     },
  //     {
  //       label: "Sleep / Recovery",
  //       data: [9, 4, 8, 8, 7, 3, 4, 1, 5, 3, 6, 9],
  //       backgroundColor: "rgba(0,100,71,0.0)",
  //       borderColor: "rgba(0,100,71,1)",
  //       pointBackgroundColor: "rgba(0,100,71,1)",
  //       borderWidth: 3,
  //     },
  //     {
  //       label: "Social Interaction",
  //       data: [2, 4, 2, 2, 7, 3, 4, 6, 3, 7, 4, 2],
  //       backgroundColor: "rgba(105,80,152,0.0)",
  //       borderColor: "rgba(255,99,132,1)",
  //       pointBackgroundColor: "rgba(255,99,132,1)",
  //       borderWidth: 3,
  //     },
  //     {
  //       label: "Healthy Nutrition & Snacks",
  //       data: [9, 4, 8, 8, 7, 3, 4, 6, 3, 7, 4, 9],
  //       backgroundColor: "rgba(179,3,43,0.0)",
  //       borderColor: "rgba(179,3,43,1)",
  //       pointBackgroundColor: "rgba(179,3,43,1)",
  //       borderWidth: 3,
  //     },
  //     {
  //       label: "Hobbies",
  //       data: [8, 5, 6, 5, 4, 3, 2, 2, 2, 2, 3, 9],
  //       backgroundColor: "rgba(98,57,255,0.0)",
  //       borderColor: "rgba(98,57,255,1)",
  //       pointBackgroundColor: "rgba(98,57,255,1)",
  //       borderWidth: 3,
  //     },
  //     {
  //       label: "Outdoor & Nature",
  //       data: [9, 4, 8, 8, 7, 3, 4, 6, 3, 7, 4, 9],
  //       backgroundColor: "rgba(33,249,157,0.0)",
  //       borderColor: "rgba(33,249,157,1)",
  //       pointBackgroundColor: "rgba(33,249,157,1)",
  //       borderWidth: 3,
  //     },
  //     {
  //       label: "Planning / Looking Forward To",
  //       data: [10, 5, 2, 3, 8, 7, 2, 8, 6, 1, 6, 1],
  //       backgroundColor: "rgba(169,106,126,0.0)",
  //       borderColor: "rgba(169,106,126,1)",
  //       pointBackgroundColor: "rgba(169,106,126,1)",
  //       borderWidth: 3,
  //     },
  //     {
  //       label: "Laughter / Makes you Smile",
  //       data: [3, 5, 8, 6, 1, 5, 1, 4, 2, 2, 3, 7],
  //       backgroundColor: "rgba(251,3,254,0.0)",
  //       borderColor: "rgba(251,3,254,1)",
  //       pointBackgroundColor: "rgba(251,3,254,1)",
  //       borderWidth: 3,
  //     },
  //   ],
  // };

  const weekData = {
    labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
    datasets: [
      {
        label: "Self-Care",

        data: [9, 4, 8, 8],
        backgroundColor: "rgba(75,192,192,0.0)",
        borderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "rgba(75,192,192,1)",
        borderWidth: 3,
      },
      {
        label: "Movement / Activity",
        data: [9, 6, 8, 8],
        backgroundColor: "rgba(255,255,71,0.0)",
        borderColor: "rgba(255,255,71,1)",
        pointBackgroundColor: "rgba(255,255,71,1)",
        borderWidth: 3,
      },
      {
        label: "Sleep / Recovery",
        data: [9, 9, 8, 8],
        backgroundColor: "rgba(0,100,71,0.0)",
        borderColor: "rgba(0,100,71,1)",
        pointBackgroundColor: "rgba(0,100,71,1)",
        borderWidth: 3,
      },
      {
        label: "Social Interaction",
        data: [9, 2, 5, 8],
        backgroundColor: "rgba(105,80,152,0.0)",
        borderColor: "rgba(255,99,132,1)",
        pointBackgroundColor: "rgba(255,99,132,1)",
        borderWidth: 3,
      },
      {
        label: "Healthy Nutrition & Snacks",
        data: [9, 5, 8, 9],
        backgroundColor: "rgba(98,57,255,0.0)",
        borderColor: "rgba(98,57,255,1)",
        pointBackgroundColor: "rgba(98,57,255,1)",
        borderWidth: 3,
      },
    ],
  };

  const options = {
    scales: {
      r: {
        beginAtZero: true,
        min: 0,
        max: 10,
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  const [radarData, setRadarData] = useState(null);
  useEffect(() => {
    async function fetchData() {
      var data = null;
      if (props.timePeriod === "month") {
        data = await Monthdata(props.userID, "RadarChart");
      }
      if (props.timePeriod === "year") {
        data = await Yeardata(props.userID, "RadarChart");
      }

      setRadarData(data);
    }
    fetchData();
  }, [props.userID, props.timePeriod]);

  return <div>{radarData && <Radar options={options} data={radarData} />}</div>;
}
