import { db } from "../../firebase-config";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

export default async function AddItems(userId, itemName, sectionName) {
  // console.log(
  //   "AddItems.jsx: userId: ",
  //   userId + " itemName: ",
  //   itemName + " sectionName: ",
  //   sectionName
  // );

  // add item to the database
  const itemRef = doc(db, "User/" + userId + "/Items", sectionName);
  // add item to the section
  const itemSnap = await getDoc(itemRef);
  if (itemSnap.exists()) {
    let itemInfo = itemSnap.data().section_list;
    // check if the item already exists
    // if it does, update it
    let itemExists = false;
    for (let item of itemInfo) {
      if (item.name === itemName) {
        itemExists = true;
        // update the item with the new info

        break;
      }
    }
    // if it doesn't, add it
    if (!itemExists) {
      itemInfo.push(itemName);
    }
    // update the section with the new info
    await updateDoc(itemRef, {
      section_list: itemInfo,
    });
    return itemInfo;
  } else {
    // if the section doesn't exist, create it
    await setDoc(itemRef, {
      section_list: [itemName],
    });
    return [itemName];
  }
}
