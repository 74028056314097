import getConnectionList from "./getConnectionList";
import GetCurrentUser from "../User/GetCurrentUser";

export default async function CheckConnection(connectionName, connectionList) {
  let formattedName = "";
  // format the connection name
  for (const char of connectionName) {
    formattedName = formattedName + char + ", ";
  }
  // remove the last comma
  formattedName = formattedName.slice(0, -2);
  connectionName = formattedName;

  if (connectionName.length === 0) {
    return true;
  }
  if (connectionList === "" || connectionList === undefined) {
    const user = await GetCurrentUser();
    connectionList = await getConnectionList(user[0].id);
  }
  let connectionFound = false;
  for (const connection of connectionList) {
    if (connection.name.toLowerCase() === connectionName.toLowerCase()) {
      connectionFound = true;
      break;
    }
  }

  return connectionFound;
}
