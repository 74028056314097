import React from "react";
import { db } from "../../firebase-config";
import {
  collection,
  query,
  where,
  deleteDoc,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";

export default async function DeleteEvent(eventInfo) {
  console.log(eventInfo);
  const auth = getAuth();
  const user = auth.currentUser.uid;
  const userRef = collection(db, "User");
  const userQuery = query(userRef, where("uuid", "==", user));
  const userSnapshot = await getDocs(userQuery);
  console.log(userSnapshot.docs[0].id);
  console.log(eventInfo);

  const collectionRef = collection(
    db,
    `User/${userSnapshot.docs[0].id}/Calendar`
  );
  const collectionSnapshot = await getDocs(collectionRef);
  // show all the documents in the collection
  console.log(collectionSnapshot.docs.map((doc) => doc.data()));
  let eventsList = collectionSnapshot.docs.map((doc) => doc.data());
  eventsList = eventsList[0].events;
  console.log(eventsList);

  // find the event in the list and delete it
  const index = eventsList.findIndex(
    (event) => event.title === eventInfo.title
  );
  console.log(index);
  eventsList.splice(index, 1);
  console.log(eventsList);
  // update the backend with the new list
  await updateDoc(
    doc(db, `User/${userSnapshot.docs[0].id}/Calendar`, "Calendar"),
    {
      events: eventsList,
    }
  );
  return eventsList;

  // delete the event from the list
  // return the list of events
}
