import React, { useEffect } from "react";
import "./App.css";
import LoginPage from "./Pages/LoginPage/LoginPage";

function App() {
  useEffect(() => {
    document.title = "Mental Health Connections"; // Set the new tab name here
  }, []);

  return (
    <div className='App h-screen'>
      <LoginPage />
    </div>
  );
}

export default App;
