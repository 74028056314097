import React, { useEffect, Fragment, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase-config";
import { useNavigate, useLocation } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";

import {
  CalendarIcon,
  HomeIcon,
  ClipboardDocumentListIcon,
  CheckCircleIcon,
  LinkIcon,
  BookOpenIcon,
  ArrowRightCircleIcon,
} from "@heroicons/react/24/outline";

import Step1 from "./Step1";
import AddItemsToYourHealthCircles from "./AddItemsToYourHealthCircles";
import AddItems from "./AddItems";
import CreateUpdateMentalHealthConnections from "./CreateUpdateMentalHealthConnections";
import GuidePage from "../Dashboard/Components/GuidePage";
import DefinitionsPage from "../Dashboard/Components/Definitions";
import { getDocs, doc, collection } from "firebase/firestore";
import { db } from "../../firebase-config";
import Calendar from "../Dashboard/Components/Calendar";

const navigation = [
  {
    name: "Overall Mental Health",
    href: "#",
    icon: "usePassed",
    current: true,
  },
  // { name: "Health Circles rating", href: "#", icon: UsersIcon, current: false },
  {
    name: "Add items to your Health Circles",
    href: "#",
    icon: ClipboardDocumentListIcon,
    current: false,
  },
  {
    name: "Create Connections",
    href: "#",
    icon: LinkIcon,
    current: false,
  },
  {
    name: "Add connections to your calendar",
    href: "#",
    icon: CalendarIcon,
    current: false,
  },
  {
    name: "Complete your assessment",
    href: "#",
    icon: CheckCircleIcon,
    current: false,
  },
];
const supportNavigation = [
  {
    name: "Start Here",
    href: "#",
    icon: ArrowRightCircleIcon,
    current: false,
  },
  {
    name: "Definitions",
    href: "#",
    icon: BookOpenIcon,
    current: false,
  },
];
const userNavigation = [
  // { name: "Your profile", href: "/profilePage", icon: UsersIcon },
  { name: "Sign out", href: "/loginPage", icon: HomeIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function FormDashboard(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sectionDisplay, setSectionDisplay] = useState(props.section);
  const [user, setUser] = useState("");
  const [userInfo, setUserInfo] = useState("");
  const [assessment, setAssessment] = useState("");
  const [collectionId, setCollectionId] = useState("");
  const [openConnection, setOpenConnection] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        console.log("uid", uid);
        const collectionRef = collection(db, "User");
        const tmp = await getDocs(collectionRef);
        const ids = tmp.docs.map((doc) => doc.id);
        const tmp2 = tmp.docs.map((doc) => doc.data());
        console.log("ids", ids);
        console.log("tmp2", tmp2);
        for (let i = 0; i < tmp2.length; i++) {
          if (tmp2[i].uuid === uid) {
            setUser(ids[i]);
            setUserInfo(tmp2[i]);
            const docRef = doc(db, "User", ids[i]);
            console.log("docRef", docRef);
            const subCollectionRef = collection(
              db,
              "User",
              ids[i],
              "Assessments"
            );
            const subCollection = await getDocs(subCollectionRef);
            const subCollectionIds = subCollection.docs.map((doc) => doc.id);
            const subCollectionData = subCollection.docs.map((doc) =>
              doc.data()
            );
            // go through each subcollection and find the one with the most recent data
            var lastDate = 0;
            var lastDateIndex = 0;
            subCollectionData.forEach((element, index) => {
              //console.log("element", element, index);
              if (element.date > lastDate) {
                lastDate = element.date;
                lastDateIndex = index;
              }
            });

            setAssessment(subCollectionData[lastDateIndex]);
            setCollectionId(subCollectionIds[lastDateIndex]);

            console.log("subCollectionIds", subCollectionIds[lastDateIndex]);
            setShow(true);
            setIsLoading(false);
          }
        }
      } else {
        console.log("user is logged out");
      }
    });
  }, []);

  useEffect(() => {
    if (location.state) {
      console.log("location.state", location.state.formSection);
      setCurrentSection(location.state.formSection);
    }
  }, [location]);

  useEffect(() => {
    document.title = "Mental Health Connections"; // Set the new tab name here
  }, []);

  const closeConnectionModal = () => {
    setOpenConnection(false);
  };

  const setCurrentSection = (section) => {
    navigation.map((item) => {
      setSectionDisplay(section);

      if (item.name === section) {
        // console.log(item.name);
        item.current = true;
      } else {
        item.current = false;
      }
    });
    supportNavigation.map((item) => {
      if (item.name === section) {
        item.current = true;
      } else {
        item.current = false;
      }
    });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return show ? (
    <>
      <div>
        <Transition.Root show={openConnection} as={Fragment}>
          <Dialog
            as='div'
            className='relative z-10'
            onClose={() => closeConnectionModal()}
          >
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            <div className='fixed inset-0 z-10 overflow-y-auto'>
              <div className='flex min-h-full items-end justify-center lg:ml-64 p-4 text-center sm:items-center sm:p-0'>
                <Transition.Child
                  as={Fragment}
                  enter='ease-out duration-300'
                  enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                  enterTo='opacity-100 translate-y-0 sm:scale-100'
                  leave='ease-in duration-200'
                  leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                  leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                >
                  <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6'>
                    <div>
                      <h1>Complete your Weekly Check In</h1>
                      <div className='grid grid-cols-2'>
                        <button
                          onClick={() => closeConnectionModal()}
                          className='m-4 justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:text-dark-text hover:bg-primary-light'
                        >
                          cancel
                        </button>
                        <button
                          onClick={() => saveForm()}
                          className='m-4 justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:text-dark-text hover:bg-primary-light'
                        >
                          confirm
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <div className=''>
          <main className='py-10 '>
            <div
              className='absolute left-0 top-0 -z-10 transform-gpu overflow-hidden blur-3xl sm:top-0'
              aria-hidden='true'
            >
              <div
                className='relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-primary-light to-primary opacity-30 sm:left-4 sm:w-[72.1875rem]'
                style={{
                  clipPath:
                    "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                }}
              />
            </div>
            <div
              className='absolute left-0 bottom-0 -z-10 transform-gpu overflow-hidden blur-3xl sm:bottom-0'
              aria-hidden='true'
            >
              <div
                className='aspect-[1097/845] w-[68.5625rem] bg-gradient-to-r from-primary-light to-primary opacity-25'
                style={{
                  clipPath:
                    "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                }}
              />
            </div>
            <div className='px-4 sm:px-6 lg:px-8'>
              {(() => {
                switch (sectionDisplay) {
                  case "Overall Mental Health":
                    return (
                      <Step1
                        userID={user}
                        docInfo={collectionId}
                        information={assessment}
                        tutorialMode={props.tutorialMode}
                      />
                    );
                  case "Health Circles rating":
                    return <AddItemsToYourHealthCircles />;
                  case "Add items to your Health Circles":
                    return (
                      <AddItems
                        userID={user}
                        docInfo={collectionId}
                        information={assessment}
                        page={"FormDashboard"}
                        tutorialMode={props.tutorialMode}
                      />
                    );
                  case "Create Connections":
                    return (
                      <CreateUpdateMentalHealthConnections
                        userID={user}
                        docInfo={collectionId}
                        information={assessment}
                        page={"FormDashboard"}
                        tutorialMode={props.tutorialMode}
                      />
                    );
                  case "Add connections to your calendar":
                    return (
                      <Calendar
                        userID={user}
                        docInfo={collectionId}
                        information={assessment}
                        page={"FormDashboard"}
                        tutorialMode={props.tutorialMode}
                      />
                    );
                  case "Start Here":
                    return <GuidePage />;
                  case "Definitions":
                    return <DefinitionsPage />;
                  default:
                    return (
                      <Step1
                        userID={user}
                        docInfo={collectionId}
                        information={assessment}
                      />
                    );
                }
              })()}
            </div>
          </main>
        </div>
      </div>
    </>
  ) : null;
}
