import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import MonthData from "../../../../Components/UserData/MonthData";
import YearData from "../../../../Components/UserData/YearData";
import PropTypes from "prop-types";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    title: {
      display: false,
      text: "Overall Mental Health",
    },
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      min: 0,
      max: 10,
    },
  },
};

export function LineChart(props) {
  LineChart.propTypes = {
    userID: PropTypes.string,
    timePeriod: PropTypes.string,
    selectedMonth: PropTypes.string,
  };

  const [lineData, setLineData] = useState(null);
  useEffect(() => {
    async function fetchData() {
      let data = null;
      if (props.timePeriod === "month") {
        data = await MonthData(props.userID, "LineChart", props.selectedMonth);
      }
      if (props.timePeriod === "year") {
        data = await YearData(props.userID, "LineChart");
      }

      setLineData(data);
    }
    fetchData();
  }, [props.timePeriod, props.selectedMonth]);

  return <div>{lineData && <Line options={options} data={lineData} />}</div>;
}
