import React, { useEffect } from "react";
import { Fragment, useRef, useState } from "react";
import {
  Dialog,
  Transition,
  TransitionChild,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../../firebase-config";
import { Formik, Form, Field } from "formik";

export default function ResetPasswordPopup({
  open: initialOpen,
  onClose,
  ...props
}) {
  const [resetPassword, setResetPassword] = useState(initialOpen);
  const cancelButtonRef = useRef(null);
  const [updatePassword, setUpdatePassword] = useState(false);

  useEffect(() => {
    setResetPassword(initialOpen);
  }, [initialOpen]);

  const handleClose = () => {
    setResetPassword(false);
    setUpdatePassword(false);
    onClose();
  };

  return (
    <Transition show={resetPassword} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={handleClose}>
        <TransitionChild
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </TransitionChild>

        <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
          <div className='flex min-h-full justify-center p-4 text-center items-center sm:p-0'>
            <TransitionChild
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <DialogPanel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
                {updatePassword == false ? (
                  <Formik
                    initialValues={{ email: "" }}
                    validate={(values) => {
                      const errors = {};
                      if (!values.email) {
                        errors.email = "Required";
                      } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                          values.email
                        )
                      ) {
                        errors.email = "Invalid email address";
                      }
                      return errors;
                    }}
                    onSubmit={async (values) => {
                      try {
                        await sendPasswordResetEmail(auth, values.email);
                        setUpdatePassword(true);
                      } catch (error) {
                        console.error("Error sending reset email:", error);
                      }
                    }}
                  >
                    <Form>
                      <div>
                        <div className='mt-3 text-center sm:mt-5'>
                          <DialogTitle
                            as='h3'
                            className='text-base font-semibold leading-6 text-gray-900'
                          >
                            Reset Your Password
                          </DialogTitle>
                          <div className='mt-2'>
                            <p className='text-sm text-gray-500'>
                              Enter your email address below and we'll send you
                              a link with instructions on how to recover your
                              password.
                            </p>
                            <Field
                              name='email'
                              type='email'
                              className='rounded-lg m-4 hover:border-primary focus:border-primary focus:ring-0 focus:ring-primary focus:ring-opacity-50'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3'>
                        <button
                          type='submit'
                          className='inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white hover:text-dark-text shadow-sm hover:bg-primary-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary sm:col-start-2'
                        >
                          Submit
                        </button>
                        <button
                          type='button'
                          className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0'
                          onClick={() => handleClose()}
                          ref={cancelButtonRef}
                        >
                          Cancel
                        </button>
                      </div>
                    </Form>
                  </Formik>
                ) : (
                  <div>
                    <div className='mt-3 text-center sm:mt-5'>
                      <DialogTitle
                        as='h3'
                        className='text-base font-semibold leading-6 text-gray-900'
                      >
                        Reset Your Password
                      </DialogTitle>
                      <div className='my-4'>
                        <p className='text-sm text-gray-500'>
                          A password reset email has been sent to your email
                          address. Please check your inbox and junk for the
                          email.
                        </p>
                        {/* <p className='text-sm text-gray-500'>If you are still experiencing problems </p> */}
                      </div>
                      <div>
                        <button
                          onClick={() => handleClose()}
                          className='inline-flex w-3/4 md:w-1/2 justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white hover:text-dark-text shadow-sm hover:bg-primary-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary sm:col-start-2'
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
