import React, { useRef, useState, useEffect } from "react";
import ServerConnectionTest from "../../../Services/Testconnection";
import "react-toastify/dist/ReactToastify.css";
import copy from "copy-to-clipboard";
import { toast, ToastContainer } from "react-toastify";
import {
  ClipboardDocumentCheckIcon,
  ClipboardDocumentIcon,
} from "@heroicons/react/24/outline";
import axios from "axios";

export default function CalendarSubscriptions(props) {
  console.log(props);
  const textRef = useRef();
  const [calendarUrlCopy, setCalendarUrlCopy] = useState(false);
  const [data, setData] = useState(null);
  const [calendarUrl, setCalendarUrl] = useState(null);

  useEffect(() => {
    // Making a GET request to the Express server
    const updateCalendarUrl = async () => {
      axios
        .get("https://app.byamour.ca/")
        .then((response) => {
          // Assuming the response is text, you can directly set it
          setData(response.data);

          // try and register the user to the calendar set user to the current user
          const userData = {
            username: props.userID,
            password: "testPassword",
          };
          console.log("Posting data:", userData);

          axios
            .post("https://app.byamour.ca/register", userData)
            .then((response) => {
              // Try to parse JSON, but catch any errors if the response isn't valid JSON
              try {
                const data = response.data;
                console.log("User registered successfully:", data);
              } catch (e) {
                console.error("Failed to parse response as JSON:", e);
              }
            })
            .catch((error) => {
              if (error.response) {
                // Server responded with a status other than 2xx
                console.error("Server error:", error.response.status);
                console.error("Response data:", error.response.data);
                // if the user already exists, try and login
                if (error.response.status === 409) {
                  console.error("User already exists");
                }
              } else if (error.request) {
                // Request was made but no response received
                console.error("No response received:", error.request);
              } else {
                // Something happened in setting up the request
                console.error("Error setting up request:", error.message);
              }
            });
          // Logging the server response to check connectivity
          console.log("Response from server:", response.data);
          setCalendarUrl(
            "webcal://app.byamour.ca/calendar.ics/" + props.userID
          );
        })
        .catch((error) => {
          // Logging any errors that occur during the request
          console.error("Error fetching data:", error);
        });

      // You can set a static calendar URL here if needed
    };
    updateCalendarUrl();
  }, [props.userID]); // Empty dependency array means this runs once when the component mounts

  const handleUser = async (input) => {
    try {
      // Making a POST request to the Express server
      const postData = { username: input };
      console.log("Posting data:", postData);

      // Wait for the response from the server
      const response = await axios.post(
        "https://app.byamour.ca/getUser",
        postData
      );

      // Log and return the user ID
      console.log("Response from server:", response.data.id);
      return response.data.id;
    } catch (error) {
      // Log the error and optionally rethrow it
      console.error("Error posting data:", error);
      throw error;
    }
  };

  const handleDownload = async () => {
    try {
      const response = await axios.get(
        "https://app.byamour.ca/calendar.ics/" + props.userID,
        {
          responseType: "blob",
        }
      );
      const url = URL.createObjectURL(
        new Blob([response.data], { type: "text/calendar" })
      );
      const a = document.createElement("a");
      a.href = url;
      a.download = "calendar.ics";
      a.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading iCal:", error);
    }
  };

  const copyToClipboard = () => {
    let copyText = textRef.current.value;
    let isCopy = copy(copyText);
    if (isCopy) {
      toast.success("Copied to Clipboard");
      setCalendarUrlCopy(true);
    }
  };
  return (
    <div className="w-11/12 md:w-5/6 xl:w-3/4 mx-auto min-h-96 p-2 bg-light-text border-dark-text border-2 rounded-md divide-y-2">
      <ToastContainer
        position="bottom-center"
        autoClose={500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <h1 className="text-2xl font-semibold m-4">Subscribe to your events</h1>

      <div className="m-4 divide-y-2">
        <div className="my-2">
          <h3 className="text-lg pb-4 font-semibold leading-6 text-dark-text">
            Subscribe using your preferred calendar application
          </h3>

          <ServerConnectionTest calendarLink={calendarUrl} />

          <div>
            <h3 className="text-lg mt-4 pb-4 font-semibold leading-6 text-dark-text">
              How to manually add the Subscription link in your calendar
              application
            </h3>
            <div className="flex bg-slate-200 rounded-lg">
              <input
                type="text"
                disabled
                className="w-11/12 p-2 border-0 bg-transparent text-dark-text"
                value={calendarUrl}
                ref={textRef}
              />
              <button onClick={copyToClipboard}>
                {calendarUrlCopy ? (
                  <ClipboardDocumentCheckIcon className="h-8 w-auto p-1 text-light-text bg-primary rounded-lg " />
                ) : (
                  <ClipboardDocumentIcon className="h-6 w-auto " />
                )}
              </button>
            </div>

            <ul className="mx-8 mt-4">
              <li>
                <h4 className="font-semibold">Google Calendar:</h4>
                <ul className="list-disc ml-8">
                  <li>Open Google Calendar</li>
                  <li>Click on the + sign next to 'Other Calendars'</li>
                  <li>Select 'From URL'</li>
                  <li>Paste the subscription link in the URL field</li>
                  <li>Click 'Add Calendar'</li>
                </ul>
              </li>
              <li className="mt-4">
                <h4 className="font-semibold">Apple Calendar:</h4>
                <ul className="list-disc ml-8">
                  <li>Open Apple Calendar</li>
                  <li>Click on 'File' in the menu bar</li>
                  <li>Select 'New Calendar Subscription'</li>
                  <li>Paste the subscription link in the URL field</li>
                  <li>Click 'Subscribe'</li>
                </ul>
              </li>
              <li className="mt-4">
                <h4 className="font-semibold">Outlook:</h4>
                <ul className="list-disc ml-8">
                  <li>Open Outlook Calendar</li>
                  <li>Click on 'Add Calendar' in the menu bar</li>
                  <li>Select 'From Internet'</li>
                  <li>Paste the subscription link in the URL field</li>
                  <li>Click 'Add'</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div className="">
          <h3 className="text-lg mt-4 pb-4 font-semibold leading-6 text-dark-text">
            Download a snapshot of your calendar
          </h3>
          <button
            className="p-2 rounded-lg bg-primary text-light-text hover:bg-primary-light hover:text-dark-text max-h-10"
            onClick={handleDownload}
          >
            Place holder for ICS Generator
          </button>
          {/* <IcsGenerator
            eventlist={calendarEvents}
            docID={props.userID}
            payment={payment}
          /> */}
        </div>
      </div>
    </div>
  );
}
