// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rc-slider-mark-text {
  font-size: 20px; /* Adjust the font size as needed */
}
`, "",{"version":3,"sources":["webpack://./src/Pages/MentalHealthConnectionForm/test.css"],"names":[],"mappings":"AAAA;EACE,eAAe,EAAE,mCAAmC;AACtD","sourcesContent":[".rc-slider-mark-text {\n  font-size: 20px; /* Adjust the font size as needed */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
