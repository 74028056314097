import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase-config";

export default async function MostFrequentConnection(userID) {
  const connectionRef = doc(
    db,
    "User/" + userID + "/Connections",
    "Connections"
  );
  const connectionSnap = await getDoc(connectionRef);
  if (connectionSnap.exists()) {
    let connectionInfo = connectionSnap.data().connectionList;
    // sort the connections by count
    connectionInfo.sort((a, b) => b.count - a.count);
    // remove the events that are scheduled by the user

    connectionInfo = connectionInfo.slice(0, 20);
    // return the list of names
    let connectionNameList = [];
    connectionInfo.forEach((element) => {
      connectionNameList.push(element.name);
    });
    return connectionNameList;
  } else {
    return [];
  }
}
