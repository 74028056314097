import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
// import "toolcool-range-slider";
// import "toolcool-range-slider/dist/plugins/tcrs-marks.min.js";
// import "toolcool-range-slider/dist/plugins/tcrs-generated-labels.min.js";
import { Link } from "react-router-dom";
import AddItemsToYourHealthCircles from "./AddItemsToYourHealthCircles";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "./test.css";
import { BsChevronDown } from "react-icons/bs";
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase-config";
import Overall_week_icon_green from "../../Images/Overall_week_icon_green.svg";
import weekly_self_assessment from "../../Images/thumbnails/Weeky self-assessment of your mental health.png";
import Video1 from "../../Video/Step1.mp4";

export default function Step1(props) {
  const [showStep1, setshowStep1] = useState(true);
  const [overall_mental_health, set_overall_mental_health] = useState(5);
  const [information, setInformation] = useState(props.information);
  const [showDefault, setshowDefault] = useState(false);

  console.log(props.userID);
  console.log(props.docInfo);

  useEffect(() => {
    async function getOverallMentalHealth() {
      if (props.information) {
        const docRef = doc(
          db,
          "User/" + props.userID + "/Assessments",
          props.docInfo
        );
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setInformation(docSnap.data());
          set_overall_mental_health(docSnap.data().overall_mental_health);
        }
        console.log(
          "useEffect setting overall Mental Health:" +
            props.information.overall_mental_health
        );
        setshowStep1(true);
      }
    }
    getOverallMentalHealth();
  }, [props.information]);

  const sliderChange = async (value) => {
    const docRef = doc(
      db,
      "User/" + props.userID + "/Assessments",
      props.docInfo
    );
    await updateDoc(docRef, { overall_mental_health: value });
    set_overall_mental_health(value);
  };
  const sliderColor = {
    backgroundColor: "#f2f2f2",
    height: "15px",
    border: "2px solid #009200",
  };
  const dotColor = {
    borderColor: "#2f4858",
    backgroundColor: "#2F4858",
    height: "12px",
    width: "12px",
    bottom: "-7px",
    fontSize: "32px",
  };
  const activeDotColor = {
    borderColor: "#009200",
    backgroundColor: "#009200",
    height: "12px",
    width: "12px",
    bottom: "-7px",
    fontSize: "32px",
  };
  const trackColor = {
    backgroundColor: "#009200",
    borderColor: "#009200",
    height: "15px",
    bottom: "-10px",
  };

  const marks = {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    9: "9",
    10: "10",
  };

  const [test, setTest] = useState(0);
  function testFunction() {
    setTest(test + 1);
  }

  return showStep1 ? (
    <div>
      <div className='mb-10 flex flex-col items-end'>
        <Link
          to='/dashboard'
          onClick={testFunction}
          state={{
            section: "Self Assessment",
            formSection: "Add items to your Health Circles",
          }}
          className='p-2 mr-4 mb-2 min-w-button text-center rounded-lg bg-primary text-light-text hover:bg-primary-light hover:text-dark-text '
        >
          Next
        </Link>
      </div>

      <Formik
        initialValues={{
          overall_mental_health: "",
        }}
        onSubmit={(values) => {
          console.log(values);
        }}
      >
        <Form className='border-2  border-dark-text bg-light-text rounded-lg m-4'>
          <div className='grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-1 mx-auto'>
            <div className='my-2 mx-4 h-12 text-md flex justify-start content-center'>
              <img
                src={Overall_week_icon_green}
                className='max-h-32 min-w-32 max-w-48 hidden sm:inline text-primary'
              />
              <div className='w-full sm:w-5/6'>
                <h1 className='m-4 text-3xl font-semibold tracking-tight text-gray-900 sm:text-2xl'>
                  Step 1: Weekly Self-Assessment
                </h1>
                <p className='text-lg'>
                  Start your weekly self-assessment by indicating your average
                  Mental Health over the past 7 days. Where 1 is poor and 10 is
                  great.
                </p>
              </div>
            </div>
            <div className='h-20 mt-40 sm:mt-20 lg:mt-20 mx-auto w-5/6 items-center'>
              <Slider
                className='w-full'
                min={1}
                max={10}
                step={1}
                marks={marks}
                defaultValue={overall_mental_health}
                dotStyle={dotColor}
                activeDotStyle={activeDotColor}
                trackStyle={{ backgroundColor: "#009200", height: 10 }}
                handleStyle={{
                  borderColor: "009200",
                  height: 28,
                  width: 28,
                  marginLeft: -1,
                  marginTop: -9,
                  backgroundColor: "black",
                }}
                railStyle={{ height: 10 }}
                onChange={sliderChange}
              />
            </div>
          </div>
          <p
            className='text-primary font-semibold ml-4 m-2 text-start flex cursor-pointer w-1/4'
            onClick={() => setshowDefault(!showDefault)}
          >
            Mental Health Definition
            <BsChevronDown className=' mt-1 ml-1' />
          </p>
          {showDefault && (
            <p className='text-lg m-4 border-primary border-2 p-2 bg-light-text rounded-md'>
              Mental health is the state of your psychological and emotional
              well-being. It is a necessary resource for living a healthy life
              and a main factor in overall health. Good mental health allows you
              to feel, think and act in ways that help you enjoy life and cope
              with its challenges.{" "}
              <Link
                className='text-primary hover:underline'
                target='_blank'
                to='https://www.canada.ca/en/public-health/services/about-mental-health.html'
              >
                More information
              </Link>
            </p>
          )}
          {props.tutorialMode ? (
            <div>
              <div className='mt-3 text-center sm:mt-5'>
                <div className='mt-2 flex justify-center '>
                  <div className='mt-1 w-1/2 gap-x-1.5'>
                    <ReactPlayer
                      url='https://vimeo.com/970933451'
                      controls
                      width='100%'
                      height='100%'
                      className='min-h-80'
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </Form>
      </Formik>

      <div className='mt-10 border-2 border-dark-text bg-light-text rounded-lg m-4'>
        <AddItemsToYourHealthCircles
          userID={props.userID}
          information={information}
          docInfo={props.docInfo}
          test={test}
        />
      </div>
      <div className=''>
        <Link
          to='/dashboard'
          onClick={testFunction}
          state={{
            section: "Self Assessment",
            formSection: "Add items to your Health Circles",
          }}
          className='p-2 mr-4 mb-2 min-w-button text-center float-right rounded-lg bg-primary text-light-text hover:bg-primary-light hover:text-dark-text'
        >
          Next
        </Link>
      </div>
    </div>
  ) : null;
}
