// function to create a MHC

import { db } from "../../firebase-config";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import CheckConnection from "./CheckConnection";
import CreateEvent from "../Event/CreateEvent";
// props: userID, connectionName, repeat
// wont have a data passed to it
export default async function CreateConnection(
  userID,
  connectionName,
  sectionList,
  repeat
) {
  // add the connections to the Connections list in firestore
  const docRef = doc(db, "User/" + userID + "/Connections", "Connections");
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    let connectionInfo = [];

    // check if the connection list is empty
    if (docSnap.data().connectionList === undefined) {
      await updateDoc(docRef, { connectionList: [] });
    } else {
      connectionInfo = docSnap.data().connectionList;
    }
    let local_name = "";
    if (typeof connectionName === "string") {
      local_name = connectionName;
    } else {
      connectionName.forEach((item) => {
        local_name = local_name + item + ", ";
      });
      // remove the last comma
      local_name = local_name.slice(0, -2);
    }
    // check if the connection already exists
    let connectionExists = await CheckConnection(local_name, connectionInfo);
    if (connectionExists) {
      return "connection already exists";
    } else {
      // create a new connection
      let newConnection = {};
      newConnection = { ...newConnection, name: local_name };
      newConnection = { ...newConnection, sectionList: sectionList };
      newConnection = { ...newConnection, count: 0 };
      connectionInfo.push(newConnection);

      await updateDoc(docRef, { connectionList: connectionInfo });
      return await CreateEvent(
        local_name,
        "",
        "",
        "",
        "",
        "",
        userID,
        false,
        sectionList,
        "first",
        "none"
      );
    }
  }

  // last reset the form the next connection
}
