import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import HeaderMenu from "../../Components/HeaderMenu";
import FooterWebsite from "../../Components/FooterWebsite";
import { motion } from "framer-motion";

const references = [
  {
    title: "Canadian Mental Health Association",
    referenceUrl: "https://cmha.ca/",
  },
  {
    title: "Mental Health Commission of Canada",
    referenceUrl: "https://mentalhealthcommission.ca/",
  },
  {
    title: "Wellness Together Canada",
    referenceUrl: "https://www.wellnesstogether.ca/en-ca",
  },

  {
    title: "Psychology Today - Find a Therapist near you",
    referenceUrl: "https://www.psychologytoday.com/ca/therapists",
  },
  {
    title: "The Blue Dot Project for Maternal Mental Health",
    referenceUrl: "https://www.thebluedotproject.org/",
  },
  {
    title: "World Health Organization - Mental Health",
    referenceUrl: "https://www.who.int/health-topics/mental-health#tab=tab_1",
  },
  {
    title: "Buddy Check for Jesse",
    referenceUrl: "https://buddycheckforjesse.com/",
  },
];

const provincialResources = [
  {
    title: "Suicide Crisis Helpline",
    referenceUrl: "https://988.ca/",
  },
  {
    title: "Kids Help Phone Canada",
    referenceUrl: "https://kidshelpphone.ca/",
  },
  {
    title: "Crisis Service Canada",
    referenceUrl: "https://talksuicide.ca/",
  },
  {
    title: "BC 211",
    referenceUrl: "https://bc.211.ca/",
  },
  {
    title: "Alberta 211",
    referenceUrl: "https://www.distresscentre.com/need-help/211-2/",
  },
  {
    title: "Manitoba 211",
    referenceUrl: "https://mb.211.ca/",
  },
  {
    title: "Saskatchewan 211",
    referenceUrl: "https://sk.211.ca/",
  },
  {
    title: "Ontario 211",
    referenceUrl: "https://211ontario.ca/",
  },
  {
    title: "Quebec 211",
    referenceUrl: "https://www.211qc.ca/en/",
  },
  {
    title: "New Brunswick 211",
    referenceUrl: "https://nb.211.ca/",
  },
  {
    title: "Nova Scotia 211",
    referenceUrl: "https://ns.211.ca/",
  },
  {
    title: "Prince Edward Island 211",
    referenceUrl: "https://pe.211.ca/",
  },
  {
    title: "Newfoundland & Labrador 211",
    referenceUrl: "https://nl.211.ca/",
  },
  {
    title: "Yukon 211",
    referenceUrl: "https://yt.211.ca/",
  },
  {
    title: "Northwest Territories 211",
    referenceUrl: "https://ab.211.ca/record/1128632/",
  },
  {
    title: "Nunavut 211",
    referenceUrl: "https://nu.211.ca/",
  },
];

export default function LinksPage(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();
  let loginStatus = location.state?.loginStatus;
  if (loginStatus === undefined) {
    loginStatus = props.loginStatus;
  }

  console.log("loginStatus = " + props.loginStatus);

  return (
    <motion.div
      className='relative'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {loginStatus === false ? <HeaderMenu /> : <div></div>}
      <div
        className='absolute left-0 top-0 -z-10 transform-gpu overflow-hidden blur-3xl sm:top-0'
        aria-hidden='true'
      >
        <div
          className='relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-primary-light to-primary opacity-30 sm:left-4 sm:w-[72.1875rem]'
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div
        className='absolute left-0 bottom-0 -z-10 transform-gpu overflow-hidden blur-3xl sm:bottom-0'
        aria-hidden='true'
      >
        <div
          className='aspect-[1097/845] w-[68.5625rem] bg-gradient-to-r from-primary-light to-primary opacity-25'
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <main className='grid grid-cols-2 gap-x-4  isolate overflow-hidden mx-10 px-8 pt-8 sm:pt-8 lg:overflow-visible '>
        <div>
          <div className='text-center mt-8'>
            <h1 className='text-2xl pb-4 tracking-tight leading-10 font-extrabold text-gray-800 sm:text-3xl sm:leading-none md:text-5xl'>
              Acute Mental Health Support
            </h1>
          </div>
          <ul className='divide-y divide-dark-text max-w-xl mx-auto'>
            {provincialResources.map((item) => (
              <li key={item.title} className='flex justify-between py-5'>
                <div className='flex gap-x-4'>
                  <div className='min-w-0 flex-auto'>
                    <Link to={item.referenceUrl} target='_blank'>
                      <h2 className='text-sm hover:font-bold sm:text-md md:text-lg font-semibold leading-6 text-dark-text'>
                        {item.title}
                      </h2>
                    </Link>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <div className='text-center  mt-8'>
            <h1 className='text-2xl pb-4 tracking-tight leading-10 font-extrabold text-gray-800 sm:text-3xl sm:leading-none md:text-5xl'>
              External Mental Health Resources
            </h1>
          </div>
          <ul className='divide-y divide-gray-800 max-w-xl mx-auto'>
            {references.map((item) => (
              <li
                key={item.referenceUrl}
                className='flex justify-between gap-x-6 py-5'
              >
                <div className='flex gap-x-4'>
                  <div className='min-w-0 flex-auto'>
                    <Link to={item.referenceUrl} target='_blank'>
                      <motion.h2
                        className='text-sm sm:text-md md:text-lg font-semibold leading-6 text-dark-text ml-2'
                        whileHover={{ scale: 1.1 }}
                      >
                        {item.title}
                      </motion.h2>
                    </Link>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </main>
      {loginStatus === false ? <FooterWebsite /> : <div></div>}
    </motion.div>
  );
}
