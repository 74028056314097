import React from "react";
import { Link } from "react-router-dom";
import MentalHealthConnectionsLogoGreen from "../Images/MentalHealthConnectionsTextLogoColor.svg";

const footerNavigation = {
  support: [
    { name: "Contact Us", href: "https://mhconnections.ca/ContactPage" },
    { name: "Pricing", href: "https://mhconnections.ca/#pricing" },
  ],
  company: [{ name: "About Us", href: "https://mhconnections.ca/AboutPage" }],
  resources: [
    {
      name: "Acute Mental Health Support",
      href: "https://mhconnections.ca/linksPage",
    },
    { name: "Mental Health News", href: "https://mhconnections.ca/NewsPage" },
  ],
  legal: [
    { name: "Privacy", href: "https://mhconnections.ca/privacy" },
    { name: "Terms", href: "https://mhconnections.ca/terms" },
  ],
};

export default function FooterWebsite() {
  return (
    <div className='mx-auto  max-w-7xl px-6 lg:px-8'>
      <footer
        aria-labelledby='footer-heading'
        className='relative border-t-2 border-dark-text pt-24 sm:mt-24 sm:pt-24'
      >
        <div className=''>
          <div className='mt-16 grid grid-cols-1 sm:grid-cols-5 gap-6 xl:col-span-2 xl:mt-0'>
            <div>
              <img
                alt='Mental Health Connections Logo'
                src={MentalHealthConnectionsLogoGreen}
                className='w-2/3 h-2/3 mx-auto'
              />
            </div>
            <div className=''>
              <h3 className='text-lg font-semibold leading-6 text-dark-text'>
                Support
              </h3>
              <ul className='mt-6 space-y-4'>
                {footerNavigation.support.map((item) => (
                  <li key={item.name}>
                    <a
                      href={item.href}
                      className='text-md leading-6 text-dark-text hover:text-primary
                        '
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className=''>
              <h3 className='text-lg font-semibold leading-6 text-dark-text'>
                Company
              </h3>
              <ul className='mt-6 space-y-4'>
                {footerNavigation.company.map((item) => (
                  <li key={item.name}>
                    <a
                      href={item.href}
                      className='text-md leading-6 text-dark-text hover:text-primary'
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className=''>
              <h3 className='text-lg font-semibold leading-6 text-dark-text'>
                Resources / News
              </h3>
              <ul className='mt-6 space-y-4'>
                {footerNavigation.resources.map((item) => (
                  <li key={item.name}>
                    <Link
                      to={item.href}
                      state={{ loginStatus: false }}
                      className='text-md leading-6 text-dark-text hover:text-primary'
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className=''>
              <h3 className='text-lg font-semibold leading-6 text-dark-text'>
                Legal
              </h3>
              <ul className='mt-6 space-y-4'>
                {footerNavigation.legal.map((item) => (
                  <li key={item.name}>
                    <a
                      href={item.href}
                      className='text-md leading-6 text-dark-text hover:text-primary'
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className='my-8 text-center'>
          <h1 className='font-semibold mx-auto my-4 h-11 te leading-6 text-dark-text'>
            2023 Mental Health Connections. All rights Reserved
          </h1>
        </div>
      </footer>
    </div>
  );
}
