import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase-config";

export default async function WeeklyData(userID, chartType, selectedMonth) {
  // get the weekly data

  const assessmentRef = collection(db, "User/" + userID + "/Assessments");
  const assessmentSnap = await getDocs(assessmentRef);
  // sort the assessments by date
  var assessmentList = [];
  assessmentSnap.forEach((doc) => {
    assessmentList.push(doc.data());
  });
  assessmentList.sort((a, b) => {
    return a.date - b.date;
  });
  const currentMonth = selectedMonth;
  // current year
  const currentYear = new Date().getFullYear();

  assessmentList = assessmentList.filter((assessment) => {
    const assessmentMonth = assessment.date.toDate().getMonth();

    // Check if the month of the assessment date matches the current month
    if (
      assessmentMonth === currentMonth &&
      assessment.date.toDate().getFullYear() === currentYear
    ) {
      return true;
    }
  });

  if (chartType === "LineChart") {
    return await getLineData(assessmentList);
  }
  if (chartType === "RadarChart") {
    if (assessmentList.length > 0) {
      return await getRadarData(assessmentList);
    }
  }

  // return the weekly data
}

async function getRadarData(assessmentList) {
  const rows = 9; // nine_circles.length;
  const columns = 4; //weeks in the month;
  var n = 0;
  // Create a 2D array and initialize it with values
  const catagories = Array.from({ length: rows }, () =>
    Array.from({ length: columns }, () => Math.floor(0))
  );
  const count = Array.from({ length: rows }, () =>
    Array.from({ length: columns }, () => Math.floor(0))
  );

  // go through each assessment
  while (assessmentList[n]) {
    // go through each category its a map

    var circles = assessmentList[n].nine_circles;
    var index = 0;
    for (const [key, value] of Object.entries(circles)) {
      if (value === 100) {
        catagories[index][n] += 0;
      } else {
        catagories[index][n] += value;
        count[index][n]++;
      }
      index++;
    }
    n++;
  }
  for (var i = 0; i < rows; i++) {
    for (var j = 0; j < columns; j++) {
      catagories[i][j] /= count[i][j];
    }
  }
  //   while (overallMentalHealth.length < 12) {
  //     overallMentalHealth.push(0);
  //   }

  return {
    labels: ["week 1", "week 2", "week 3", "week 4"],
    datasets: [
      {
        label: "Self-Care",

        data: catagories[0],
        backgroundColor: "rgba(75,192,192,0.0)",
        borderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "rgba(75,192,192,1)",
        borderWidth: 3,
      },
      {
        label: "Movement / Activity",
        data: catagories[1],
        backgroundColor: "rgba(255,255,71,0.0)",
        borderColor: "rgba(255,255,71,1)",
        pointBackgroundColor: "rgba(255,255,71,1)",
        borderWidth: 3,
      },
      {
        label: "Sleep / Recovery",
        data: catagories[2],
        backgroundColor: "rgba(0,100,71,0.0)",
        borderColor: "rgba(0,100,71,1)",
        pointBackgroundColor: "rgba(0,100,71,1)",
        borderWidth: 3,
      },
      {
        label: "Social Interaction",
        data: catagories[3],
        backgroundColor: "rgba(105,80,152,0.0)",
        borderColor: "rgba(255,99,132,1)",
        pointBackgroundColor: "rgba(255,99,132,1)",
        borderWidth: 3,
      },
      {
        label: "Healthy Nutrition & Snacks",
        data: catagories[4],
        backgroundColor: "rgba(179,3,43,0.0)",
        borderColor: "rgba(179,3,43,1)",
        pointBackgroundColor: "rgba(179,3,43,1)",
        borderWidth: 3,
      },
      {
        label: "Hobbies",
        data: catagories[5],
        backgroundColor: "rgba(98,57,255,0.0)",
        borderColor: "rgba(98,57,255,1)",
        pointBackgroundColor: "rgba(98,57,255,1)",
        borderWidth: 3,
      },
      {
        label: "Outdoor & Nature",
        data: catagories[6],
        backgroundColor: "rgba(33,249,157,0.0)",
        borderColor: "rgba(33,249,157,1)",
        pointBackgroundColor: "rgba(33,249,157,1)",
        borderWidth: 3,
      },
      {
        label: "Planning / Looking Forward To",
        data: catagories[7],
        backgroundColor: "rgba(169,106,126,0.0)",
        borderColor: "rgba(169,106,126,1)",
        pointBackgroundColor: "rgba(169,106,126,1)",
        borderWidth: 3,
      },
      {
        label: "Laughter / Makes you Smile",
        data: catagories[8],
        backgroundColor: "rgba(251,3,254,0.0)",
        borderColor: "rgba(251,3,254,1)",
        pointBackgroundColor: "rgba(251,3,254,1)",
        borderWidth: 3,
      },
    ],
  };
}

async function getLineData(assessmentList) {
  var n = 0;
  var overallMentalHealth = [];
  while (assessmentList[n]) {
    overallMentalHealth.push(assessmentList[n].overall_mental_health);
    n++;
  }
  while (overallMentalHealth.length < 4) {
    overallMentalHealth.push(0);
  }

  for (var i = 0; i < 4; i++) {
    if (overallMentalHealth[i] == 0) {
      overallMentalHealth[i] = "NaN";
    }
  }

  return {
    labels: ["week 1", "week 2", "week 3", "week 4"],
    datasets: [
      {
        label: "overall mental health",
        data: overallMentalHealth,
        borderColor: "rgb(00, 146, 00)",
        backgroundColor: "rgba(00, 146, 00, 0.5)",
      },
    ],
  };
}
