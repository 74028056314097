import { Formik, Field, Form } from "formik";
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase-config";
import { doc, getDoc } from "firebase/firestore";
import categories from "./common/healthCircleDef";
import PropTypes from "prop-types";

export default function DataHighlight(props) {
  DataHighlight.propTypes = {
    userID: PropTypes.string.isRequired,
  };

  const [timePeriod, setTimePeriod] = useState("week");
  const [stats, setStats] = useState([]);
  const [mostFrequent, setMostFrequent] = useState();
  const [toFocus, setToFocus] = useState([]);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const nextMonday = () => {
    const currentDate = new Date();

    // Calculate the difference between the current day and Monday
    const dayOfWeek = currentDate.getDay();
    const daysUntilMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

    // Calculate the date of the previous Monday
    const prevMonday = new Date(currentDate);
    prevMonday.setDate(currentDate.getDate() - daysUntilMonday);

    return prevMonday;
  };
  const prevMonday = nextMonday();

  const nextSunday = () => {
    const currentDate = new Date();

    // Calculate the difference between the current day and Monday
    const dayOfWeek = currentDate.getDay();
    const daysUntilSunday = dayOfWeek === 0 ? 0 : 7 - dayOfWeek;

    // Calculate the date of the previous Monday
    const nextSunday = new Date(currentDate);
    nextSunday.setDate(currentDate.getDate() + daysUntilSunday);

    return nextSunday;
  };
  const sunday = nextSunday();

  useEffect(() => {
    // get the information form the stats collection
    const getStats = async () => {
      const statsList = doc(db, "User/" + props.userID + "/Stats", "Stats");
      const statsListSnap = await getDoc(statsList);
      if (statsListSnap.exists()) {
        setStats(statsListSnap.data());
        const statsListSnapData = statsListSnap.data();
        let max = 0;
        let min = statsListSnapData.sections["movement_activity"];
        setToFocus(["movement & activity"]);
        if (statsListSnapData.sections !== undefined) {
          for (let key in statsListSnapData.sections) {
            if (statsListSnapData.sections[key] > max) {
              max = statsListSnapData.sections[key];
              for (var i = 0; i < categories.length; i++) {
                if (categories[i].id === key) {
                  setMostFrequent(categories[i].name);
                }
              }
            }
            if (statsListSnapData.sections !== undefined) {
              for (let key in statsListSnapData.sections) {
                if (statsListSnapData.sections[key] < min) {
                  min = statsListSnapData.sections[key];
                }
              }
              let focusList = [];
              for (let key in statsListSnapData.sections) {
                if (statsListSnapData.sections[key] === min) {
                  for (let i = 0; i < categories.length; i++) {
                    if (categories[i].id === key) {
                      focusList.push(categories[i].name);
                    }
                  }
                }
              }
              if (focusList.length === categories.length) {
                setToFocus(["More information needed"]);
              } else {
                while (focusList.length > 3) {
                  focusList.pop();
                }
                setToFocus(focusList);
              }
            }
          }
        }
      }
    };
    getStats();
  }, [timePeriod]);

  return (
    <div className='col-span-full bg-light-text p-2 rounded-lg shadow-xl'>
      <Formik
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
        }}
      >
        <Form className='flex w-full flex-col'>
          <div className='flex flex-col sm:flex-row justify-between gap-x-6 py-5 px-2'>
            <h1 className='p-4 text-xl font-medium text-dark-text'>
              Highlights
              {timePeriod === "week" ? (
                <p className='text-md text-gray-600'>
                  Mon, {monthNames[prevMonday.getMonth()]}{" "}
                  {prevMonday.getDate()}- Sun, {monthNames[sunday.getMonth()]}{" "}
                  {sunday.getDate()} 2023
                </p>
              ) : (
                <p className='text-md text-gray-600'>
                  {monthNames[prevMonday.getMonth()]} -{" "}
                  {monthNames[prevMonday.getMonth() + 1]}{" "}
                  {prevMonday.getFullYear()}
                </p>
              )}
            </h1>
            <Field
              as='select'
              onChange={(e) => {
                setTimePeriod(e.target.value);
              }}
              name='timePeriod'
              className=' shrink  sm:ml-auto mt-4 rounded-md border-1 m-2 text-dark-text focus:ring-2 focus:ring-inset focus:ring-primary sm:text-md'
            >
              <option value='week'>Last Week</option>
              <option value='month'>Last Month</option>
            </Field>
          </div>
          {/* information div */}
          <div className='grid grid-cols-1 sm:grid-cols-3 divide-y-2 sm:divide-y-0 sm:divide-x-2 divide-dark-text '>
            <div className='p-2'>
              <h2 className='text-lg ml-4 font-semibold'>Total Connections</h2>
              {timePeriod === "week" ? (
                <p className='text-primary font-semibold ml-4'>
                  {stats.total_completed}
                </p>
              ) : (
                <p className='text-primary font-semibold ml-4'>
                  {stats.total_completed}
                </p>
              )}
            </div>
            <div className='p-2'>
              <h2 className='text-lg font-semibold'>
                Most Frequent Health Circle Connections
              </h2>
              <p className='text-primary font-semibold'>{mostFrequent}</p>
            </div>
            <div className='p-2'>
              <h2 className='text-lg font-semibold'>
                Health Circle(s) to Consider Focusing On
              </h2>
              {toFocus.map((item) => (
                <p className='text-primary font-semibold' key={item}>
                  {item}
                </p>
              ))}
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
}
