import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import MonthData from "../../../../Components/UserData/MonthData";
import YearData from "../../../../Components/UserData/YearData";
import PropTypes from "prop-types";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export default function RadarChart(props) {
  RadarChart.propTypes = {
    userID: PropTypes.string,
    timePeriod: PropTypes.string,
  };

  const options = {
    scales: {
      r: {
        beginAtZero: true,
        min: 0,
        max: 10,
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  const [radarData, setRadarData] = useState(null);
  useEffect(() => {
    async function fetchData() {
      let data = null;
      if (props.timePeriod === "month") {
        data = await MonthData(props.userID, "RadarChart");
      }
      if (props.timePeriod === "year") {
        data = await YearData(props.userID, "RadarChart");
      }

      setRadarData(data);
    }
    fetchData();
  }, [props.userID, props.timePeriod]);

  return <div>{radarData && <Radar options={options} data={radarData} />}</div>;
}
