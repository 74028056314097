import React, { Fragment, useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { Dialog, Menu, Transition, Switch } from "@headlessui/react";
import sideTextLogoWhite from "../../Images/sideLogoWhite2.svg";
import { Formik, Form, Field } from "formik";
import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  BookOpenIcon,
  ArrowRightCircleIcon,
  ClipboardDocumentListIcon,
  ChartBarIcon,
  LinkIcon,
  NewspaperIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import MainPage from "./Components/MainPage";
import Calendar from "./Components/Calendar";
import Definitions from "./Components/Definitions";
import ProfilePage from "../ProfilePage/ProfilePage";
import MyData from "./Components/MyData";
import Guide from "./Components/GuidePage";
import CalendarSubscriptions from "./Components/CalendarSubscriptions";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../../firebase-config";
import {
  collection,
  query,
  orderBy,
  limit,
  onSnapshot,
  where,
  addDoc,
} from "firebase/firestore";
import AddItems from "../MentalHealthConnectionForm/AddItems";
import CreateUpdateMentalHealthConnections from "../MentalHealthConnectionForm/CreateUpdateMentalHealthConnections";
import FormDashboard from "../MentalHealthConnectionForm/FormDashboard";
import LinksPage from "../LinksPage/LinksPage";
import NewsPage from "../NewsPage/NewsPage";
import { motion } from "framer-motion";
import UpdatePaymentBanner from "./Components/Banners/updateProfile";

const navigation = [
  {
    name: "Dashboard",
    id: "dashboard",
    href: "#",
    step: 1,
    icon: HomeIcon,
    current: true,
  },
  {
    name: "Add Items to your Health Circles",
    id: "add-items-to-your-health-circles",
    href: "#",
    step: 3,
    icon: ClipboardDocumentListIcon,
    current: false,
  },
  {
    name: "Create MH Connections",
    id: "my-connections",
    href: "#",
    step: 2,
    icon: LinkIcon,
    current: false,
  },
  {
    name: "Manage MH Connections",
    id: "calendar",
    href: "#",
    step: 5,
    icon: CalendarIcon,
    current: false,
  },
  {
    name: "My Data",
    id: "my-data",
    href: "#",
    step: 7,
    icon: ChartBarIcon,
    current: false,
  },

  {
    name: "Definitions",
    id: "definitions",
    href: "#",
    step: 6,
    icon: BookOpenIcon,
    current: false,
  },
  {
    name: "News",
    id: "news",
    href: "#",
    step: 8,
    icon: NewspaperIcon,
    current: false,
  },
];

const smallNavigation = [
  {
    name: "Dashboard",
    id: "dashboard",
    href: "#",
    step: 1,
    icon: HomeIcon,
    current: true,
  },
  {
    name: "Add Items to your Health Circles",
    id: "add_items_to_your_health_circles",
    href: "#",
    step: 3,
    icon: ClipboardDocumentListIcon,
    current: false,
  },
  {
    name: "Create MH Connections",
    id: "my_connections",
    href: "#",
    step: 2,
    icon: LinkIcon,
    current: false,
  },
  {
    name: "Manage MH Connections",
    id: "calendar",
    href: "#",
    step: 5,
    icon: CalendarIcon,
    current: false,
  },

  {
    name: "My Data",
    id: "my_data",
    href: "#",
    step: 7,
    icon: ChartBarIcon,
    current: false,
  },
  {
    name: "Start Here",
    id: "start_here",
    href: "#",
    step: 7,
    icon: ArrowRightCircleIcon,
    current: false,
  },
  {
    name: "Definitions",
    id: "definitions",
    href: "#",
    step: 6,
    icon: BookOpenIcon,
    current: false,
  },
  {
    name: "News",
    id: "news",
    href: "#",
    step: 8,
    icon: NewspaperIcon,
    current: false,
  },
  {
    name: "Acute Mental Health Resources",
    id: "acute_mental_health_resources",
    href: "#",
    step: 7,
    icon: UsersIcon,
    current: false,
  },
  {
    name: "Your Profile",
    id: "your_profile",
    href: "#",
    step: 7,
    icon: UsersIcon,
    current: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Dashboard(props) {
  const [showPage, setShowPage] = useState(true);
  const location = useLocation();
  const [user, setUser] = useState("");
  const [userinfo, setUserinfo] = useState({});
  const [docInfo, setDocInfo] = useState("");
  const [information, setInformation] = useState({});
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sectionDisplay, setSectionDisplay] = useState("");
  const [formSectionDisplay, setFormSectionDisplay] = useState(
    "Overall Mental Health"
  );
  const cancelButtonRef = useRef(null);
  const [bugSent, setBugSent] = useState(false);
  const [formCompleted, setFormCompleted] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [reportBug, setReportBug] = useState(false);

  useEffect(() => {
    document.title = "Mental Health Connections"; // Set the new tab name here
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const userCollectionRef = collection(db, "User");
        const userQ = query(userCollectionRef, where("uuid", "==", user.uid));
        let userDocID = "";

        const userUnsubscribe = onSnapshot(userQ, async (querySnapshot) => {
          const user = [];
          // TODO - add error handling
          querySnapshot.forEach((doc) => {
            user.push(doc.data());
            userDocID = doc.id;
            setUser(doc.id);
            setUserinfo(doc.data());
            const collectionRef = collection(
              db,
              "User/" + userDocID + "/Assessments"
            );
            const q = query(collectionRef, orderBy("date", "desc"), limit(1));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
              querySnapshot.forEach((doc) => {
                setDocInfo(doc.id);
                setInformation(doc.data());
              });
            });
          });
          //setCurrentSection("Dashboard");
          //navigation[0].current = true;
        });

        // get the assessment info from the database and the doc id
      } else {
        console.log("user is logged out");
      }

      return () => {
        unsubscribe();
      };
    });
  }, []);

  useEffect(() => {
    if (location.state) {
      setCurrentSection(location.state.section);
      setSectionDisplay(location.state.section);

      if (formSectionDisplay != "Overall Mental Health") {
        setFormSectionDisplay(location.state.formSection);
      }
      if (location.state.formCompleted === true) {
        setFormCompleted(false);
      }
    }
  }, [location]);

  const setCurrentSection = (section) => {
    navigation.map((item) => {
      if (item.name === section) {
        item.current = true;
        setSectionDisplay(section);
      } else {
        item.current = false;
      }
      setSidebarOpen(false);
    });
    smallNavigation.map((item) => {
      if (item.name === section) {
        item.current = true;
        setSectionDisplay(section);
      } else {
        item.current = false;
      }
      setSidebarOpen(false);
    });
  };

  return showPage ? (
    <div>
      <div className='h-full '>
        <Transition.Root show={reportBug} as={Fragment}>
          <Dialog
            as='div'
            className='relative z-10'
            initialFocus={cancelButtonRef}
            onClose={setReportBug}
          >
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            <div className='fixed inset-0 z-10 overflow-y-auto'>
              <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center lg:pl-60 sm:p-0'>
                <Transition.Child
                  as={Fragment}
                  enter='ease-out duration-300'
                  enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                  enterTo='opacity-100 translate-y-0 sm:scale-100'
                  leave='ease-in duration-200'
                  leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                  leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                >
                  <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
                    <div>
                      <div className='mt-3 text-center sm:mt-5'>
                        <Dialog.Title
                          as='h3'
                          className='text-lg font-semibold leading-6 text-gray-900'
                        >
                          Report a bug
                        </Dialog.Title>
                        <div className='mt-2'>
                          <Formik
                            initialValues={{}}
                            onSubmit={async (values) => {
                              // send the email
                              let payload = {
                                to: "info@mhconnections.ca",
                                message: {
                                  subject: "bug report",
                                  replyTo: userinfo.email_username,
                                  html: `<h1>bug report</h1>
                                  <p>Email: ${userinfo.email_username}</p>
                                  <p>Message: ${values.message}</p>`,
                                },
                              };
                              addDoc(
                                collection(db, "contact_us_email"),
                                payload
                              );
                              console.log("sent");
                              setReportBug(false);
                              setBugSent(false);
                            }}
                          >
                            <Form className='w-3/4 mx-auto'>
                              <div className=''>
                                {bugSent ? (
                                  <div>
                                    <p className='text-left'>
                                      Thank you for your feedback. It has been
                                      sent to our team for review.
                                    </p>

                                    <button
                                      type='submit'
                                      ref={cancelButtonRef}
                                      className='mt-2 inline-flex col-span-2 w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white hover:text-dark-text shadow-sm hover:bg-primary-light'
                                    >
                                      Close
                                    </button>
                                  </div>
                                ) : (
                                  <div>
                                    <p className='text-left'>
                                      In your report include the title of the
                                      page your on and a brief description of
                                      the bug you encountered.
                                    </p>
                                    <Field
                                      as='textarea'
                                      rows='5'
                                      name='message'
                                      id='message'
                                      className='mt-2 block w-full shadow-sm sm:text-sm focus:ring-primary focus:border-primary border-gray-300 rounded-md '
                                      placeholder='Message'
                                    />

                                    <button
                                      type='button'
                                      onClick={() => setBugSent(true)}
                                      className='mt-2 inline-flex col-span-2 w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white hover:text-dark-text shadow-sm hover:bg-primary-light'
                                    >
                                      Send
                                    </button>
                                  </div>
                                )}
                              </div>
                            </Form>
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as='div'
            className='relative z-50 lg:hidden'
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter='transition-opacity ease-linear duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity ease-linear duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-gray-900/80' />
            </Transition.Child>

            <div className='fixed inset-0 flex'>
              <Transition.Child
                as={Fragment}
                enter='transition ease-in-out duration-300 transform'
                enterFrom='-translate-x-full'
                enterTo='translate-x-0'
                leave='transition ease-in-out duration-300 transform'
                leaveFrom='translate-x-0'
                leaveTo='-translate-x-full'
              >
                <Dialog.Panel className='relative mr-16 flex w-full max-w-md flex-1'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-in-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-300'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div className='absolute left-full top-0 flex w-16 justify-center pt-5'>
                      <button
                        type='button'
                        className='-m-2.5 p-2.5'
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className='sr-only'>Close sidebar</span>
                        <XMarkIcon
                          className='h-6 w-6 text-white'
                          aria-hidden='true'
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className='flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 ring-1 ring-white/10'>
                    <div className='flex h-28 w-auto shrink-0 items-center'>
                      <img
                        alt='side logo'
                        src={sideTextLogoWhite}
                        className='pt-4 h-28 w-auto text-primary'
                      />
                    </div>
                    <h2 className='text-wrap text-2xl font-semibold text-light-text'>
                      Beta Version
                    </h2>
                    <nav className='flex flex-1 flex-col'>
                      <ul className='flex flex-1 flex-col gap-y-7'>
                        <li>
                          <ul className='-mx-2  space-y-1'>
                            {smallNavigation.map((item) => {
                              if (
                                item.name === "Acute Mental Health Resources"
                              ) {
                                return (
                                  <button
                                    key={item.name}
                                    id={item.id}
                                    to={item.href}
                                    onClick={() => setCurrentSection(item.name)}
                                    className={classNames(
                                      item.current
                                        ? "bg-light-text text-primary"
                                        : "text-gray-300",
                                      "sm:hidden  bg-slate-200 text-red-700 group flex items-center rounded-md px-2 py-2 text-base font-medium w-full hover:bg-red-700 hover:text-light-text"
                                    )}
                                  >
                                    {item.icon == "usePassed" ? (
                                      <img
                                        alt='overall logo'
                                        src={Overall_week_icon}
                                        className='h-7 w-7 pr-2 flex-shrink-0 stroke-primary stroke-2'
                                      />
                                    ) : (
                                      <item.icon
                                        className={classNames(
                                          item.current
                                            ? "text-primary"
                                            : "text-gray-400 group-hover:text-light-text",
                                          "text-red-700 mr-4 h-6 w-6 flex-shrink-0"
                                        )}
                                        aria-hidden='true'
                                      />
                                    )}{" "}
                                    {item.name}
                                  </button>
                                );
                              }

                              return (
                                <button
                                  key={item.name}
                                  id={item.id}
                                  to={item.href}
                                  onClick={() => setCurrentSection(item.name)}
                                  className={classNames(
                                    item.current
                                      ? "bg-light-text text-primary"
                                      : "text-gray-300 hover:bg-light-text hover:text-primary",
                                    "group flex items-center rounded-md px-2 py-2 text-base font-medium w-full"
                                  )}
                                >
                                  {item.icon == "usePassed" ? (
                                    <img
                                      alt='overall logo'
                                      src={Overall_week_icon}
                                      className='h-7 w-7 pr-2 flex-shrink-0 stroke-primary stroke-2'
                                    />
                                  ) : (
                                    <item.icon
                                      className={classNames(
                                        item.current
                                          ? "text-primary"
                                          : "text-gray-400 group-hover:text-primary",
                                        "mr-4 h-6 w-6 flex-shrink-0"
                                      )}
                                      aria-hidden='true'
                                    />
                                  )}{" "}
                                  {item.name}
                                </button>
                              );
                            })}
                          </ul>
                        </li>

                        <li className='mt-auto'>
                          <Link
                            to='/loginPage'
                            className='group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white'
                          >
                            <HomeIcon
                              className='h-6 w-6 shrink-0'
                              aria-hidden='true'
                            />
                            Logout{" "}
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className='hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-82 lg:flex-col'>
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className='flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4'>
            <div className='flex h-28 shrink-0 items-center'>
              <img
                alt='side logo'
                src={sideTextLogoWhite}
                className='pt-4 h-28 w-auto text-primary'
              />
            </div>
            <h2 className='text-wrap text-2xl font-semibold text-light-text'>
              Beta Version
            </h2>
            <nav className='flex flex-1 flex-col'>
              <ul className='flex flex-1 flex-col gap-y-7'>
                <li>
                  <ul className='-mx-2 mt-2 space-y-1'>
                    {navigation.map((item) => (
                      <motion.button
                        key={item.name}
                        id={item.id + "static_menu"}
                        to={item.href}
                        whileHover={{ scale: 1.1, duration: 0.5 }}
                        onClick={() => setCurrentSection(item.name)}
                        className={classNames(
                          item.current
                            ? "bg-light-text text-primary"
                            : "text-gray-300 hover:bg-light-text hover:text-primary",
                          "group flex items-center rounded-md px-2 py-2 text-base font-medium w-full"
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.current
                              ? "text-primary"
                              : "text-gray-400 group-hover:text-primary",
                            "mr-4 h-6 w-6 flex-shrink-0"
                          )}
                          aria-hidden='true'
                        />

                        <span className='truncate'>{item.name}</span>
                      </motion.button>
                    ))}
                  </ul>
                </li>
                <li className='mt-auto flex justify-between'>
                  <p className='text-light-text'>Tutorial Mode</p>
                  <Switch
                    checked={enabled}
                    onChange={setEnabled}
                    className={classNames(
                      enabled ? "bg-primary" : "bg-gray-200",
                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 "
                    )}
                  >
                    <span className='sr-only'>Use setting</span>
                    <span
                      aria-hidden='true'
                      className={classNames(
                        enabled ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                      )}
                    />
                  </Switch>
                </li>

                <li>
                  <Link
                    to='/loginPage'
                    className='group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white'
                  >
                    <HomeIcon className='h-6 w-6 shrink-0' aria-hidden='true' />
                    Logout{" "}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div>
          <div className='sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8 lg:pl-80'>
            <button
              type='button'
              id='open_side_menu'
              className='-m-2.5 p-2.5 text-gray-700 lg:hidden'
              onClick={() => setSidebarOpen(true)}
            >
              <span className='sr-only'>Open sidebar</span>
              <Bars3Icon className='h-6 w-6' aria-hidden='true' />
            </button>
            <Link
              key='top Start Here'
              to='/Dashboard'
              state={{ section: "Start Here" }}
              className='bg-slate-200 border-2 hover:border-dark-text group flex items-center rounded-md px-2 ml-4 py-2 text-base font-medium '
            >
              <ArrowRightCircleIcon className='mr-4 h-6 w-6 flex-shrink-0 text-primary' />

              <span className='truncate'>Start Here</span>
            </Link>

            {/* Separator */}
            <div
              className='h-6 w-px hidden sm:block bg-gray-900/10 '
              aria-hidden='true'
            />
            <Link
              key='mental health resources'
              to='/Dashboard'
              state={{ section: "Acute Mental Health Resources" }}
              onClick={() => setCurrentSection("Acute Mental Health Resources")}
              className='bg-slate-200 hidden border-2 hover:border-red-700 sm:flex text-red-700 hover:bg-red-700 hover:text-light-text group items-center rounded-md px-2 py-2 text-base font-medium '
            >
              <UsersIcon
                className=' mr-4 h-6 w-6 flex-shrink-0'
                aria-hidden='true'
              />

              {/* <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                            {item.initial}
                          </span> */}
              <span className='truncate'>Acute Mental Health Resources</span>
            </Link>

            <div className='flex flex-1 gap-x-4 self-stretch lg:gap-x-6'>
              <form
                className='relative flex flex-1'
                action='#'
                method='GET'
              ></form>
              <div className='flex items-center gap-x-4 lg:gap-x-6'>
                {/* {userinfo.paid === true ? <p>paid</p> : <p>not paid</p>} */}

                {/* Separator */}
                <div
                  className='hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10'
                  aria-hidden='true'
                />

                {/* Profile dropdown */}
                <Menu as='div' className='relative'>
                  <Menu.Button className='-m-1.5 flex items-center p-1.5'>
                    <span className='sr-only'>Open user menu</span>

                    <span className='hidden lg:flex lg:items-center'>
                      <span
                        className='ml-4 text-sm font-semibold leading-6 text-gray-900'
                        aria-hidden='true'
                      >
                        {userinfo.first_name} {userinfo.last_name}
                      </span>
                      <ChevronDownIcon
                        className='ml-2 h-5 w-5 text-gray-400'
                        aria-hidden='true'
                      />
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Menu.Items className='absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none'>
                      <Menu.Item key='sideMenuProfile'>
                        {({ active }) => (
                          <button
                            onClick={() => setSectionDisplay("Your Profile")}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "w-full text-left block px-3 py-1 text-sm leading-6 text-gray-900"
                            )}
                          >
                            Your Profile
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item key='sideMenu'>
                        {({ active }) => (
                          <Link
                            to='/loginPage'
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              " block px-3 py-1 text-sm leading-6 text-gray-900"
                            )}
                          >
                            Sign Out
                          </Link>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className='flex grow h-full w-full bg-primary bg-opacity-10 overflow-x-hidden'>
            <div
              className='fixed bottom-0 right-0 mb-4 mr-4 bg-black text-white p-2 rounded z-50 cursor-pointer'
              onClick={() => setReportBug(true)}
            >
              Report a bug
            </div>
            <div
              className='absolute w-full left-0 top-0 -z-10 transform-gpu overflow-hidden blur-3xl sm:top-0'
              aria-hidden='true'
            >
              <div
                className='relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-primary-light to-primary opacity-30 sm:left-4 sm:w-[72.1875rem]'
                style={{
                  clipPath:
                    "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                }}
              />
            </div>
            <div
              className='absolute w-full left-0 bottom-0 -z-10 transform-gpu overflow-hidden blur-3xl sm:bottom-0'
              aria-hidden='true'
            >
              <div
                className='aspect-[1097/845] w-[68.5625rem] bg-gradient-to-r from-primary-light to-primary opacity-25'
                style={{
                  clipPath:
                    "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                }}
              />
            </div>
            <div
              className='absolute w-full right-0 top-0 -z-10 transform-gpu overflow-hidden blur-3xl sm:top-0'
              aria-hidden='true'
            >
              <div
                className='relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-primary-light to-primary opacity-30 sm:left-4 sm:w-[72.1875rem]'
                style={{
                  clipPath:
                    "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                }}
              />
            </div>
            <div
              className='absolute w-full left-0 bottom-0 -z-10 transform-gpu overflow-hidden blur-3xl sm:bottom-0'
              aria-hidden='true'
            >
              <div
                className='aspect-[1097/845] w-[68.5625rem] bg-gradient-to-r from-primary-light to-primary opacity-25'
                style={{
                  clipPath:
                    "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                }}
              />
            </div>
            <div className='p-2 sm:p-4 w-full' id='parent-container'>
              <div className=' lg:pl-80 pb-10' id='child-container'>
                {userinfo.paid === false ? <UpdatePaymentBanner /> : null}
                {(() => {
                  switch (sectionDisplay) {
                    case "Create MH Connections":
                      return (
                        <CreateUpdateMentalHealthConnections
                          userID={user}
                          docInfo={docInfo}
                          information={information}
                          tutorialMode={enabled}
                        />
                      );
                    case "Add Items to your Health Circles":
                      return (
                        <AddItems
                          userID={user}
                          docInfo={docInfo}
                          information={information}
                          tutorialMode={enabled}
                        />
                      );
                    case "Manage MH Connections":
                      return <Calendar userID={user} tutorialMode={enabled} />;
                    case "Calendar Subscriptions":
                      return (
                        <CalendarSubscriptions
                          userID={user}
                          docInfo={userinfo}
                        />
                      );
                    case "Start Here":
                      return <Guide userID={user} />;
                    case "Definitions":
                      return <Definitions userID={user} />;
                    case "Acute Mental Health Resources":
                      return <LinksPage loginStatus={true} />;
                    case "News":
                      return <NewsPage loginStatus={true} />;
                    case "My Data":
                      return <MyData userID={user} docInfo={docInfo} />;
                    case "Your Profile":
                      return <ProfilePage userID={user} />;
                    case "Self Assessment":
                      return (
                        <FormDashboard
                          userID={user}
                          sectionDisplay={formSectionDisplay}
                          tutorialMode={enabled}
                        />
                      );

                    default:
                      return (
                        <MainPage
                          userID={user}
                          docInfo={docInfo}
                          information={information}
                          formCompleted={formCompleted}
                        />
                      );
                  }
                })()}
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  ) : null;
}
