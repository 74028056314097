import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase-config";
import { hi } from "date-fns/locale";

export default async function WeeklyData(userID, chartType, selectedMonth) {
  const assessmentRef = collection(db, "User/" + userID + "/Assessments");
  const assessmentSnap = await getDocs(assessmentRef);

  let assessmentList = [];
  assessmentSnap.forEach((doc) => {
    assessmentList.push(doc.data());
  });

  assessmentList.sort((a, b) => {
    return a.date - b.date;
  });

  if (selectedMonth === undefined) {
    selectedMonth = new Date().getMonth();
  }
  const currentMonth = selectedMonth;
  const currentYear = new Date().getFullYear();

  assessmentList = assessmentList.filter((assessment) => {
    const assessmentMonth = assessment.date.toDate().getMonth();
    return (
      assessmentMonth === currentMonth &&
      assessment.date.toDate().getFullYear() === currentYear
    );
  });

  const numWeeks = getNumberOfSaturdays(currentYear, currentMonth);

  if (chartType === "LineChart") {
    return await getLineData(assessmentList, numWeeks);
  }
  if (chartType === "RadarChart") {
    if (assessmentList.length > 0) {
      return await getRadarData(assessmentList, numWeeks);
    }
  }
}

function getNumberOfSaturdays(year, month) {
  let saturdays = [];
  const date = new Date(year, month, 1);

  while (date.getMonth() === month) {
    if (date.getDay() === 6) {
      saturdays.push(new Date(date));
    }
    date.setDate(date.getDate() + 1);
  }

  return saturdays.length;
}

async function getRadarData(assessmentList, numWeeks) {
  const rows = 9; // nine_circles.length;
  const columns = numWeeks; // Dynamically set based on Saturdays
  let n = 0;
  // Create a 2D array and initialize it with values
  const categories = Array.from({ length: rows }, () =>
    Array.from({ length: columns }, () => Math.floor(0))
  );
  const count = Array.from({ length: rows }, () =>
    Array.from({ length: columns }, () => Math.floor(0))
  );

  while (assessmentList[n]) {
    let circles = assessmentList[n].nine_circles;
    let index = 0;
    for (const [, value] of Object.entries(circles)) {
      if (value === 100) {
        categories[index][n] += 0;
      } else {
        categories[index][n] += value;
        count[index][n]++;
      }
      index++;
    }
    n++;
  }

  for (let i = 0; i < rows; i++) {
    for (let j = 0; j < columns; j++) {
      categories[i][j] /= count[i][j];
    }
  }

  return {
    labels: Array.from({ length: numWeeks }, (_, i) => `week ${i + 1}`),
    datasets: [
      {
        label: "Self-Care",

        data: categories[0],
        backgroundColor: "rgba(75,192,192,0.0)",
        borderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "rgba(75,192,192,1)",
        borderWidth: 3,
      },
      {
        label: "Movement / Activity",
        data: categories[1],
        backgroundColor: "rgba(255,255,71,0.0)",
        borderColor: "rgba(255,255,71,1)",
        pointBackgroundColor: "rgba(255,255,71,1)",
        borderWidth: 3,
      },
      {
        label: "Sleep / Recovery",
        data: categories[2],
        backgroundColor: "rgba(0,100,71,0.0)",
        borderColor: "rgba(0,100,71,1)",
        pointBackgroundColor: "rgba(0,100,71,1)",
        borderWidth: 3,
      },
      {
        label: "Social Interaction",
        data: categories[3],
        backgroundColor: "rgba(105,80,152,0.0)",
        borderColor: "rgba(255,99,132,1)",
        pointBackgroundColor: "rgba(255,99,132,1)",
        borderWidth: 3,
      },
      {
        label: "Healthy Nutrition & Snacks",
        data: categories[4],
        backgroundColor: "rgba(179,3,43,0.0)",
        borderColor: "rgba(179,3,43,1)",
        pointBackgroundColor: "rgba(179,3,43,1)",
        borderWidth: 3,
      },
      {
        label: "Hobbies",
        data: categories[5],
        backgroundColor: "rgba(98,57,255,0.0)",
        borderColor: "rgba(98,57,255,1)",
        pointBackgroundColor: "rgba(98,57,255,1)",
        borderWidth: 3,
      },
      {
        label: "Outdoor & Nature",
        data: categories[6],
        backgroundColor: "rgba(33,249,157,0.0)",
        borderColor: "rgba(33,249,157,1)",
        pointBackgroundColor: "rgba(33,249,157,1)",
        borderWidth: 3,
      },
      {
        label: "Planning / Looking Forward To",
        data: categories[7],
        backgroundColor: "rgba(169,106,126,0.0)",
        borderColor: "rgba(169,106,126,1)",
        pointBackgroundColor: "rgba(169,106,126,1)",
        borderWidth: 3,
      },
      {
        label: "Laughter / Makes you Smile",
        data: categories[8],
        backgroundColor: "rgba(251,3,254,0.0)",
        borderColor: "rgba(251,3,254,1)",
        pointBackgroundColor: "rgba(251,3,254,1)",
        borderWidth: 3,
      },
    ],
  };
}

async function getLineData(assessmentList, numWeeks) {
  let n = 0;
  let overallMentalHealth = [];
  while (assessmentList[n]) {
    overallMentalHealth.push(assessmentList[n].overall_mental_health);
    n++;
  }

  while (overallMentalHealth.length < numWeeks) {
    overallMentalHealth.push(0);
  }

  for (let i = 0; i < numWeeks; i++) {
    if (overallMentalHealth[i] === 0) {
      overallMentalHealth[i] = "NaN";
    }
  }

  return {
    labels: Array.from({ length: numWeeks }, (_, i) => `week ${i + 1}`),
    datasets: [
      {
        label: "overall mental health",
        data: overallMentalHealth,
        borderColor: "rgb(00, 146, 00)",
        backgroundColor: "rgba(00, 146, 00, 0.5)",
      },
    ],
  };
}
