import { db } from "../../firebase-config";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";
import fetch from "node-fetch";

export default async function CheckStripePayment(userEmail) {
  const backendUrl = process.env.BACKEND_URL;

  try {
    let stripeCustomerId = await getStripeCustomerId(userEmail);

    const response = await fetch(`${backendUrl}check-subscription`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        customerId: stripeCustomerId,
      }),
    });
    const data = await response.json();
    // set the paid status of the user based on the response
    // get the user document from the user collection in firestore
    const userRef = collection(db, "User");
    const userQuery = query(userRef, where("email_username", "==", userEmail));
    const userSnapshot = await getDocs(userQuery);
    const userDoc = doc(db, "User", userSnapshot.docs[0].id);

    if (
      data.data[0].status === "active" ||
      data.data[0].status === "trialing"
    ) {
      await updateDoc(userDoc, { paid: true });
    }

    // Check if the user has an active subscription
  } catch (error) {
    console.error("Error checking Stripe payment:", error);
    throw error;
  }
}

export async function getStripeCustomerId(userEmail) {
  try {
    // Query the customers collection to find the document with the specified email
    const customerQuery = query(
      collection(db, "customers"),
      where("email", "==", userEmail)
    );
    const customerSnapshot = await getDocs(customerQuery);

    if (customerSnapshot.empty) {
      return null; // or throw an error if you prefer
    }

    // Assuming there should only be one customer with this email
    let stripeCustomerId = null;
    customerSnapshot.forEach((doc) => {
      stripeCustomerId = doc.data().stripeId;
    });

    return stripeCustomerId;
  } catch (error) {
    console.error("Error retrieving customer:", error);
    throw error; // rethrow the error after logging it
  }
}
