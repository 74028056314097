// use the stripe api to get the status of the trail

import React from "react";
import { getStripeCustomerId } from "./GetStripeStatus";

export default async function GetTrailStatus(userEmail) {
  const backendUrl = process.env.BACKEND_URL;

  try {
    let stripeCustomerId = await getStripeCustomerId(userEmail);

    const response = await fetch(`${backendUrl}check-trail`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        customerId: stripeCustomerId,
      }),
    });
    const data = await response.json();
    return data.data[0].trial_end;
  } catch (error) {
    console.error("Error checking Stripe payment:", error);
    throw error;
  }
}
