import getConnectionList from "./getConnectionList";
import GetCurrentUser from "../User/GetCurrentUser";

export default async function CheckConnection(connectionName, connectionList) {
  var formattedName = "";
  // format the connection name
  for (var i = 0; i < connectionName.length; i++) {
    formattedName = formattedName + connectionName[i] + ", ";
  }
  // remove the last comma
  formattedName = formattedName.slice(0, -2);
  connectionName = formattedName;

  if (connectionName.length === 0) {
    return true;
  }
  if (connectionList === "" || connectionList === undefined) {
    const user = await GetCurrentUser();
    connectionList = await getConnectionList(user[0].id);
  }
  var connectionFound = false;
  for (var i = 0; i < connectionList.length; i++) {
    if (connectionList[i].name.toLowerCase() === connectionName.toLowerCase()) {
      connectionFound = true;
      break;
    }
  }

  if (connectionFound === true) {
    return true;
  } else {
    //connection not found
    return false;
  }
}
