import React, { useEffect } from "react";
import { Field } from "formik";
import { min } from "date-fns";

const generateHourOptions = () => {
  const options = [];
  for (let hour = 1; hour <= 12; hour++) {
    const value = String(hour).padStart(2, "0");
    options.push(
      <option key={value} value={value}>
        {value}
      </option>
    );
  }
  return options;
};

const generateMinuteOptions = () => {
  const options = [];
  for (let minute = 0; minute < 60; minute += 5) {
    const value = String(minute).padStart(2, "0");
    options.push(
      <option key={value} value={value}>
        {value}
      </option>
    );
  }
  return options;
};

const amPmOptions = () => {
  return (
    <>
      <option value='AM'>AM</option>
      <option value='PM'>PM</option>
    </>
  );
};

// takes a time string in the format "HH:MM" using the 24-hour clock
// returns 3 select fields for hour, minute, and AM/PM
export default function CustomTimeJump({ field, form, ...props }) {
  useEffect(() => {
    // convert the single time string to an array of [hour, minute, AM/PM]
    const timeArray = field.value.split(":");
    let hour = timeArray[0];
    const rawMinute = timeArray[1];
    let amPm = "AM";
    if (hour >= 12) {
      amPm = "PM";
      hour -= 12;
    }
    // Find the closest 5-minute interval
    const remainder = rawMinute % 5;
    let minute;
    if (remainder === 0) {
      minute = String(rawMinute).padStart(2, "0");
    } else if (remainder < 3) {
      minute = String(rawMinute - remainder).padStart(2, "0");
    } else {
      minute = String(rawMinute + (5 - remainder)).padStart(2, "0");
    }
    if (minute.length === 3) {
      // check if the last digit is 0
      if (minute[2] === "0") {
        minute = minute.slice(0, 2);
        // check if the first digit is 0
      } else if (minute[0] === "0") {
        minute = minute.slice(0, 2) + "0";
      }
    }

    form.setFieldValue(`${field.name}Hour`, hour.toString().padStart(2, "0"));
    form.setFieldValue(`${field.name}Minute`, minute);
    form.setFieldValue(`${field.name}AmPm`, amPm);
  }, [field.value]);

  useEffect(() => {
    // check if form values is filled out
    if (!form.values[`${field.name}Hour`]) return;

    if (parseInt(form.values[`${field.name}Hour`], 10) > 12) {
      let tmpHours = parseInt(form.values[`${field.name}Hour`], 10);
      tmpHours -= 12;
      form.setFieldValue(
        `${field.name}Hour`,
        tmpHours.toString().padStart(2, "0")
      );
    }
  }, [form.values[`${field.name}Hour`]]);

  useEffect(() => {
    const hourVal = form.values[`${field.name}Hour`];
    const minuteVal = form.values[`${field.name}Minute`];
    const amPmVal = form.values[`${field.name}AmPm`];

    // Only proceed if all values are available
    if (!hourVal || !minuteVal || !amPmVal) return;

    let hour = parseInt(hourVal, 10);

    // Convert to 24-hour format
    if (amPmVal === "PM" && hour < 12) {
      hour += 12;
    } else if (amPmVal === "AM" && hour === 12) {
      hour = 0;
    }

    const newTimeValue = `${hour.toString().padStart(2, "0")}:${minuteVal}`;

    // Prevent infinite loop by checking if value actually changed
    if (newTimeValue !== field.value) {
      form.setFieldValue(field.name, newTimeValue);
    }
  }, [
    form.values[`${field.name}Hour`],
    form.values[`${field.name}Minute`],
    form.values[`${field.name}AmPm`],
  ]);

  return (
    <div className='flex space-x-2 justify-center'>
      <Field
        as='select'
        name={`${field.name}Hour`}
        className='mt-1 focus:ring-primary focus:border-primary shadow-sm
        sm:text-sm border-gray-300 rounded-md'
      >
        {generateHourOptions()}
      </Field>
      <div className='my-auto'>:</div>

      <Field
        as='select'
        name={`${field.name}Minute`}
        className='mt-1 focus:ring-primary focus:border-primary shadow-sm sm:text-sm border-gray-300 rounded-md'
      >
        {generateMinuteOptions()}
      </Field>
      <Field
        as='select'
        name={`${field.name}AmPm`}
        className='mt-1 focus:ring-primary focus:border-primary shadow-sm sm:text-sm border-gray-300 rounded-md'
      >
        {amPmOptions()}
      </Field>
    </div>
  );
}
