import { db } from "../../firebase-config";
import {
  doc,
  getDoc,
  updateDoc,
  Timestamp,
  addDoc,
  collection,
} from "firebase/firestore";
import getConnectionList from "../Connection/getConnectionList";
import UpdateDateConnections from "../Connection/UpdateDateConnection";
import addEvent from "../CalendarApi/AddEvent";

// create and event in the users calendar
// if no dates are passed then use old dates 1969 Dec 31 16:00:00
// all events are init with completed = false
// if no time is passed then schedule == false
// weeklyRepeat used for days of the week the event repeats on
export default async function CreateEvent(
  eventName,
  eventLocation,
  startDate,
  endDate,
  startTime,
  endTime,
  userID,
  allDay,
  selectedSectionList,
  repeatType,
  repeatEnd,
  attendeesList,
  repeatFlag,
  weeklyRepeat
) {
  // get the calendar collection
  const calendarRef = doc(db, "User/" + userID + "/Calendar", "Calendar");
  const calendarSnap = await getDoc(calendarRef);
  // make sure it exists
  if (calendarSnap.exists()) {
    let calendarInfo = calendarSnap.data().events;

    // get the users name
    const userRef = doc(db, "User", userID);
    const userSnap = await getDoc(userRef);
    let user = "";
    if (userSnap.exists()) {
      user = userSnap.data().first_name + " " + userSnap.data().last_name;
    }

    // formate the string so it can be compared to the event name
    let connectionName = "";
    if (typeof eventName === "string") {
      connectionName = eventName;
    } else {
      eventName.forEach((item) => {
        connectionName = connectionName + item + ", ";
      });
    }
    const event = calendarInfo.find((event) => event.name === connectionName);
    // will return calendarInfo list form both functions
    if (event === undefined) {
      calendarInfo = await createNewEvent(
        connectionName,
        eventLocation,
        startDate,
        startTime,
        endDate,
        endTime,
        allDay,
        repeatType,
        repeatEnd,
        calendarInfo,
        attendeesList,
        user,
        repeatFlag,
        selectedSectionList,
        weeklyRepeat,
        userID
      );
    } else {
      calendarInfo = await updateEvent(
        event,
        eventLocation,
        startDate,
        startTime,
        endDate,
        endTime,
        allDay,
        repeatType,
        repeatEnd,
        calendarInfo,
        attendeesList,
        user,
        repeatFlag,
        calendarRef,
        weeklyRepeat,
        userID
      );
    }

    try {
      await updateDoc(calendarRef, {
        events: calendarInfo,
      });
    } catch (e) {
      console.error(e);
    }
    // update the count in the connection list
    updateConnectionCount(connectionName, userID);
    return calendarInfo;
  }
}

async function updateConnectionCount(eventName, userID) {
  let connectionList = await getConnectionList(userID);
  connectionList.forEach((element) => {
    if (element.name === eventName) {
      element.count = element.count + 1;
      UpdateDateConnections(userID, eventName);
    }
  });
}

async function createNewEvent(
  connectionName,
  eventLocation,
  startDate,
  startTime,
  endDate,
  endTime,
  allDay,
  repeatType,
  repeatEnd,
  calendarInfo,
  attendeesList,
  user,
  repeatFlag,
  selectedSectionList,
  weeklyRepeat,
  userID
) {
  let eventStart = 0;
  let eventEnd = 0;
  let RType = repeatType;
  let REnd = repeatEnd;
  let isScheduled = false;

  // set the time
  // check if the event has a start date
  if (startDate === "" && allDay === false) {
    // set eventStart and eventEnd to 1/1/1970
    eventStart = new Date(1 / 1 / 1970).getTime();
    eventEnd = new Date(1 / 1 / 1970).getTime();
  } else {
    // check for all day event
    if (allDay) {
      startTime = "00:00:00";
      endTime = "23:59:59";
    }
    eventStart = new Date(startDate + "T" + startTime).getTime();
    eventEnd = new Date(endDate + "T" + endTime).getTime();

    isScheduled = true;
  }
  const endCheck = new Date(repeatEnd).getTime() / 1000;
  const today = new Date().getTime() / 1000;

  // check if repeat end has passed
  if (endCheck < today) {
    RType = "none";
    REnd = "none";
  }

  if (repeatFlag === undefined) {
    repeatFlag = false;
  }
  if (attendeesList === undefined) {
    attendeesList = [];
  }
  if (weeklyRepeat === undefined) {
    weeklyRepeat = [];
  }

  let newEvent = {};
  newEvent = {
    ...newEvent,
    name: connectionName,
    startTime: Timestamp.fromMillis(eventStart),
    endTime: Timestamp.fromMillis(eventEnd),
    completed: false,
    location: eventLocation,
    scheduled: isScheduled,
    allDay: allDay,
    repeatType: RType,
    repeatEnd: REnd,
    attendeesList: attendeesList,
    repeatFlag: repeatFlag,
    weeklyRepeat: weeklyRepeat,
  };

  // older events don't have section lists
  if (selectedSectionList !== undefined) {
    newEvent = { ...newEvent, sectionList: selectedSectionList };
  } else {
    newEvent = { ...newEvent, sectionList: [] };
  }

  if (RType === "first") {
    calendarInfo.unshift(newEvent);
  } else {
    calendarInfo.push(newEvent);
  }

  // only send emails if the event is scheduled
  if (newEvent.scheduled === true && attendeesList !== undefined) {
    checkEmail(attendeesList, newEvent, user);
  }
  // update the connection list with the true scheduled value\

  // call Calendar API to add event
  addEvent(newEvent);

  return calendarInfo;
}

async function updateEvent(
  event,
  location,
  startDate,
  startTime,
  endDate,
  endTime,
  allDay,
  repeatType,
  repeatEnd,
  calendarInfo,
  attendeesList,
  user,
  repeatFlag,
  calendarRef,
  weeklyRepeat,
  userID
) {
  let eventStart = 0;
  let eventEnd = 0;
  let isScheduled = false;

  // the event is completed needs this for the all day events
  if (
    startDate === "" &&
    startTime === "" &&
    endDate === "" &&
    endTime === ""
  ) {
    // set eventStart and eventEnd to 1/1/1970
    eventStart = new Date(1 / 1 / 1970).getTime();
    eventEnd = new Date(1 / 1 / 1970).getTime();
  } else {
    if (startDate === "" && allDay === false) {
      // check if the event has a start date
      // set eventStart and eventEnd to 1/1/1970
      eventStart = new Date(1 / 1 / 1970).getTime();
      eventEnd = new Date(1 / 1 / 1970).getTime();
    } else {
      // check for all day event
      if (event.allDay) {
        startTime = "00:00:00";
        endTime = "23:59:59";
      }
      eventStart = new Date(startDate + "T" + startTime).getTime();
      eventEnd = new Date(endDate + "T" + endTime).getTime();

      isScheduled = true;
    }
  }
  const endCheck = new Date(repeatEnd).getTime() / 1000;
  const today = new Date().getTime() / 1000;
  let RType = repeatType;
  let REnd = repeatEnd;
  // check if repeat end has passed
  if (endCheck < today) {
    RType = "none";
    REnd = "none";
  }
  if (weeklyRepeat === undefined) {
    weeklyRepeat = [];
  }
  // update the event with the new info
  event.startTime = Timestamp.fromMillis(eventStart);
  event.endTime = Timestamp.fromMillis(eventEnd);
  event.completed = false;
  event.scheduled = isScheduled;
  event.allDay = allDay;
  event.repeatType = RType;
  event.repeatEnd = REnd;
  event.location = location;
  event.attendeesList = attendeesList;
  event.repeatFlag = repeatFlag;
  event.weeklyRepeat = weeklyRepeat;
  let selectedSectionList = event.sectionList;
  if (selectedSectionList === undefined) {
    event.sectionList = [];
  }

  // only send emails if the event is scheduled
  if (event.scheduled === true && attendeesList !== undefined) {
    checkEmail(attendeesList, event, user);
  }

  // call Calendar API to add event
  addEvent(event, userID);

  return calendarInfo;
}

// invite emails moved here for when a event is modified
function checkEmail(attendeesList, event, user) {
  let payload = {
    to: "",
    template: {},
  };

  let formattedDateString = "";
  let formattedEndDateString = "";

  if (event.allDay === true) {
    event.startTime.seconds = event.startTime.seconds - 86400;
    event.endTime.seconds = event.endTime.seconds - 86400;
  } else {
    // startTime
    const dateString = new Date(event.startTime.seconds * 1000).toString();
    const gmtIndex = dateString.indexOf("GMT");

    formattedDateString =
      gmtIndex !== -1 ? dateString.slice(0, gmtIndex) : dateString;
    // endTime
    const endDateString = new Date(event.endTime.seconds * 1000).toString();
    const endGmtIndex = endDateString.indexOf("GMT");
    formattedEndDateString =
      endGmtIndex !== -1 ? endDateString.slice(0, endGmtIndex) : endDateString;
  }
  for (const email of attendeesList) {
    payload.to = email.email;
    payload.template = {
      name: "invite_email",
      data: {
        invitedPerson: email.email,
        MHCUser: user,
        connectionName: event.name,
        location: event.location,
        startTime: formattedDateString,
        endTime: formattedEndDateString,
      },
    };
    addDoc(collection(db, "contact_us_email"), payload);
  }
}
