import React from "react";
import { Link } from "react-router-dom";

export default function UpdatePaymentBanner() {
  return (
    <div className='flex items-center gap-x-6 bg-red-600 px-6 py-2.5 sm:px-3.5 sm:before:flex-1 rounded-xl'>
      <p className='text-sm leading-6 text-white'>
        <Link
          to='/dashboard'
          state={{
            section: "Your Profile",
          }}
        >
          Your account has been temporary disabled. Please update your payment
          method on your profile page to enable your account &nbsp;
          <span aria-hidden='true'>&rarr;</span>
        </Link>
      </p>
      <div className='flex flex-1 justify-end'></div>
    </div>
  );
}
