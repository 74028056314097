import React, { useState } from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import DataHighlight from "./DataHighlight";
import OverallMentalHealthData from "./OverallMentalHealthData";
import OverallProgressData from "./OverallProgressData";
import { motion } from "framer-motion";
import PropTypes from "prop-types";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export default function MyData(props) {
  MyData.propTypes = {
    userID: PropTypes.string.isRequired,
  };

  const [timePeriod, setTimePeriod] = useState("year");
  const [radarTimePeriod, setRadarTimePeriod] = useState("year");
  const currentDate = new Date();

  return (
    <motion.div
      className='mt-4'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className='grid grid-cols-1 gap-6 sm:grid-cols-3 lg:grid-cols-3'>
        <DataHighlight userID={props.userID} date={currentDate} />
        <OverallMentalHealthData
          userID={props.userID}
          timePeriod={timePeriod}
          date={currentDate}
        />

        <OverallProgressData
          userID={props.userID}
          timePeriod={radarTimePeriod}
          date={currentDate}
        />
      </div>
    </motion.div>
  );
}
