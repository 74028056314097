import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { addDoc, collection, onSnapshot, doc } from "firebase/firestore";
import { db } from "../../../firebase-config"; // Adjust your Firebase configuration import

const useCheckout = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const monthly = "price_1PRK8nL0HRQUVolb30GaGoRc";
  const annual = "price_1PUDIQL0HRQUVolbkwBpR9AS";
  const [plan, setPlan] = useState(location.state?.plan || "Monthly");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const currentUserUid = user.uid;
        try {
          const priceId = plan === "Monthly" ? monthly : annual;
          const docRef = await addDoc(
            collection(db, "customers", currentUserUid, "checkout_sessions"),
            {
              price: priceId,
              allow_promotion_codes: true,
              success_url: window.location.origin,
              cancel_url: window.location.origin,
            }
          );

          const unsub = onSnapshot(doc(db, docRef.path), (snap) => {
            const { error, url } = snap.data();
            if (error) {
              console.error(`An error occurred: ${error.message}`);
            }
            if (url) {
              window.location.assign(url);
              unsub();
            }
          });
        } catch (error) {
          console.error("Error adding document: ", error);
        }
      } else {
        console.error("No user is signed in");
      }
    });

    return () => unsubscribe();
  }, [auth, plan]);

  return null;
};

export default useCheckout;
