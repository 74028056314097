import React from "react";
import add_to_calendar from "../../../Images/thumbnails/add mental health connections to your calendar.png";
import populate_health_circles from "../../../Images/thumbnails/populate the health circles.png";
import weekly_self_assessment from "../../../Images/thumbnails/Weeky self-assessment of your mental health.png";
import create_and_update from "../../../Images/thumbnails/thumbnail_Thumbnail Create MHCs.png";
import ReactPlayer from "react-player";
import { motion } from "framer-motion";

const guideSection = [
  {
    title: "Step 1: Weekly Mental Health self-assessment",
    poster: weekly_self_assessment,
    url: "https://vimeo.com/1048669236?share=copy",
    text: "This is the first step in your MHC journey. This assessment is based on the past seven days. First assess your overall mental health over the past seven days by indicating how intentional you were at incorporating each of the 9 Health Circles using a 1 - 10 scale, with 1 being the lowest and 10 being the highest. This assessment will serve as a weekly check-in on your mental health and will help you to identify areas to maintain and areas to prioritize more (this data is tracked and viewable by selecting 'My Data' from the menu).  After completing Step 1, click on 'Next' to move to Step 2.",
  },
  // Mental health is a state of mental well-being that enables people to cope with the stresses of life, realize their abilities, learn well and work well, and contribute to their community (World Health Organization).
  {
    title: "Step 2: Add Items to your Health Circles",
    poster: populate_health_circles,
    url: "https://vimeo.com/1048669263?share=copy",
    text: "In this step you are able to add items to any of the Health Circles. These items are activities that you currently do, or are planning to do. Use the 'Definitions' and 'Examples' provided to help generate ideas for the items you place in each Health Circle, and remember that it is possible that some items may fit in more than one Health Circle - this is fine.  After completing Step 2, click on 'Next' to move to Step 3.",
  },
  {
    title: "Step 3: Create Mental Health Connections",
    poster: create_and_update,
    url: "https://vimeo.com/1048669136?share=copy",
    text: "Based on the items added to your Health Circles in the previous step, in this step you get to create Mental Health Connections. A connection is created by combining items from different Health Circles that can 'fit together' into one activity...thus creating a Mental Health Connection where one event connects multiple positive factors to your mental health. An example of a Mental Health Connection could be: meeting with a friend (Social Interaction), to go for a walk (Movement / Activity), by the lake (Outdoors / Nature), and a picnic (Healthy Nutrition & Snacks), and planning what show / concert you want to see in a few weeks (Planning / Looking Forward To).  After completing Step 3, click on 'Next' to move to Step 4.",
  },
  {
    title: "Step 4: Manage Mental Health Connections",
    poster: add_to_calendar,
    url: "https://vimeo.com/1048669166?share=copy",
    text: "In this step you will manage the Mental Health Connections created in the previous step by: adding them to your calendar, sending invites to others to join your Mental Health Connection, revising / updating Mental Health Connections, and indicating which Mental Health Connections you have completed and / or want to schedule again.  The tracking bar at the bottom of the Dashboard screen shows your progress towards achievement milestones.",
  },
];

export default function GuidePage() {
  return (
    <motion.div
      className='relative isolate mt-4 '
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <main className='relative isolate mx-2 sm:mx-2 lg:mx-10 px-2 sm:px-4  lg:overflow-visible -z-10'>
        <h1 className='text-3xl font-semibold tracking-tight text-dark-text sm:text-5xl'>
          {" "}
          Video Tutorials
        </h1>
        <div
          className='absolute left-0 top-0 -z-20 transform-gpu overflow-hidden blur-3xl sm:top-0'
          aria-hidden='true'
        >
          <div
            className='relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-primary-light to-primary opacity-30 sm:left-4 sm:w-[72.1875rem]'
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div
          className='absolute left-0 bottom-0 -z-20 transform-gpu overflow-hidden blur-3xl sm:bottom-0'
          aria-hidden='true'
        >
          <div
            className='aspect-[1097/845] w-[68.5625rem] bg-gradient-to-r from-primary-light to-primary opacity-25'
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>

        <div className='list-none'>
          {guideSection.map((item) => (
            <li
              key={item.title}
              className='gap-y-2 bg-light-text border-dark-text border-2 rounded-md p-1 m-1 mb-4 sm:p-5 sm:m-5 '
            >
              <h2 className='text-xl xl:text-2xl 2xl:text-3xl p-4 sm:p-0 font-semibold leading-6 text-dark-text'>
                {item.title}
              </h2>
              <div className='grid grid-cols-1 xl:grid-cols-2 align-middle'>
                <div className='flex gap-x-4'>
                  <div className='min-w-0 flex-auto'>
                    <p className='text-md xl:text-lg max-w-6xl p-6 leading-6 text-dark-text'>
                      {item.text}
                    </p>
                  </div>
                </div>
                <div className='flex justify-center items-center w-full h-80'>
                  <div className='w-full h-full gap-x-1.5 z-20'>
                    <ReactPlayer
                      url={item.url}
                      controls
                      width='100%'
                      height='100%'
                      className='min-h-80'
                    />
                  </div>
                </div>
              </div>
            </li>
          ))}
        </div>
        <h1 className='text-3xl font-semibold text-gray-800'>Shortcuts</h1>

        <div className='grid sm:grid-cols-1 md:grid-cols-2 gap-4'>
          <div className='bg-light-text border-dark-text border-2 rounded-md p-1 m-1 mb-4 sm:p-5 sm:m-5'>
            <h2 className='text-xl xl:text-2xl 2xl:text-3xl p-4 sm:p-0 font-semibold leading-6 text-dark-text'>
              ISO shortcut
            </h2>
            <ul className='m-4'>
              <li>1: Go the the Mental Health Connections login page</li>
              <li>2: Select the '' icon</li>
              <li>3: Scroll down to 'Add to Home Screen'</li>
              <li>4: Tap 'Add' in the top left corner</li>
            </ul>
            <h3>
              Congratulations - you have now created a Shortcut for Mental
              Health Connections!!
            </h3>
          </div>
          <div className='bg-light-text border-dark-text border-2 rounded-md p-1 m-1 mb-4 sm:p-5 sm:m-5'>
            <h2 className='text-xl xl:text-2xl 2xl:text-3xl p-4 sm:p-0 font-semibold leading-6 text-dark-text'>
              Android shortcut
            </h2>
            <ul className='m-4'>
              <li>1: Go the the Mental Health Connections login page</li>
              <li>2: Select the '' icon</li>
              <li>3: Scroll down to 'Add to Home Screen'</li>
              <li>
                4: Then in the popup tap 'Add' and then in the next popup tap
                'Add" again
              </li>
            </ul>
            <h3>
              Congratulations - you have now created a Shortcut for Mental
              Health Connections!!
            </h3>
          </div>
        </div>
      </main>
    </motion.div>
  );
}
