import React, { useRef, useState, useEffect } from "react";
import ServerConnectionTest from "../../../Services/Testconnection";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import copy from "copy-to-clipboard";
import {
  ClipboardDocumentCheckIcon,
  ClipboardDocumentIcon,
} from "@heroicons/react/24/outline";
import axios from "axios";
import PropTypes from "prop-types";

export default function CalendarSubscriptions(props) {
  CalendarSubscriptions.propTypes = {
    userID: PropTypes.string.isRequired,
  };

  const textRef = useRef();
  const [calendarUrlCopy, setCalendarUrlCopy] = useState(false);
  const [data, setData] = useState(null);
  const [calendarUrl, setCalendarUrl] = useState(null);

  useEffect(() => {
    // Check if userID is available before making the request
    if (!props.userID) {
      return;
    }

    const updateCalendarUrl = async () => {
      axios
        .get("https://app.byamour.ca/")
        .then((response) => {
          setData(response.data);

          const userData = {
            username: props.userID,
          };
          console.log("Posting data:", userData);
          // Make a POST request to the server to register the user
          // TODO add a more secure way to handle passwords
          const registerData = {
            username: userData.username,
            password: "password",
          };
          axios
            .post("https://app.byamour.ca/register", registerData)
            .then((response) => {
              console.log("User registered successfully:", response.data);
            })
            .catch((error) => {
              console.error("Error registering user:", error);
            });

          axios
            .post("https://app.byamour.ca/getUser", userData)
            .then((response) => {
              try {
                const data = response.data;
                console.log("User registered successfully:", data);
                setCalendarUrl(
                  "webcal://app.byamour.ca/calendar.ics/" + props.userID
                );
              } catch (e) {
                console.error("Failed to parse response as JSON:", e);
              }
            })
            .catch((error) => {
              if (error.response) {
                console.error("Server error:", error.response.status);
                console.error("Response data:", error.response.data);
                if (error.response.status === 409) {
                  console.error("User already exists");
                  setCalendarUrl(
                    "webcal://app.byamour.ca/calendar.ics/" + props.userID
                  );
                }
              } else if (error.request) {
                console.error("No response received:", error.request);
              } else {
                console.error("Error setting up request:", error.message);
              }
            });

          console.log("Response from server:", response.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };

    updateCalendarUrl();
  }, [props.userID]); // Dependency array includes userID to trigger when it's available

  const handleUser = async (input) => {
    try {
      // Making a POST request to the Express server
      const postData = { username: input };
      console.log("Posting data:", postData);

      // Wait for the response from the server
      const response = await axios.post(
        "https://app.byamour.ca/getUser",
        postData
      );

      // Log and return the user ID
      console.log("Response from server:", response.data.id);
      return response.data.id;
    } catch (error) {
      // Log the error and optionally rethrow it
      console.error("Error posting data:", error);
      throw error;
    }
  };

  const handleDownload = async () => {
    try {
      const response = await axios.get(
        "https://app.byamour.ca/calendar.ics/" + props.userID,
        {
          responseType: "blob",
        }
      );
      const url = URL.createObjectURL(
        new Blob([response.data], { type: "text/calendar" })
      );
      const a = document.createElement("a");
      a.href = url;
      a.download = "calendar.ics";
      a.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading iCal:", error);
    }
  };

  const copyToClipboard = () => {
    let copyText = textRef.current.value;
    let isCopy = copy(copyText);
    if (isCopy) {
      toast.success("Copied to Clipboard");
      setCalendarUrlCopy(true);
    }
  };
  return (
    <div className='w-11/12 md:w-5/6 xl:w-3/4 mx-auto min-h-96 p-2 bg-light-text border-dark-text border-2 rounded-md '>
      <ToastContainer
        position='bottom-center'
        autoClose={500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
      <h1 className='text-2xl font-semibold m-4'>Subscribe to your events</h1>

      <div className='m-4 divide-y-2'>
        <div className='my-2'>
          <h3 className='text-lg pb-4 font-semibold leading-6 text-dark-text'>
            Subscribe using your preferred calendar application
          </h3>

          <ServerConnectionTest calendarLink={calendarUrl} />
          <p>
            Different calendar platforms update on different frequencies, so it
            may take time for the new event to show in your calendar.
          </p>

          <div>
            <h3 className='text-lg mt-4 pb-4 font-semibold leading-6 text-dark-text'>
              How to manually add the Subscription link in your calendar
              application
            </h3>
            <div className='flex bg-slate-200 rounded-lg'>
              <input
                type='text'
                disabled
                className='w-11/12 p-2 border-0 bg-transparent text-dark-text'
                value={calendarUrl}
                ref={textRef}
              />
              <button onClick={copyToClipboard}>
                {calendarUrlCopy ? (
                  <ClipboardDocumentCheckIcon className='h-8 w-auto p-1 text-light-text bg-primary rounded-lg ' />
                ) : (
                  <ClipboardDocumentIcon className='h-6 w-auto ' />
                )}
              </button>
            </div>

            <ul className='mx-8 mt-4'>
              <li>
                <h4 className='font-semibold'>Google Calendar:</h4>
                <ul className='list-disc ml-8'>
                  <li>Open Google Calendar</li>
                  <li>Click on the + sign next to 'Other Calendars'</li>
                  <li>Select 'From URL'</li>
                  <li>Paste the subscription link in the URL field</li>
                  <li>Click 'Add Calendar'</li>
                </ul>
              </li>
              <li className='mt-4'>
                <h4 className='font-semibold'>Apple Calendar:</h4>
                <ul className='list-disc ml-8'>
                  <li>Open Apple Calendar</li>
                  <li>Click on 'File' in the menu bar</li>
                  <li>Select 'New Calendar Subscription'</li>
                  <li>Paste the subscription link in the URL field</li>
                  <li>Click 'Subscribe'</li>
                </ul>
              </li>
              <li className='mt-4'>
                <h4 className='font-semibold'>Outlook:</h4>
                <ul className='list-disc ml-8'>
                  <li>Open Outlook Calendar</li>
                  <li>Click on 'Add Calendar' in the menu bar</li>
                  <li>Select 'From Internet'</li>
                  <li>Paste the subscription link in the URL field</li>
                  <li>Click 'Add'</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div className=''>
          <h3 className='text-lg mt-4 pb-4 font-semibold leading-6 text-dark-text'>
            Download a snapshot of your calendar
          </h3>
          <button
            className='p-2 rounded-lg bg-primary text-light-text hover:bg-primary-light hover:text-dark-text max-h-10'
            onClick={handleDownload}
          >
            Download Calendar
          </button>
        </div>
      </div>
    </div>
  );
}
