//import { GoogleAuthProvider } from "firebase/auth";
import React, { useState, Fragment, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { object, string } from "yup";
import MentalHealthConnectionsLogoGreen from "../../Images/sideLogoBlack2.svg";
import mountain from "../../Images/mountain1.jpg";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth, signInWithGooglePopup, db } from "../../firebase-config";
import { Dialog, Transition } from "@headlessui/react";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
  setDoc,
  onSnapshot,
  limit,
  Timestamp,
} from "firebase/firestore";
import CheckStripePayment from "../../Components/User/GetStripeStatus";

export default function LoginPage() {
  const navigation = useNavigate();
  const [emailError, setEmailError] = useState("");
  const [resetPassword, setResetPassword] = useState(false);
  const cancelButtonRef = useRef(null);
  const [email, setEmail] = useState("");

  const updatePassword = async () => {
    setResetPassword(false);
    // sendPasswordResetEmail(auth, email)
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      console.error("Error sending reset email:", error);
    }
  };

  const loginGoogleUser = async () => {
    const response = await signInWithGooglePopup();
    navigation("/Dashboard");
  };

  async function getOverallMentalHealth(userDocID) {
    if (userDocID) {
      const userID = userDocID;
      // get the user information from the database
      const docRef = doc(db, "User", userID);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        // check for collections in the user document
        // if there are no collections, then we need to create them
        // if there are collections, then we need to update them
        const connectionsCollection = collection(
          db,
          "User/" + userID + "/Connections"
        );
        const connections = await getDocs(connectionsCollection, limit(1));
        if (connections.size != 0) {
          console.log("Connections exist");
        } else {
          // create the connections collection
          const connectionsCollection = collection(
            db,
            "User/" + userID + "/Connections"
          );
          // create the connections document
          const connectionsDoc = doc(connectionsCollection, "Connections");
          // set the connections document
          await setDoc(connectionsDoc, { connectionList: [] });
        }

        const personCollection = collection(db, "User/" + userID + "/Person");
        const people = await getDocs(personCollection, limit(1));
        if (people.size != 0) {
          console.log("Persons exist");
        } else {
          console.log("Persons do not exist");
          // create the Person collection

          // create the Person document
          const personDoc = doc(personCollection, "Person");
          // set the person document
          await setDoc(personDoc, { personList: [] });
        }

        const calendarCollection = collection(
          db,
          "User/" + userID + "/Calendar"
        );
        const calendars = await getDocs(calendarCollection, limit(1));
        if (calendars.size != 0) {
          console.log("Calendar exists");
        } else {
          console.log("Calendar does not exist");

          // create the calendar document
          const calendarDoc = doc(calendarCollection, "Calendar");
          // set the calendar document
          await setDoc(calendarDoc, {
            events: [],
            reminders: [
              { active: false, name: "daily", reminderTime: Timestamp.now() },
              {
                active: false,
                name: "weekly",
                reminderTime: Timestamp.now(),
              },
            ],
          });
        }

        const itemsCollection = collection(db, "User/" + userID + "/Items");
        const items = await getDocs(itemsCollection, limit(1));
        if (items.size != 0) {
          console.log("Items exist");
        } else {
          // create the Items collection

          // create the Items document
          const healthy_nutrition_snacks = doc(
            itemsCollection,
            "healthy_nutrition_snacks"
          );
          const hobbies = doc(itemsCollection, "hobbies");
          const laughter_makes_you_smile = doc(
            itemsCollection,
            "laughter_makes_you_smile"
          );
          const movement_activity = doc(itemsCollection, "movement_activity");
          const outdoors_nature = doc(itemsCollection, "outdoors_nature");
          const planning_looking_forward_to = doc(
            itemsCollection,
            "planning_looking_forward_to"
          );
          const self_care = doc(itemsCollection, "self_care");
          const sleep_recovery = doc(itemsCollection, "sleep_recovery");
          const social_interaction = doc(itemsCollection, "social_interaction");
          // set the Items document
          await setDoc(healthy_nutrition_snacks, { section_list: [] });
          await setDoc(hobbies, { section_list: [] });
          await setDoc(laughter_makes_you_smile, { section_list: [] });
          await setDoc(movement_activity, { section_list: [] });
          await setDoc(outdoors_nature, { section_list: [] });
          await setDoc(planning_looking_forward_to, { section_list: [] });
          await setDoc(self_care, { section_list: [] });
          await setDoc(sleep_recovery, { section_list: [] });
          await setDoc(social_interaction, { section_list: [] });
        }

        const assessmentsCollection = collection(
          db,
          "User/" + userID + "/Assessments"
        );
        if (assessmentsCollection) {
          console.log("Assessments exist");
        } else {
          console.log("Assessments do not exist");
          // create the Assessments collection
          //makeNewForm();
          // create the Assessments document
        }

        // create the stats collections if it does not already exist
        const statsCollection = collection(db, "User/" + userID + "/Stats");
        const stats = await getDocs(statsCollection, limit(1));
        console.log(statsCollection);
        if (stats.size != 0) {
          console.log("Stats exist");
        } else {
          console.log("Stats do not exist");
          // create the Assessments collection

          const statsDoc = doc(statsCollection, "Stats");
          const sections = {
            healthy_nutrition_snacks: 0,
            hobbies: 0,
            laughter_makes_you_smile: 0,
            movement_activity: 0,
            outdoors_nature: 0,
            planning_looking_forward_to: 0,
            self_care: 0,
            sleep_recovery: 0,
            social_interaction: 0,
          };
          const total_completed = 0;
          const monthly_completed = 0;

          // create the Stats document
          await setDoc(statsDoc, {
            sections,
            total_completed,
            monthly_completed,
          });
        }
      }
    }
  }

  return (
    <>
      <Transition.Root show={resetPassword} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setResetPassword}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Reset Your Password
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Enter your email address below and we'll send you a
                          link with instructions on how to recover your
                          password.
                        </p>
                        <input
                          className="rounded-lg m-4 hover:border-primary focus:border-primary focus:ring-0 focus:ring-primary focus:ring-opacity-50"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white hover:text-dark-text shadow-sm hover:bg-primary-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary sm:col-start-2"
                      onClick={() => updatePassword()}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => setResetPassword(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="flex min-h-full flex-1">
        <div className="flex flex-1 bg-background flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <div>
                <Link to="https://mhc-landing-page.web.app/">
                  <img
                    alt="Mental Health Connections Logo"
                    src={MentalHealthConnectionsLogoGreen}
                    className="h-32 w-auto text-primary"
                  />
                </Link>
              </div>
              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-dark-text">
                Sign in to your account
              </h2>
              <p className="mt-2 text-sm leading-6 text-dark-text">
                Not a member?{" "}
                <Link
                  to="/CreateAccount"
                  state={{ plan: "" }}
                  className="font-semibold text-primary "
                >
                  Start a 10 day free trial
                </Link>
              </p>
            </div>

            <div className="mt-10">
              <div>
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={object({
                    email: string()
                      .email("Invalid email address")
                      .required("Required"),
                    password: string().required("Required"),
                  })}
                  onSubmit={(values) => {
                    signInWithEmailAndPassword(
                      auth,
                      values.email,
                      values.password
                    )
                      .then((userCredential) => {
                        // Signed in
                        const user = userCredential.user;
                        const collectionRef = collection(db, "User");
                        const q = query(
                          collectionRef,
                          where("uuid", "==", user.uid)
                        );
                        let userDocID = "";

                        onSnapshot(q, async (querySnapshot) => {
                          const user = [];
                          // TODO - add error handling
                          querySnapshot.forEach((doc) => {
                            user.push(doc.data());
                            userDocID = doc.id;
                          });
                          getOverallMentalHealth(userDocID);
                        });

                        // get the stripe status
                        CheckStripePayment(values.email);

                        navigation("/Dashboard", {
                          section: "",
                        });
                        // ...
                      })
                      .catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        setEmailError("Invalid email or password");
                        console.log(errorCode);
                        console.log(errorMessage);
                      });
                  }}
                >
                  <Form action="#" method="POST" className="space-y-6">
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-md font-medium leading-6 text-dark-text"
                      >
                        Email address
                      </label>
                      <div className="mt-2">
                        <Field
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          className="block w-full text-dark-text bg-background rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-light-text focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                        />
                      </div>
                      {emailError !== "" ? (
                        <p className="text-red-500 text-sm">{emailError}</p>
                      ) : null}
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="password"
                        className="block text-md font-medium leading-6 text-dark-text"
                      >
                        Password
                      </label>
                      <div className="mt-2">
                        <Field
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          className="block w-full text-dark-text bg-background rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-light-text focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                        />
                      </div>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>

                    <div className="flex items-center justify-between">
                      {/* <div className='flex items-center'>
                        <input
                          id='remember-me'
                          name='remember-me'
                          type='checkbox'
                          className='h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary-light'
                        />
                        <label
                          htmlFor='remember-me'
                          className='ml-3 block text-sm leading-6 text-dark-text'
                        >
                          Remember me
                        </label>
                      </div> */}

                      <div className="text-sm leading-6">
                        <button
                          href="#"
                          type="button"
                          onClick={() => setResetPassword(true)}
                          className="font-semibold text-primary"
                        >
                          Forgot password?
                        </button>
                      </div>
                    </div>

                    <div>
                      {/* <Link to="/Dashboard"> */}
                      <button
                        type="submit"
                        id="loginButton"
                        className="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-background shadow-sm hover:bg-primary-light hover:text-dark-text focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Sign in
                      </button>
                      {/* </Link> */}
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={mountain}
            alt=""
          />
        </div>
      </div>
    </>
  );
}
