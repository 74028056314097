import { db } from "../../firebase-config";
import { doc, getDoc, updateDoc } from "firebase/firestore";

// returns a list of all the users connections
export default async function getConnectionList(userID) {
  const docRef = doc(db, "User/" + userID + "/Connections", "Connections");
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    var connectionInfo = [];
    if (docSnap.data().connectionList === undefined) {
      await updateDoc(docRef, { connectionList: [] });
    } else {
      connectionInfo = docSnap.data().connectionList;
    }
    return connectionInfo;
  }
}
