const axios = require("axios");

export default async function addEvent(eventData, user) {
  console.log("add events to the backend", eventData.name, user);
  // if the event start date is 1970-01-01, it should be ignored
  if (eventData.startTime.seconds === 0) {
    console.log("Event start date is 1970-01-01, ignoring");
    return;
  }

  let start = new Date(eventData.startTime.seconds * 1000);
  let end = new Date(eventData.endTime.seconds * 1000);

  try {
    const response = await axios.post("https://app.byamour.ca/addEvents", {
      userId: user,
      start: start.toISOString().slice(0, 19).replace("T", " "),
      end: end.toISOString().slice(0, 19).replace("T", " "),
      description: eventData.name,
      location: eventData.location,
    });
    console.log("Event added successfully:", response.data);
  } catch (error) {
    console.error("Error adding event:", error.message);
  }
}
