import { db } from "../../firebase-config";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import CreateConnection from "./CreateConnection";

// TODO make a check for missing next_activation also update CompleteConnection
export default async function UpdateDateConnections(userID, connectionName) {
  console.log("updating date" + connectionName + " " + userID);
  const docRef = doc(db, "User/" + userID + "/Connections", "Connections");
  const docSnap = await getDoc(docRef);
  var cList = [];
  if (docSnap.exists()) {
    cList = docSnap.data().connectionList;
  }

  // create a check for events that don't have a connection
  var connectionExists = false;
  for (let i = 0; i < cList.length; i++) {
    const element = cList[i];
    if (element.name == connectionName) {
      connectionExists = true;
      element.count = element.count + 1;
      break;
    }
  }
  if (!connectionExists) {
    console.log("connection not found");
    await CreateConnection(userID, connectionName, "");
    UpdateDateConnections(userID, connectionName);
  }
  // update connection list in firestore
  await updateDoc(docRef, { connectionList: cList });
  return cList;
}
