import React, { Fragment, useState, useEffect } from "react";
//import { Carousel } from "react-responsive-carousel";
import Carousel from "./Components/carousel/Carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Dialog, Transition } from "@headlessui/react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { BsChevronDown, BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import {
  updateDoc,
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";
import { db, auth } from "../../firebase-config";
import categories from "../../Pages/Dashboard/Components/common/healthCircleDef";
import populate_health_circles from "../../Images/thumbnails/populate the health circles.png";
import { ClipboardDocumentListIcon } from "@heroicons/react/24/outline";
import ReactPlayer from "react-player";
import { motion } from "framer-motion";
import { set } from "date-fns";

export default function AddItems(props) {
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [showExample, setShowExample] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const [currentItem, setCurrentItem] = useState({});
  const [userID, setUserID] = useState("");
  const [payment, setPayment] = useState(false);
  const [openTutorial, setOpenTutorial] = useState(false);

  const name = [
    "Movement / Activity",
    "Social Interaction",
    "Sleep / Recovery",
    "Healthy Nutrition & Snacks",
    "Laughter / Makes You Smile",
    "Hobbies",
    "Self-Care",
    "Outdoors / Nature",
    "Planning / Looking Forward To",
  ];

  useEffect(() => {
    async function getItems() {
      if (props.userID != "") {
        const collectionRef = collection(db, "User/" + props.userID + "/Items");
        const snapshot = await getDocs(collectionRef);
        const dict = {};
        snapshot.docs.forEach((doc) => {
          dict[doc.id] = doc.data();
        });
        setCurrentItem(dict);

        setShow(true);
      }
    }
    async function initItems(userID) {
      if (userID !== "") {
        const collectionRef = collection(db, "User/" + userID + "/Items");
        const snapshot = await getDocs(collectionRef);
        const dict = {};
        snapshot.docs.forEach((doc) => {
          dict[doc.id] = doc.data();
        });
        setCurrentItem(dict);
        setShow(true);
      }
    }
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userQ = query(
          collection(db, "User"),
          where("uuid", "==", user.uid)
        );
        onSnapshot(userQ, (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            setUserID(doc.id);
            setPayment(doc.data().paid);
            initItems(doc.id);
          });
        });
      } else {
        console.log("No user is signed in");
      }
    });
    getItems();
  }, []);

  const toggleVisibility = () => {
    setShowExample(!showExample);
  };

  return show ? (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <Transition.Root show={open} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6'>
                  <div>
                    <div className='mt-3 text-center sm:mt-5'>
                      <h1 className='pt-0 p-4 text-xl'>
                        Item added to Health Circle
                      </h1>
                      <button
                        className='inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:text-dark-text hover:bg-primary-light'
                        onClick={() => setOpen(false)}
                      >
                        close
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {props.page === "FormDashboard" ? (
        <div className='flex justify-between'>
          <Link
            to='/dashboard'
            state={{
              section: "Self Assessment",
              formSection: "Overall Mental Health",
            }}
            className='p-2 mt-2 hover:bg-primary-light hover:text-dark-text max-h-10 bg-primary min-w-button text-center text-light-text font-semibold rounded-lg px-4 py-2 m-4 items-start'
          >
            Back
          </Link>
          <Link
            to='/dashboard'
            state={{
              section: "Self Assessment",
              formSection: "Create Connections",
            }}
            className='p-2 mt-2 hover:bg-primary-light hover:text-dark-text max-h-10 bg-primary min-w-button text-center text-light-text font-semibold rounded-lg px-4 py-2 m-4 items-start'
          >
            Next
          </Link>
        </div>
      ) : (
        <div className='flex flex-col items-end'>
          <Link
            to='/dashboard'
            state={{
              section: "Create MH Connections",
            }}
            className='p-2 mt-2 hover:bg-primary-light hover:text-dark-text max-h-10 bg-primary min-w-button text-center text-light-text font-semibold rounded-lg px-4 py-2 m-4 items-start'
          >
            Next
          </Link>
        </div>
      )}
      <div className='bg-light-text border-dark-text border-2 rounded-md p-1 m-1 sm:p-5 sm:m-5'>
        <div className='flex justify-start '>
          <ClipboardDocumentListIcon className='max-h-32 min-w-32 max-w-48 hidden sm:inline text-primary' />
          <div className='w-full m-2 sm:m-4'>
            <h1 className='text-2xl font-semibold'>
              Step 2: Add items to your Health Circles
            </h1>
            <p className='m-2 sm:mx-6 w-fit'>
              For each of the Health Circles use the 'Add item' box to add an
              item that you do, or can do, to positively impact your health. If
              you need help thinking of items then you can click on the
              'Examples' button to see some examples. Previously added items can
              be searched for in the 'Current items' box if you are unsure if
              you have already added an item. To move on to the next Health
              Circle to add items you can click on the big arrows on the side of
              the box.
            </p>
          </div>
        </div>
        {props.tutorialMode ? (
          <div>
            <div className='mt-3 text-center sm:mt-5'>
              <div className='flex justify-center items-center w-full h-80'>
                <div className='w-full h-full gap-x-1.5'>
                  <ReactPlayer
                    url='https://vimeo.com/970933539'
                    controls
                    width='100%'
                    height='100%'
                    className='min-h-80'
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div>
        <Carousel currentItem={currentItem} userId={userID} payment={payment} />
      </div>
      <div className=''>
        {/* <Carousel
          className='w-full sm:w-3/4 mx-auto rounded-2xl bg-light-text'
          showStatus={false}
          showIndicators={true}
          showArrows={true}
          showThumbs={false}
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <button
                type='button'
                onClick={onClickHandler}
                title={label}
                className='absolute right-0 top-0 text-2xl h-3/4 w-1/6 text-primary'
              >
                <span className='sr-only'>Next</span>
                <BsChevronRight className='h-auto w-full hover:text-dark-text' />
              </button>
            )
          }
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <button
                type='button'
                onClick={onClickHandler}
                title={label}
                className='absolute z-10 left-0 top-0 text-2xl h-3/4 w-1/6 text-primary'
              >
                <span className='sr-only'>Previous</span>
                <BsChevronLeft className='h-auto w-full hover:text-dark-text' />
              </button>
            )
          }
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            const defStyle = {
              color: "white",
              cursor: "pointer",
              margin: "5px",
              padding: "2px",
              borderRadius: "5%",
            };
            const style = isSelected
              ? {
                  ...defStyle,
                  color: "green",
                }
              : {
                  ...defStyle,
                  color: "black",
                };
            return (
              <span
                className='text-lg m-2'
                style={style}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role='button'
                tabIndex={0}
                aria-label={`${label} ${index + 1}`}
              >
                {name[index] + " "}
              </span>
            );
          }}
        >
          {categories.map((category) => {
            return (
              <div
                key={category.name}
                className=' grid w-3/4 mx-auto  grid-cols-1'
              >
                <div className='grid grid-cols-3'>
                  <div className='col-span-3'>
                    <div className='content-center flex justify-center items-center'>
                      <category.iconName className=' h-14 w-14 text-dark-text' />
                      <h1 className='text-2xl w-3/4 m-4 place-content-center font-bold text-dark-text'>
                        {category.name}
                      </h1>
                      <div>
                        <img
                          src={category.nonagonMap}
                          alt={category.name}
                          className='h-1/3 w-auto float-right pt-4'
                        />
                      </div>
                    </div>
                    <p className='m-2 ml-10 mr-6 text-md float-left text-start '>
                      {category.text}
                    </p>
                    {category.name === "Sleep / Recovery" ? (
                      <p className='m-2 ml-10 mr-6 text-md float-left text-start '>
                        {category.text2}
                      </p>
                    ) : null}
                    <button
                      onClick={toggleVisibility}
                      className='m-2 ml-10 mb-10 text-primary font-semibold text-start flex'
                    >
                      Examples
                      <BsChevronDown className=' mt-1 ml-1' />
                    </button>
                  </div>

                  {showExample ? (
                    <div className='col-span-3'>
                      <p className='col-span-3 text-md -mt-10 mb-16 ml-14 mr-6 text-start'>
                        {category.example}
                      </p>
                    </div>
                  ) : null}
                  {showExample && category.name === "Sleep / Recovery" ? (
                    <div className='col-span-3'>
                      <p className='col-span-3 text-md -mt-10 mb-16 ml-14 mr-6 text-start'>
                        {category.example2}
                      </p>
                    </div>
                  ) : null}
                </div>
                <div className='grid grid-cols-1 sm:grid-cols-2 border-2 border-dark-text mb-40 sm:mb-36 rounded-lg mx-6'>
                  <Formik
                    initialValues={{
                      item: "",
                    }}
                    validate={(values) => {
                      const errors = {};
                      if (!values.item) {
                        errors.item = "Required";
                        console.log("error");
                      } else if (
                        currentItem[category.id].section_list.includes(
                          values.item
                        )
                      ) {
                        errors.item = "Item already added";
                        console.log("error");
                        console.log(values.item);
                      }
                      return errors;
                    }}
                    onSubmit={(value, { resetForm, setFieldError }) => {
                      const newItems = currentItem[category.id].section_list;
                      newItems.push(value.item);
                      setCurrentItem({
                        ...currentItem,
                        [category.id]: {
                          ...currentItem[category.id],
                          section_list: [
                            ...currentItem[category.id].section_list,
                          ],
                        },
                      });
                      console.log(currentItem[category.id]);

                      updateDoc(
                        doc(db, "User/" + props.userID + "/Items", category.id),
                        { section_list: newItems }
                      );
                      setOpen(true);
                      setSearchItem("");
                      resetForm();
                    }}
                  >
                    {({ handleSubmit }) => (
                      <Form
                        className='sm:border-r-2 border-dark-text justify-start items-center'
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleSubmit();
                          }
                        }}
                      >
                        <h3 className='m-2 text-lg font-semibold'>Add Item</h3>
                        <Field
                          id={"add_item_" + category.id}
                          name='item'
                          type='text'
                          placeholder='Enter New Item'
                          className='rounded-2xl m-4 w-3/4 bg-light-text'
                        />
                        <ErrorMessage
                          name='item'
                          component='div'
                          className='text-red-500'
                        />

                        <button
                          type='submit'
                          id={"add_item_button_" + category.id}
                          className='rounded-2xl bg-primary w-3/4 p-3 text-light-text hover:bg-primary-light hover:text-dark-text  text-center'
                        >
                          Add Item
                        </button>
                      </Form>
                    )}
                  </Formik>
                  <div className='m-2 border-t-2 sm:border-t-0 border-dark-text'>
                    <h3 className='text-lg p-2 font-semibold'>Current Items</h3>
                    <input
                      type='text'
                      name='search-items'
                      placeholder='Search Items'
                      className='rounded-2xl m-4 w-3/4 bg-light-text'
                      onChange={(e) => setSearchItem(e.target.value)}
                    ></input>
                    <ul className='max-h-20 min-h-10 overflow-y-auto border-2 border-dark-text rounded-md divide-y divide-dark-text divide-solid'>
                      {currentItem[category.id].section_list.map(
                        (item, index) => {
                          //console.log(currentItem[category.id].section_list);

                          if (item.includes(searchItem)) {
                            return (
                              <li key={index} className=''>
                                {item}
                              </li>
                            );
                          }
                          return null; // Return null for items that don't meet the conditions
                        }
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel> */}
      </div>
      {props.page === "FormDashboard" ? (
        <div className='h-12'>
          <Link
            to='/dashboard'
            state={{
              section: "Self Assessment",
              formSection: "Overall Mental Health",
            }}
            className=' bg-primary min-w-button text-center float-left text-light-text font-semibold rounded-lg px-4 py-2 m-4 hover:bg-primary-light hover:text-dark-text max-h-10'
          >
            Back
          </Link>
          <Link
            to='/dashboard'
            state={{
              section: "Self Assessment",
              formSection: "Create Connections",
            }}
            className='bg-primary min-w-button text-center float-right text-light-text font-semibold rounded-lg px-4 py-2 m-4 hover:bg-primary-light hover:text-dark-text max-h-10'
          >
            Next
          </Link>
        </div>
      ) : (
        <div className='flex flex-col items-end'>
          <Link
            to='/dashboard'
            state={{
              section: "Create MH Connections",
            }}
            className=' hover:bg-primary-light hover:text-dark-text max-h-10 bg-primary min-w-button text-center items-end text-light-text font-semibold rounded-lg px-4 py-2 m-4'
          >
            Next
          </Link>
        </div>
      )}
    </motion.div>
  ) : null;
}
