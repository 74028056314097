import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Transition,
  TransitionChild,
  DialogTitle,
} from "@headlessui/react";
import { CheckIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useState, useEffect, useRef } from "react";
import DeleteEvent from "../../../../Components/Event/DeleteEvent";

export default function ConfirmPopup({
  show: initialOpen,
  onClose,
  message,
  eventInfo,
}) {
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    setOpen(initialOpen);
  }, [initialOpen]);

  // Close the popup with out deleting the event
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleConfirm = async () => {
    if (handleConfirm) {
      // Call the delete function then close the popup
      const updatedList = await DeleteEvent(eventInfo);
      console.log(updatedList);
      onClose(updatedList);
    }
  };

  return (
    <Transition show={open}>
      <Dialog
        as='div'
        className='relative z-10'
        initialFocus={cancelButtonRef}
        onClose={handleClose}
      >
        <TransitionChild
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </TransitionChild>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full justify-center p-4 text-center items-center lg:pl-60 sm:p-0'>
            <TransitionChild
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <DialogPanel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
                <div>
                  <div className='mt-3 text-center sm:mt-5'>
                    <DialogTitle
                      as='h3'
                      className='text-base font-semibold leading-6 text-gray-900'
                    >
                      {message}
                    </DialogTitle>
                    <div className='mt-2 flex flex-row justify-between gap-4'>
                      <button
                        type='button'
                        onClick={() => handleClose()}
                        ref={cancelButtonRef}
                        className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0'
                      >
                        Cancel
                      </button>

                      <button
                        type='button'
                        onClick={() => handleConfirm()}
                        ref={cancelButtonRef}
                        className='inline-flex col-span-2 w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white hover:text-dark-text shadow-sm hover:bg-primary-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-light'
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
