import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Monthdata from "../../../../Components/UserData/Monthdata";
import Yeardata from "../../../../Components/UserData/Yeardata";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    title: {
      display: false,
      text: "Overall Mental Health",
    },
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      min: 0,
      max: 10,
    },
  },
};

export function LineChart(props) {
  const [lineData, setLineData] = useState(null);
  useEffect(() => {
    async function fetchData() {
      var data = null;
      if (props.timePeriod === "month") {
        data = await Monthdata(props.userID, "LineChart", props.selectedMonth);
      }
      if (props.timePeriod === "year") {
        data = await Yeardata(props.userID, "LineChart");
      }

      setLineData(data);
    }
    fetchData();
  }, [props.timePeriod, props.selectedMonth]);

  return <div>{lineData && <Line options={options} data={lineData} />}</div>;
}
