import React from "react";
import categories from "./common/healthCircleDef";
import { motion } from "framer-motion";

export default function Definitions() {
  const health_circle_definitions = categories;
  return (
    <motion.ul
      className='p-1 sm:p-4'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h1 className='text-3xl font-semibold tracking-tight text-dark-text sm:text-5xl'>
        Definitions
      </h1>
      {health_circle_definitions.map((definition) => (
        <li
          key={definition.text}
          className='flex justify-between bg-light-text border-dark-text border-2 rounded-md p-1 m-1 sm:p-5 sm:m-5'
        >
          <div className='flex gap-x-4'>
            <definition.iconName className='flex-none h-6 w-6 text-gray-800' />
            <div className='min-w-0 flex-auto'>
              <p className='text-xl font-semibold leading-6 text-gray-700'>
                {definition.name}
              </p>
              <p className='mt-1 w-full text-md leading-5 text-gray-700'>
                {definition.text}
              </p>

              {/* <p className="mt-1 w-full text-md leading-5 text-gray-700">
                {definition.text2}
              </p> */}
              <p className='mt-2 ml-4  text-md leading-5 text-gray-700'>
                {definition.name === "Sleep / Recovery" ? (
                  <span className='text-primary font-semibold'>Example </span>
                ) : (
                  <span className='text-primary'>Example:</span>
                )}

                {definition.example}
              </p>
              {definition.name === "Sleep / Recovery" ? (
                <p className='mt-2 w-full text-md leading-5 text-gray-700'>
                  {definition.text2}
                </p>
              ) : null}
              <p className='mt-2 ml-4 w-full text-md leading-5 text-gray-700'>
                {definition.name === "Sleep / Recovery" ? (
                  <span className='text-primary font-semibold'>Example </span>
                ) : null}
                {definition.example2}
              </p>
            </div>
          </div>
        </li>
      ))}
    </motion.ul>
  );
}
