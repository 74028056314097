import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import GetTrailStatus from "../../../../Components/User/GetTrailStatus";
import { set } from "date-fns";

// takes if there is a trail period and the day it ends
export default function DaysInTrailBanner() {
  const [showBanner, setShowBanner] = useState(false);
  const [trailEnd, setTrailEnd] = useState(null);
  const [daysLeft, setDaysLeft] = useState(null);

  useEffect(() => {
    const fetchTrailStatus = async () => {
      const endDate = await GetTrailStatus("jonathan@splitroof.ca");

      // there is no trail period
      if (endDate === null) {
        setShowBanner(false);
        return;
      }
      const currentUnixTimestamp = Math.floor(Date.now() / 1000);
      // trail period has ended
      if (endDate < currentUnixTimestamp) {
        setShowBanner(false);
        return;
      }
      // trail period is still active and we can show the banner and convert the unix timestamp to a date and find how many days are left
      const daysLeft = Math.floor((endDate - currentUnixTimestamp) / 86400);
      setDaysLeft(daysLeft);
      const trialEndDate = new Date(endDate * 1000);
      const formattedDate = `${trialEndDate.getUTCFullYear()}-${String(
        trialEndDate.getUTCMonth() + 1
      ).padStart(2, "0")}-${String(trialEndDate.getUTCDate()).padStart(
        2,
        "0"
      )}`;
      setTrailEnd(formattedDate);
      setShowBanner(true);
    };
    fetchTrailStatus();
  }, []);

  return (
    <>
      {showBanner ? (
        <div className='flex items-center gap-x-6 bg-primary px-6 py-2.5 sm:px-3.5 sm:before:flex-1 rounded-xl mb-6'>
          <p className='text-sm leading-6 text-light-text'>
            <Link
              to='/dashboard'
              state={{
                section: "Your Profile",
              }}
            >
              You have {daysLeft} days left in your trial. Your default payment
              method will be charged on the last day of your trial. {trailEnd}
            </Link>
          </p>
          <div className='flex flex-1 justify-end'></div>
        </div>
      ) : null}
    </>
  );
}
