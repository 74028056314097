import React from "react";
import InsideFile from "./insideFile";

export default function Carousel(props) {
  return (
    <div className='w-full md:w-5/6 xl:w-3/4 mx-auto min-h-96 bg-light-text border-dark-text border-2 rounded-md'>
      <InsideFile
        categoryId={0}
        currentItem={props.currentItem}
        userId={props.userId}
        payment={props.payment}
      />
    </div>
  );
}
