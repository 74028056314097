import nonagon_self_care from "../../../../Images/nonagon/nonagon_self_care.svg";
import nonagon_movement_activity from "../../../../Images/nonagon/nonagon_movement_activity.svg";
import nonagon_sleep_recovery from "../../../../Images/nonagon/nonagon_sleep_recovery.svg";
import nonagon_social_interaction from "../../../../Images/nonagon/nonagon_social_interaction.svg";
import nonagon_healthy_nutrition_snacks from "../../../../Images/nonagon/nonagon_healthy_nutrition_snacks.svg";
import nonagon_planning_looking_forward_to from "../../../../Images/nonagon/nonagon_planning_looking_forward_to.svg";
import nonagon_laughter_makes_you_smile from "../../../../Images/nonagon/nonagon_laughter_makes_you_smile.svg";
import nonagon_hobbies from "../../../../Images/nonagon/nonagon_hobbies.svg";
import nonagon_outdoors_nature from "../../../../Images/nonagon/nonagon_outdoors_nature.svg";
import {
  GiSelfLove,
  GiArcheryTarget,
  GiBoatFishing,
  GiCampingTent,
  GiBowlOfRice,
  GiNightSleep,
  GiRunningShoe,
  GiTalk,
} from "react-icons/gi";
import { BiSmile } from "react-icons/bi";

const categories = [
  {
    name: "Movement / Activity",
    id: "movement_activity",
    text: "Engaging in something that gets the body moving, increases heart rate, and has a physical aspect to it.",
    example:
      " walking the dog, jogging / running, gardening / yard work, taking the stairs, walking to the grocery store, parking further from work and walking in, yoga, going to the gym, aqua aerobics, bowling, cycling, hiking, water sports, winter sports, helping a friend move",
    iconName: GiRunningShoe,
    nonagonMap: nonagon_movement_activity,
  },
  {
    name: "Social Interaction",
    id: "social_interaction",
    text: "Any process that involves reciprocal stimulation or responses between two or more individuals, involves both verbal and non-verbal communication, and can include in-person or virtual environments.",
    example:
      " virtual / phone calls with friends / family living elsewhere, meet with friends for an event / activity, coffee breaks with co-workers, participating in a group or club, dinner out with friends, games night, watching sports / shows with friends, community events, church / spiritual events, volunteering",
    iconName: GiTalk,
    nonagonMap: nonagon_social_interaction,
  },
  {
    name: "Sleep / Recovery",
    id: "sleep_recovery",
    text: " A condition of body and mind that typically occurs for several hours every night, in which the nervous system is relatively inactive, the eyes closed, the postural muscles relaxed, and consciousness practically suspended. Benefits of quality sleep include enhanced body energy and repair, improved cognitive function and alertness, and improved mood state.",
    text2:
      " Involves purposefully ceasing from action or motion, refraining from exertion, and providing time and space to be free from anxiety or disturbance.",
    example:
      "Sleep: consistent sleep-wake cycle, following a 20min bedtime routine to unwind, breathing exercises in bed, putting phone on silent and away from the bed, keeping bedroom dark / use eye-shades, ear plugs / white noise, let others know your schedule",
    example2:
      "Recovery: intentionally removing yourself from a busy environment to decompress, doing mindfulness activities and / or deep breathing exercise, taking a cat-nap (30’ish minutes with an alarm), sitting in a comfortable place (inside or outside) and observing your environment calmly in a relaxed state.",
    iconName: GiNightSleep,
    nonagonMap: nonagon_sleep_recovery,
  },
  {
    name: "Healthy Nutrition & Snacks",
    id: "healthy_nutrition_snacks",
    text: "Eating a variety of foods that provide the nutrients you need to maintain your health, feel good, and have energy.",
    example:
      " pack a lunch for work, drink plenty of water, healthier beverage options, more fruits & vegetables for snacks, include salad at every dinner, plan meals for the week and buy the groceries, cook extra healthy food for dinner to later use for lunches, bring a fruit or veggie tray with you to a party / gathering, eat less processed foods, have lots of color on your dinner plate ",
    iconName: GiBowlOfRice,
    nonagonMap: nonagon_healthy_nutrition_snacks,
  },
  {
    name: "Laughter / Makes you Smile",
    id: "laughter_makes_you_smile",
    text: "Anything that you enjoy and makes you happy, smile, laugh, and generally makes you feel good by the positive emotions the experience provides.",
    example:
      " watch a favorite show / movie / series, listen to a music playlist of your favorites, call / meet with a friend, look through old pictures, go to an event / show, videocall with family / friends out of town, play with your pet, talk about your hobbies with someone of similar interests",
    iconName: BiSmile,
    nonagonMap: nonagon_laughter_makes_you_smile,
  },
  {
    name: "Hobbies",
    id: "hobbies",
    text: "Something you enjoy doing in your free time or you make time in your schedule to do it. Is an activity that you enjoy investing your time and energy into, and can help relieve stress, tension, and provide a positive distraction.",
    example:
      " pets, gardening, collecting something, reading, photography, music, woodwork, sewing, cooking / baking, painting, watching something you are passionate about, volunteering, coaching, artistic pursuits, yard-work, mechanics, cars, fishing, poetry",
    iconName: GiBoatFishing,
    nonagonMap: nonagon_hobbies,
  },
  {
    name: "Self-Care",
    id: "self_care",
    text: "The practice of taking an active role in protecting one's well-being and happiness, and can be done proactively or reactively. Includes spiritual, emotional, physical, and mental activities.",
    example:
      " meditation / breathing exercises, yoga, spirituality activities, journaling, massage, unplugging from technology, reading, mindfulness activities, injury rehabilitation exercises, sitting & relaxing, hot tub / bath, sauna, seeking help / talking to someone",
    iconName: GiSelfLove,
    nonagonMap: nonagon_self_care,
  },

  {
    name: "Outdoors / Nature",
    id: "outdoors_nature",
    text: "Being outside and exposed to fresh air, environmental conditions (i.e., different types of weather), different types of natural light (dawn, daylight, dusk, evening), and in different settings (park, lake, beach, forest, mountains, fields, snow, etc).",
    example:
      " hiking / biking on a trail, sitting on a bench with a view, gardening, going to the beach, reading a book in the backyard, going to the park, walking downtown, eating a meal outside, any outdoor sports / activities",
    iconName: GiCampingTent,
    nonagonMap: nonagon_outdoors_nature,
  },
  {
    name: "Planning / Looking Forward To",
    id: "planning_looking_forward_to",
    text: "Having things in your schedule that are either regularly occurring short-term items for your health or events scheduled further in the future that you work towards and anticipate the positive benefits it will provide.",
    example:
      " movie night, camping trip, dinner and a show, hiking group, sport event, craft fair, garden tour, games night, rotate hosting dinners with a group of friends / family, backyard / park / beach / lake gathering, concert, festival, community event",
    iconName: GiArcheryTarget,
    nonagonMap: nonagon_planning_looking_forward_to,
  },
];

export default categories;
