import React, { useState, useEffect } from "react";
import { db } from "../../firebase-config";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";

export default async function CheckStripePayment(userEmail) {
  try {
    var stripeCustomerId = await getStripeCustomerId(userEmail);

    // Make a request to Stripe API to check payment status
    const response = await fetch(
      `https://api.stripe.com/v1/customers/${stripeCustomerId}/subscriptions`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer sk_test_51NnpuDL0HRQUVolbxLoI774OSzUAMzBO85SrwHwuVlMRps9jYwEHGVPYyipXOMPmesZRKs7IPlvOnzfrkHNXcvTr00JeeGMiqV`,
          "Content-Type": "application/json",
        },
      }
    );

    // Parse the response
    const data = await response.json();
    // set the paid status of the user based on the response
    // get the user document from the user collection in firestore
    const userRef = collection(db, "User");
    const userQuery = query(userRef, where("email_username", "==", userEmail));
    const userSnapshot = await getDocs(userQuery);
    const userDoc = doc(db, "User", userSnapshot.docs[0].id);

    if (data.data !== undefined) {
      await updateDoc(userDoc, { paid: data.data.length > 0 });
    }

    // Check if the user has an active subscription
  } catch (error) {
    console.error("Error checking Stripe payment:", error);
    throw error;
  }
}

async function getStripeCustomerId(userEmail) {
  try {
    // Query the customers collection to find the document with the specified email
    const customerQuery = query(
      collection(db, "customers"),
      where("email", "==", userEmail)
    );
    const customerSnapshot = await getDocs(customerQuery);

    if (customerSnapshot.empty) {
      console.log("No matching documents.");
      return null; // or throw an error if you prefer
    }

    // Assuming there should only be one customer with this email
    let stripeCustomerId = null;
    customerSnapshot.forEach((doc) => {
      stripeCustomerId = doc.data().stripeId;
    });

    return stripeCustomerId;
  } catch (error) {
    console.error("Error retrieving customer:", error);
    throw error; // rethrow the error after logging it
  }
}
