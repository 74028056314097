import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import { LineChart } from "./Charts/LineChart";
import PropTypes from "prop-types";

export default function OverallMentalHealthData(props) {
  OverallMentalHealthData.propTypes = {
    userID: PropTypes.string.isRequired,
    docInfo: PropTypes.object.isRequired,
    date: PropTypes.object.isRequired,
  };

  const [timePeriod, setTimePeriod] = useState("year");
  const [selectedMonth, setSelectedMonth] = useState(props.date.getMonth());
  console.log("props.userID: ", props.userID);
  if (!props.userID) {
    return (
      <div>
        <h2>loading data</h2>
      </div>
    );
  }

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <div className='col-span-full bg-light-text p-2 rounded-lg shadow-xl'>
      <Formik
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
        }}
      >
        <Form className='flex w-full flex-col'>
          <div className='flex flex-col sm:flex-row justify-between gap-x-6 py-5 px-2'>
            <h1 className='p-4 truncate text-xl font-medium text-dark-text'>
              Overall Mental Health
              {timePeriod === "year" ? (
                <p className='text-md text-gray-600'>
                  {props.date.getFullYear()}
                </p>
              ) : (
                <p className='text-md text-gray-600'>
                  {monthNames[selectedMonth]} {props.date.getFullYear()}
                </p>
              )}
            </h1>
            <Field
              as='select'
              onChange={(e) => {
                setTimePeriod(e.target.value);
              }}
              name='timePeriod'
              className=' shrink sm:ml-auto mt-4 rounded-md border-1 m-2 text-dark-text focus:ring-2 focus:ring-inset focus:ring-primary sm:text-md'
            >
              <option value='year'>Year</option>
              <option value='month'>Month</option>
            </Field>
          </div>
          {/* information div */}
          <div className='w-full sm:w-3/4 mx-auto'>
            <LineChart
              userID={props.userID}
              docInfo={props.docInfo}
              timePeriod={timePeriod}
              selectedMonth={selectedMonth}
            />
          </div>
          <div>
            {timePeriod === "month" ? (
              <div className='overflow-x-auto'>
                <ul className='flex xl:justify-center mt-4 gap-2 overscroll-contain'>
                  {monthNames.map((month, index) => {
                    return (
                      <li key={month} className=''>
                        <button
                          type='button'
                          name={month}
                          onClick={() => setSelectedMonth(index)}
                          className='p-2 rounded-lg mt-2  bg-primary text-light-text hover:bg-primary-light hover:text-dark-text max-h-10'
                        >
                          {month}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : (
              <div className='h-8' />
            )}
          </div>
        </Form>
      </Formik>
    </div>
  );
}
