import { db } from "../../firebase-config";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import CreateConnection from "./CreateConnection";

// TODO make a check for missing next_activation also update CompleteConnection
export default async function UpdateDateConnections(userID, connectionName) {
  const docRef = doc(db, "User/" + userID + "/Connections", "Connections");
  const docSnap = await getDoc(docRef);
  let connectionsList = [];
  if (docSnap.exists()) {
    connectionsList = docSnap.data().connectionList;
  }

  // create a check for events that don't have a connection
  let connectionExists = false;
  for (let i = 0; i < connectionsList.length; i++) {
    const element = connectionsList[i];
    if (element.name == connectionName) {
      connectionExists = true;
      element.count = element.count + 1;
      break;
    }
  }
  if (!connectionExists) {
    await CreateConnection(userID, connectionName, "");
    UpdateDateConnections(userID, connectionName);
  }
  // update connection list in firestore
  await updateDoc(docRef, { connectionList: connectionsList });
  return connectionsList;
}
