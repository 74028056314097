import { db } from "../../firebase-config";
import { doc, getDoc, updateDoc } from "firebase/firestore";

export default async function DeleteItem(userId, itemName, sectionName) {
  const itemRef = doc(db, "User/" + userId + "/Items", sectionName);
  const itemSnap = await getDoc(itemRef);
  if (itemSnap.exists()) {
    var itemInfo = itemSnap.data().section_list;
    var itemExists = false;
    for (var i = 0; i < itemInfo.length; i++) {
      var item = itemInfo[i];
      if (item === itemName) {
        itemExists = true;
        itemInfo.splice(i, 1);
        break;
      }
    }
    if (itemExists) {
      await updateDoc(itemRef, {
        section_list: itemInfo,
      });
      return itemInfo;
    }
  }
}
