import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "tailwindcss/tailwind.css";
import { BrowserRouter as Router, Routes, Route, Form } from "react-router-dom";

import LoginPage from "./Pages/LoginPage/LoginPage";
import ContactPage from "./Pages/ContactPage/ContactPage";
import CreateAccount from "./Pages/CreateAccount/CreateAccount";
import Dashboard from "./Pages/Dashboard/Dashboard";
import FormDashboard from "./Pages/MentalHealthConnectionForm/FormDashboard";
import MentalHealthConnectionForm from "./Pages/MentalHealthConnectionForm/Step1";
import ProfilePage from "./Pages/ProfilePage/ProfilePage";
import LinksPage from "./Pages/LinksPage/LinksPage";
import FAQPage from "./Pages/FAQPage/FAQPage";
import NewsPage from "./Pages/NewsPage/NewsPage";
import CheckoutContainer from "./Pages/CreateAccount/Components/stripePayments";

import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router onUpdate={() => window.scrollTo(0, 0)}>
    <Routes>
      <Route path='/' element={<App />} />
      <Route path='/ContactPage' element={<ContactPage />} />
      <Route path='/FAQPage' element={<FAQPage />} />
      <Route path='/CreateAccount' element={<CreateAccount />} />
      <Route path='/loginPage' element={<LoginPage />} />
      <Route path='/Dashboard' element={<Dashboard />} />
      <Route path='/FormDashboard' element={<FormDashboard />} />
      <Route
        path='/mentalHealthConnectionForm'
        element={<MentalHealthConnectionForm />}
      />
      <Route path='/profilePage' element={<ProfilePage />} />
      <Route path='/linksPage' element={<LinksPage />} />
      <Route path='*' element={<h1>Not Found</h1>} />
      <Route path='/NewsPage' element={<NewsPage />} />
      <Route path='/BillingPage' element={<CheckoutContainer />} />
    </Routes>
  </Router>
);
