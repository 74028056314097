import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase-config";
import getCurrentUser from "../User/GetCurrentUser";
import { ca } from "date-fns/locale";

export default async function YearData(userID, chartType) {
  // get the weekly data
  const assessmentRef = collection(db, "User/" + userID + "/Assessments");
  //TODO change to a query for the assessments in the last month

  const assessmentSnap = await getDocs(assessmentRef);
  // sort the assessments by date
  var assessmentList = [];
  assessmentSnap.forEach((doc) => {
    assessmentList.push(doc.data());
  });
  assessmentList.sort((a, b) => {
    return a.date - b.date;
  });
  const currentYear = new Date().getFullYear();

  assessmentList = assessmentList.filter((assessment) => {
    const assessmentYear = assessment.date.toDate().getFullYear();

    // Check if the month of the assessment date matches the current month
    return assessmentYear === currentYear;
  });

  // here is where we need to filter by chart type
  if (chartType === "LineChart") {
    return await getLineData(assessmentList);
  }
  if (chartType === "RadarChart") {
    if (assessmentList.length > 0) {
      return await getRadarData(assessmentList);
    }
  }
}

async function getRadarData(assessmentList) {
  const rows = 9; // nine_circles.length;
  const columns = 12; //months in the year;
  var n = 0;
  // Create a 2D array and initialize it with values
  const catagories = Array.from({ length: rows }, () =>
    Array.from({ length: columns }, () => Math.floor(0))
  );
  const count = Array.from({ length: rows }, () =>
    Array.from({ length: columns }, () => Math.floor(0))
  );

  // go through each assessment
  while (assessmentList[n]) {
    // go through each category its a map
    var month = assessmentList[n].date.toDate().getMonth();

    var circles = assessmentList[n].nine_circles;
    var index = 0;
    for (const [key, value] of Object.entries(circles)) {
      if (value === 100) {
        catagories[index][month] += 0;
      } else {
        catagories[index][month] += value;
        count[index][month]++;
      }
      index++;
    }
    n++;
  }
  for (var i = 0; i < rows; i++) {
    for (var j = 0; j < columns; j++) {
      catagories[i][j] /= count[i][j];
    }
  }

  //   while (overallMentalHealth.length < 12) {
  //     overallMentalHealth.push(0);
  //   }

  return {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Self-Care",

        data: catagories[0],
        backgroundColor: "rgba(75,192,192,0.0)",
        borderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "rgba(75,192,192,1)",
        borderWidth: 3,
      },
      {
        label: "Movement / Activity",
        data: catagories[1],
        backgroundColor: "rgba(255,255,71,0.0)",
        borderColor: "rgba(255,255,71,1)",
        pointBackgroundColor: "rgba(255,255,71,1)",
        borderWidth: 3,
      },
      {
        label: "Sleep / Recovery",
        data: catagories[2],
        backgroundColor: "rgba(0,100,71,0.0)",
        borderColor: "rgba(0,100,71,1)",
        pointBackgroundColor: "rgba(0,100,71,1)",
        borderWidth: 3,
      },
      {
        label: "Social Interaction",
        data: catagories[3],
        backgroundColor: "rgba(105,80,152,0.0)",
        borderColor: "rgba(255,99,132,1)",
        pointBackgroundColor: "rgba(255,99,132,1)",
        borderWidth: 3,
      },
      {
        label: "Healthy Nutrition & Snacks",
        data: catagories[4],
        backgroundColor: "rgba(179,3,43,0.0)",
        borderColor: "rgba(179,3,43,1)",
        pointBackgroundColor: "rgba(179,3,43,1)",
        borderWidth: 3,
      },
      {
        label: "Hobbies",
        data: catagories[5],
        backgroundColor: "rgba(98,57,255,0.0)",
        borderColor: "rgba(98,57,255,1)",
        pointBackgroundColor: "rgba(98,57,255,1)",
        borderWidth: 3,
      },
      {
        label: "Outdoor & Nature",
        data: catagories[6],
        backgroundColor: "rgba(33,249,157,0.0)",
        borderColor: "rgba(33,249,157,1)",
        pointBackgroundColor: "rgba(33,249,157,1)",
        borderWidth: 3,
      },
      {
        label: "Planning / Looking Forward To",
        data: catagories[7],
        backgroundColor: "rgba(169,106,126,0.0)",
        borderColor: "rgba(169,106,126,1)",
        pointBackgroundColor: "rgba(169,106,126,1)",
        borderWidth: 3,
      },
      {
        label: "Laughter / Makes you Smile",
        data: catagories[8],
        backgroundColor: "rgba(251,3,254,0.0)",
        borderColor: "rgba(251,3,254,1)",
        pointBackgroundColor: "rgba(251,3,254,1)",
        borderWidth: 3,
      },
    ],
  };
}

async function getLineData(assessmentList) {
  var n = 0;
  var overallMentalHealth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  var overallMentalHealthCount = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  while (assessmentList[n]) {
    overallMentalHealth[assessmentList[n].date.toDate().getMonth()] +=
      assessmentList[n].overall_mental_health;
    overallMentalHealthCount[assessmentList[n].date.toDate().getMonth()]++;
    n++;
  }
  for (var i = 0; i < 12; i++) {
    if (overallMentalHealthCount[i] >= 0) {
      overallMentalHealth[i] /= overallMentalHealthCount[i];
    } else {
      overallMentalHealth[i] = "NaN";
    }
  }

  //   while (overallMentalHealth.length < 12) {
  //     overallMentalHealth.push(0);
  //   }

  return {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "overall mental health",
        data: overallMentalHealth,
        borderColor: "rgb(00, 146, 00)",
        backgroundColor: "rgba(00, 146, 00, 0.5)",
      },
    ],
  };
}
