import { db } from "../../firebase-config";
import { doc, getDoc, updateDoc, collection, addDoc } from "firebase/firestore";
import CreateEvent from "./CreateEvent";

// the connection must exist for this to happen
export default async function CompleteEvent(userID, connectionName) {
  // get the event list
  const eventList = doc(db, "User/" + userID + "/Calendar", "Calendar");
  const eventListSnap = await getDoc(eventList);
  var sectionList = [];

  if (eventListSnap.exists()) {
    const userDoc = doc(db, "User", userID);
    const userSnap = await getDoc(userDoc);
    var events = eventListSnap.data().events;
    // find the event
    var eventFound = -1000;

    if (userSnap.exists()) {
      var currentUser = userSnap.data();
      for (const [index, element] of events.entries()) {
        // check if the event is the one we are looking
        if (element.name === connectionName) {
          element.completed = true;
          eventFound = index;
          sectionList = element.sectionList;

          // updates the number of completed events and checks if the user has completed a section
          const acknowledgmentsList = doc(db, "acknowledgments", "steps");
          const acknowledgmentsSnapShot = await getDoc(acknowledgmentsList); // Remove unused variable
          currentUser.number_completed = currentUser.number_completed + 1;
          // sending the achievement email
          if (acknowledgmentsSnapShot.exists()) {
            const AList = acknowledgmentsSnapShot.data();

            for (const [key, value] of Object.entries(AList)) {
              if (currentUser.number_completed === Number(key)) {
                // send the achievement email to the user
                let payload = {
                  to: currentUser.email_username,
                  template: {
                    name: "achivment_email",
                    data: {
                      username: currentUser.first_name,
                      achievement_message: value,
                    },
                  },
                };
                // Send the email
                addDoc(collection(db, "contact_us_email"), payload);
              }
            }
          }
          await updateDoc(userDoc, currentUser);

          // updating the stats
          const statsList = doc(db, "User/" + userID + "/Stats", "Stats");
          const statsSnapShot = await getDoc(statsList);
          if (statsSnapShot.exists()) {
            const stats = statsSnapShot.data();
            stats.total_completed = stats.total_completed + 1;
            stats.monthly_completed = stats.monthly_completed + 1;
            if (element.sectionList !== undefined) {
              element.sectionList.forEach((item) => {
                stats.sections[item] = stats.sections[item] + 1;
              });
              await updateDoc(statsList, stats);
            }
          }
          // Handling repeats and creating a new event
          const newEventList = await handleRepeats(
            element,
            userID,
            connectionName,
            sectionList
          );

          // Return newEventList if necessary
          return newEventList;
        }
      }
    }
  }

  // delete the event from the list
  async function deleteEvent(eventFound) {
    const eventListRef = doc(db, "User/" + userID + "/Calendar", "Calendar");
    const eventListSnap = await getDoc(eventListRef);
    console.log(eventFound);
    if (eventListSnap.exists()) {
      var calendarInfo = eventListSnap.data().events;
      for (var i = 0; i < calendarInfo.length; i++) {
        if (calendarInfo[i].name === eventFound.name) {
          eventFound = i;
          break;
        }
      }
      calendarInfo.splice(eventFound, 1);
      await updateDoc(eventListRef, { events: calendarInfo });
    }
  }

  async function handleRepeats(element, userID, connectionName, sectionList) {
    let repeat = "none"; // values for event not found
    let repeatEnd = "none"; // values for event not found
    let repeatFlag = false; // values for event not found
    console.log(element);
    // if repeat is none
    console.log("repeat type: " + element.repeatType);
    if (element.repeatType === "none" || element.repeatType === "first") {
      // delete the event from the list

      await deleteEvent(element);
      // create a new event
      const newEventList = await CreateEvent(
        connectionName,
        "",
        "",
        "",
        "",
        "",
        userID,
        false,
        sectionList,
        repeat,
        repeatEnd,
        [],
        repeatFlag,
        element.weeklyRepeat
      );
      return newEventList;
    } else {
      // check if the repeat end date has passed
      const endCheck = new Date(element.repeatEnd).getTime() / 1000;
      const today = new Date().getTime() / 1000;

      // check if repeat end has passed
      if (endCheck < today) {
        // skip the rest of the if statement
        const newEventList = await CreateEvent(
          connectionName,
          "",
          "",
          "",
          "",
          "",
          userID,
          false,
          sectionList,
          repeat,
          repeatEnd,
          [],
          repeatFlag,
          element.weeklyRepeat
        );
        return newEventList;
      }
    }
    let endDate = "";
    let date = "";
    if (element.allDay) {
      endDate = new Date();
      date = new Date();
    } else {
      // TODO: see if it needs to be the day not the time
      endDate = new Date(element.endTime.seconds * 1000);

      // get the time out of start time
      date = new Date(element.startTime.seconds * 1000);
    }

    let endDay = "";
    let endMonth = "";
    let endYear = "";
    let day = "";
    let month = "";
    let year = "";

    console.log(endDate);

    // handle the repeat type
    if (element.repeatType === "day") {
      // update the db with the new event list minus the event
      repeat = "day";
      repeatEnd = element.repeatEnd;

      // Display the date in a human-readable format (e.g., DD/MM/YYYY)
      endDay = endDate.getDate() + 1;
      endMonth = endDate.getMonth() + 1; // Months are zero-based in JavaScript
      endYear = endDate.getFullYear();
      // Display the date in a human-readable format (e.g., DD/MM/YYYY)
      day = date.getDate() + 1;
      month = date.getMonth() + 1; // Months are zero-based in JavaScript
      year = date.getFullYear();
      repeatFlag = true;
    }

    if (element.repeatType === "week") {
      // update the db with the new event list minus the event
      repeat = "week";
      repeatEnd = element.repeatEnd;
      repeatFlag = true;

      // find how many days in the future the next day of the week that weekSelected is set to true
      const daysInFuture = daysToNextSelectedDay(element.weeklyRepeat);
      console.log(daysInFuture);
      // Calculate the date of the next selected day
      const nextDate = new Date(date);
      nextDate.setDate(nextDate.getDate() + daysInFuture);
      day = nextDate.getDate() + 1;
      month = nextDate.getMonth() + 1; // Months are zero-based in JavaScript
      year = nextDate.getFullYear();

      const nextEndDate = new Date(endDate);
      nextEndDate.setDate(nextEndDate.getDate() + daysInFuture);
      endDay = nextEndDate.getDate() + 1;
      endMonth = nextEndDate.getMonth() + 1; // Months are zero-based in JavaScript
      endYear = nextEndDate.getFullYear();

      console.log(nextDate);
      endDate = nextEndDate;
      date = nextDate;
    }

    // gets the end time and the start time
    // Display the time in a 24-hour format (HH:MM:SS)
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    // Display the time in a 24-hour format (HH:MM:SS)
    const endhours = endDate.getHours();
    const endminutes = endDate.getMinutes();
    const endseconds = endDate.getSeconds();

    let startTime =
      String(hours).padStart(2, "0") +
      ":" +
      String(minutes).padStart(2, "0") +
      ":" +
      String(seconds).padStart(2, "0");
    let endTime =
      String(endhours).padStart(2, "0") +
      ":" +
      String(endminutes).padStart(2, "0") +
      ":" +
      String(endseconds).padStart(2, "0");
    let startDate =
      year +
      "-" +
      String(month).padStart(2, "0") +
      "-" +
      String(day).padStart(2, "0");
    let endDate2 =
      endYear +
      "-" +
      String(endMonth).padStart(2, "0") +
      "-" +
      String(endDay).padStart(2, "0");

    console.log(startTime);
    console.log(endTime);

    console.log(startDate);
    console.log(endDate2);

    const newEventList = await CreateEvent(
      connectionName,
      element.location,
      startDate,
      endDate2,
      startTime,
      endTime,
      userID,
      false,
      sectionList,
      repeat,
      repeatEnd,
      element.attendeesList,
      repeatFlag,
      element.weeklyRepeat
    );
    console.log(newEventList);
    return newEventList;
  }
}

function daysToNextSelectedDay(weekSelected) {
  // Get the current day of the week as an integer (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
  const currentDate = new Date();
  const currentDayOfWeek = currentDate.getDay(); // getDay() returns 0 (Sunday) to 6 (Saturday)

  // Initialize the days offset counter
  let daysOffset = 0;

  // Iterate through the week starting from the current day
  for (let i = 0; i < 7; i++) {
    // Calculate the index of the current day in the iteration
    const index = (currentDayOfWeek + i) % 7;

    // Check if the current day in the iteration is selected
    if (weekSelected[index]) {
      // Return the days offset (how many days in the future the next selected day is)
      return daysOffset;
    }

    // Increment the days offset counter
    daysOffset++;
  }

  // If no selected day is found, return -1 (you may want to handle this differently)
  return -1;
}
