import { getAuth } from "firebase/auth";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase-config"; // Adjust the import according to your project structure

export default async function useCurrentUser() {
  const auth = getAuth();
  const user = auth.currentUser;

  if (user) {
    const uid = user.uid;
    console.log("User exists: ", uid);

    const userQ = query(collection(db, "User"), where("uuid", "==", uid));

    const userDataPromise = new Promise((resolve, reject) => {
      const unsubscribe = onSnapshot(
        userQ,
        (querySnapshot) => {
          let userData = [];
          querySnapshot.forEach((doc) => {
            console.log("doc.data(): ", doc.id);
            userData.push({ ...doc.data(), id: doc.id });
          });

          if (userData.length > 0) {
            resolve(userData);
          } else {
            reject(new Error("No user data found"));
          }

          // Optionally unsubscribe from the snapshot listener
          unsubscribe();
        },
        (error) => {
          reject(error);
        }
      );
    });

    try {
      const userData = await userDataPromise;
      console.log("userData: ", userData);
      return userData;
    } catch (error) {
      console.error("Error fetching user data: ", error);
      return null;
    }
  } else {
    console.log("No user signed in");
    return null;
  }
}
